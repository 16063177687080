import { ISceneResult } from "@threekit-tools/treble/dist/types";
import {
  ModelsName_NodesT,
  NODES_THREEKIT,
  WallItemT,
} from "../../utils/constants/nodesNamesThreekit";
import { getAllEvalNodeThreekit, getAllNodeThreekit, getNameNodeThreekit, getTranslationThreekit } from "../../utils/threekit/general/getFunctions";
import { getStartEndPointWall } from "../wallsAndFloor/getStartEndPointWall";
import { getSizeModelRelativeTransform, isPointOnLine } from "../intervals/getIntervalsOnWallForCabinetsWall";
import { getAllWallsNode } from "../wallsAndFloor/buildWallFromData";
import { CABINETS_BASE_STANDART_DEPTH_FROM_DOOR } from "./constatns";

/**
 * Шукає моделі (Models Null), в які встановлені ассети.
 *
 * @param {NODES_THREEKIT} regexpModel Регулятний вираз типу "Model_Cabinets_Base_" по якому шукаємо нулі моделей.
 * @return {ModelsName_NodesT[]} Массив з Nodes для Models Null ["Model_Cabinets_Base_0", ...] які заповнені (з встановленими ассетами).
 */
export const getСompletedModelsNull = (
  regexpModel: NODES_THREEKIT
): ISceneResult[] => {
  const allModelsNull = getAllNodeThreekit(regexpModel);
  let activeModelsNullCabinetBase: ISceneResult[] = [];

  Object.values(allModelsNull).forEach((nodeModelNull: ISceneResult) => {
    //@ts-ignore
    if (!!nodeModelNull["plugs"]["Null"][0]["asset"])
      activeModelsNullCabinetBase.push(nodeModelNull);
  });

  return activeModelsNullCabinetBase;
};

/**
 * Шукає моделі(Models Null), в які встановлені ассети.
 *
 * @param {NODES_THREEKIT} regexpModel Регулятний вираз типу "Model_Cabinets_Base_" по якому шукаємо нулі моделей.
 * @return {ModelsName_NodesT[]} Массив з іменами Threekit, для Models Null ["Model_Cabinets_Base_0", ...] які заповнені (з встановленими ассетами).
 */
export const getСompletedModelsNullNames = (
  regexpModel: NODES_THREEKIT
): ModelsName_NodesT[] => {
  const allModelsNull = getAllNodeThreekit(regexpModel);
  let activeModelsNullCabinetBase: ModelsName_NodesT[] = [];

  Object.values(allModelsNull).forEach((nodeModelNull: ISceneResult) => {
    //@ts-ignore
    if (!!nodeModelNull["plugs"]["Null"][0]["asset"])
      activeModelsNullCabinetBase.push(
        nodeModelNull["name"] as ModelsName_NodesT
      );
  });

  return activeModelsNullCabinetBase;
};

/**
 * Шукає EvalNode моделей(Models Null), в які встановлені ассети.
 *
 * @param {NODES_THREEKIT} regexpModels Регулятний вираз типу "Model_Cabinets_Base_" по якому шукаємо нулі моделей.
 * @return {ModelsName_NodesT[]} Массив з EvalNodes для Models Null ["Model_Cabinets_Base_0", ...] які заповнені (з встановленими ассетами).
 */
export const getСompletedEvalNodeModels = (
  regexpModels: NODES_THREEKIT
): ISceneResult[] => {
  const allEvalNodeCabinetsRegExp = getAllEvalNodeThreekit(regexpModels);
  let activeModelsNullCabinetBase: ISceneResult[] = [];

  Object.values(allEvalNodeCabinetsRegExp).forEach((evalNodeModelNull: ISceneResult) => {
    //@ts-ignore
    if (!!evalNodeModelNull["node"]["plugs"]["Null"][0]["asset"])
      activeModelsNullCabinetBase.push(
        evalNodeModelNull
      );
  });

  return activeModelsNullCabinetBase;
};

/**
 * Шукає моделі на підлозі, які встановлені безпосередньо біля обраної стіни.
 *
 * @param {WallItemT} wallName Ім'я стіни з Threekit, біля якої шукаємо моделі на підлозі.
 * @return {ModelsName_NodesT[]} Массив з іменами Threekit, для Models Null ["Model_Cabinets_Base_0", ...].
 */
export const getModelsBaseNullOnWall = (
  wallName: WallItemT
): ModelsName_NodesT[] => {
  const completedModelsNullNamesCabinetsBase = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_BASE
  );
  // const completedModelsNullNamesAppliances = getСompletedModelsNullNames(
  //   NODES_THREEKIT.MODEL_APPLIANCES
  // );
  const completedModels = [ ...completedModelsNullNamesCabinetsBase/*, ...completedModelsNullNamesAppliances */];
  const wallSize = getSizeModelRelativeTransform(wallName);
  const [wallCoordsLeft, wallCoordsRight] = getStartEndPointWall(wallName);

  let baseCabinetsOnWall: ModelsName_NodesT[] = [];
  completedModels.forEach((nodeModelNullName) => {
    const posModel = getTranslationThreekit({ name: nodeModelNullName });
    const isModelNullOnPlane = isPointOnLine(
      { ...wallCoordsLeft, y: 0 },
      { ...wallCoordsRight, y: 0 },
      posModel,
      wallSize["z"] / 1.5
    );
    if (isModelNullOnPlane) baseCabinetsOnWall.push(nodeModelNullName);
  });

  const applianceOnWall = getModelsAppliancesOnWall(wallName, CABINETS_BASE_STANDART_DEPTH_FROM_DOOR)

  return [ ...baseCabinetsOnWall, ...applianceOnWall ];
};

/**
 * Шукає моделі на підлозі, які встановлені біля стін.
 *
 * @param {WallItemT} wallName Ім'я стіни з Threekit, біля якої шукаємо моделі на підлозі.
 * @return {ModelsName_NodesT[]} Массив з іменами Threekit, для Models Null ["Model_Cabinets_Base_0", ...].
 */
export const getModelsBaseNullOnAllWalls = (): ModelsName_NodesT[] => {

  const allWallsNode = getAllWallsNode();
  let modelsBaseNullOnAllWalls: ModelsName_NodesT[] = []

  Object.keys(allWallsNode).forEach((idWall: string) => {
    const wallName = getNameNodeThreekit({id: idWall}) as WallItemT;
    const modelsBaseOnWall = getModelsBaseNullOnWall(wallName);
    modelsBaseNullOnAllWalls = [
      ...modelsBaseNullOnAllWalls,
      ...modelsBaseOnWall
    ]
  })

  return modelsBaseNullOnAllWalls;
};

/**
 * Шукає моделі на стіні, які встановлені безпосередньо на обраній стіні. НІСТІННІ МОДЕЛІ(Cabinets Wall).
 *
 * @param {PlaneCabinetsWallT} planeName Ім'я для Plane на стіні з Threekit, біля якої шукаємо настінні моделі (поцьому плейну переміщуються моделі на стіні).
 * @return {ModelsName_NodesT[]} Массив з іменами Threekit, для Models Null ["Model_Cabinets_Wall_0", ...].
 */
export const getModelsWallNullOnWall = (
  wallName: WallItemT
): ModelsName_NodesT[] => {
  const completedModelsNullNamesCabinetsWall = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_WALL
  );
  const wallSize = getSizeModelRelativeTransform(wallName);
  const [wallCoordsLeft, wallCoordsRight] = getStartEndPointWall(wallName);

  let modelsOnWall: ModelsName_NodesT[] = [];
  completedModelsNullNamesCabinetsWall.forEach((nodeModelNullName) => {
    const posModel = getTranslationThreekit({ name: nodeModelNullName });
    const isModelNullOnPlane = isPointOnLine(
      { ...wallCoordsLeft, y: 0 },
      { ...wallCoordsRight, y: 0 },
      { ...posModel, y: 0},
      wallSize["z"] / 1.5
    );
    if (isModelNullOnPlane) modelsOnWall.push(nodeModelNullName);
  });

  return modelsOnWall;
};

/**
 * Шукає моделі Appliances біля стіни, які встановлені безпосередньо біля обраної стіни на зазаначеній відстані.
 *
 * @param {WallItemT} wallName Ім'я стіни з Threekit, біля якої шукаємо настінні моделі.
 * @param {number} tolerance Точність. Відстань, на якій модель може розташовуватись від стіни.
 * @return {ModelsName_NodesT[]} Массив з іменами точок моделей Threekit.
 */
export const getModelsAppliancesOnWall = (
  wallName: WallItemT,
  tolerance: number
): ModelsName_NodesT[] => {
  const completedAppliances = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_APPLIANCES
  );
  const wallSize = getSizeModelRelativeTransform(wallName);
  const [wallCoordsLeft, wallCoordsRight] = getStartEndPointWall(wallName);

  let modelsOnWall: ModelsName_NodesT[] = [];

  completedAppliances.forEach((nullName) => {
    const posModel = getTranslationThreekit({ name: nullName });
    const isModelNullOnPlane = isPointOnLine(
      { ...wallCoordsLeft, y: 0 },
      { ...wallCoordsRight, y: 0 },
      { ...posModel, y: 0},
      wallSize["z"] / 1.5 + tolerance
    );
    if (isModelNullOnPlane) modelsOnWall.push(nullName);
  });

  return modelsOnWall;
};