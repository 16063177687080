// scss
import s from "./../MainMenu.module.scss";

import { Logo } from "./../../Logo/Logo";
import { funcGetUrlIcon } from "../../../functionsUI/funcImg";
import Windows from "./../../../assets/images/svg/Menu/2D/Windows.svg";
import Doors from "./../../../assets/images/svg/Menu/2D/Doors.svg";
import Openings from "./../../../assets/images/svg/Menu/2D/Openings.svg";
import Select from "./../../../assets/images/svg/Menu/2D/Select.svg";
import { mainMenu2D, MainMenuItem2DI } from "../../../utils/constants/settingsConfig2D";
import { IComponent } from "../../../types/UI/dynamic-rendering.interfaces";
import { DistributeComponents } from "../../DistributeComponents/DistributeComponents";
import React, { useEffect, useState } from "react";
import { IModes } from "@threekit-tools/treble/dist/hooks/useSpaces/types";
import { EditModeSvg } from "../../../assets/images/svg/EditMode";
import { useAppDispatch } from "../../../hooks/useStoreHooks";
import { addFeatureRoomBuilder } from "../../../functionsConfigurator/features/addFeature";

interface ModeOptI {
  label: IModes;
  value: IModes;
  selected: boolean;
  handleSelect: () => void;
}
const ControlLink = ({ ...props }) => {
  const { label, value, selected, onClick } = props;
  return (
    <>
      {selected && (
        <div className={`${s.mainMenuLink}`} key={value} onClick={onClick}>
          <img src={Select} alt="" />
          <p className={s.mainMenuLinktext}>{label}</p>
        </div>
      )}
    </>
  );
};

export const MainMenuRoomBuilder = ({ ...props }) => {
  const {
    //  Blueprint canvas
    canvasRef,
    //  Enhanced Attributes
    walls,
    windows,
    doors,
    openings,
    //  State Managers
    selectedElement,
    mode,
    dimensions,
    angleSnapping,
    deleteAll,
    deleteElement,
  } = props;

  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  const handleMenuClick = (menuItemObj: MainMenuItem2DI, modeOption?: any) => {
    if (!!modeOption) {
      setActiveMenu(menuItemObj["key"]);
      modeOption.handleSelect();
    } else if (menuItemObj["key"] === "Doors") {
      addFeatureRoomBuilder({
        attributeRoomBuilder: doors,
        initialConfiguration: { length: 0.762 },
        dispatch,
      });
    } else if (menuItemObj["key"] === "Windows") {
      addFeatureRoomBuilder({
        attributeRoomBuilder: windows,
        initialConfiguration: { length: 2.4 },
        dispatch,
      });
    } else if (menuItemObj["key"] === "Openings") {
      addFeatureRoomBuilder({
        attributeRoomBuilder: openings,
        initialConfiguration: { length: 2.4 },
        dispatch,
      });
    } else if (menuItemObj["key"] === "Layout Templates") {
      setActiveMenu(menuItemObj["key"]);
    }
  };

  const handleCloseSubMenu = () => {
    setActiveMenu(null);
  };

  const getMenuSettings = (valueModeOption: string): MainMenuItem2DI => {
    const menuKey = valueModeOption === "draw" ? "Draw" : "Select";
    const currentMenuSettings = mainMenu2D.find((menuItem) => menuItem["key"] === menuKey) as MainMenuItem2DI;
    return currentMenuSettings;
  };

  // useEffect(() => {
  //   if (!!walls && walls["value"].length === 0)
  //     mode.handleChange("draw");
  // }, []);

  return (
    <div className={`${s.mainMenuWrap} ${s.mainMenuWrap_2D}`}>
      <div className={s.mainMenuLogoWrap}>
        <Logo />
      </div>
      <div className={s.mainMenu}>
        <div className={s.mainMenuScroll}>
          {mode.options.map((opt: any) => {
            const menuSetting = getMenuSettings(opt["value"]);
            const urlSVG: any = menuSetting["imgUrl"];
            const isActiveMenu = !!activeMenu && activeMenu === menuSetting["key"];

            const isDraw = menuSetting["key"] === "Draw";

            return (
              <div
                className={`${s.mainMenuLink} ${opt["selected"] && s.active} ${
                  opt["value"] === "select" ? s.selectBtn : ""
                }`}
                key={opt["value"]}
                onClick={() => handleMenuClick(menuSetting, opt)}
              >
                {urlSVG && funcGetUrlIcon(urlSVG)}
                <p className={s.mainMenuLinktext}>{opt["label"] === "select" ? "Select & Modify" : opt["label"]}</p>
                {opt["value"] === "select" && (
                  <div className={s.editMode}>
                    <EditModeSvg />
                  </div>
                )}
                {isActiveMenu && isDraw && (
                  <div className={s.menuItemSubContent}>
                    {menuSetting["setting"] &&
                      menuSetting["setting"].map((setting: IComponent) => {
                        return (
                          <DistributeComponents
                            key={setting["data"]["id"]}
                            {...setting}
                            onCloseMenu2D={() => handleCloseSubMenu()}
                          />
                        );
                      })}
                  </div>
                )}
              </div>
            );
          })}

          {mainMenu2D.map((menuItem: MainMenuItem2DI, indx: number) => {
            if (["Draw", "Select"].includes(menuItem["key"])) return;

            const urlSVG: any = menuItem["imgUrl"];
            const isActiveMenu = !!activeMenu && activeMenu === menuItem["key"];
            const isLayoutTemplate = menuItem["key"] === "Layout Templates";

            return (
              <div
                className={`${s.mainMenuLink} ${isActiveMenu && s.active}`}
                key={menuItem["key"]}
                onClick={() => handleMenuClick(menuItem)}
              >
                {urlSVG && funcGetUrlIcon(urlSVG)}
                <p className={s.mainMenuLinktext}>{menuItem["text"]}</p>
                {isActiveMenu && isLayoutTemplate && (
                  <div className={s.menuItemSubContent}>
                    {menuItem["setting"] &&
                      menuItem["setting"].map((setting: IComponent) => {
                        return (
                          <DistributeComponents
                            key={setting["data"]["id"]}
                            {...setting}
                            onCloseMenu2D={() => handleCloseSubMenu()}
                          />
                        );
                      })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
