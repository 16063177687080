import { PlaneCabinetsWallT } from "../../utils/constants/nodesNamesThreekit";
import { isNullNameAppliancesFridge } from "../cabinets/checkModels";
import { getModelsAppliancesOnWall } from "../cabinets/getNodesCabinets";
import { getWallNameFromPlaneName } from "../wallsAndFloor/getGeneralWallInfo";
import { getIntervalsInfoOnWallForCabinetsBase } from "./getIntervalsInfoOnWallBase";
import { ArrWallRangesT } from "./getIntervalsOnWallForCabinetsWall";

/**
 * Формує масив заповнених інтервалів на плейні, в яких розташовані холодильники
 *
 * @param {PlaneCabinetsWallT} namePlane Name плейну, для якого шукаємо наявність інтервалів з холодильниками
 * @return {ArrWallRangesT} Масив інтервалів з холодильниками на плейні
 */
export const getIntervalsFridge = (namePlane: PlaneCabinetsWallT, tolerance: number = 0.005): ArrWallRangesT => {
  const modelsAppliancesOnWall = getModelsAppliancesOnWall(
    getWallNameFromPlaneName(namePlane),
    tolerance
  );
  const intervalsAppliancesOnWall = getIntervalsInfoOnWallForCabinetsBase(
    modelsAppliancesOnWall,
    namePlane
  );
  const arrIntervalsFilledFromFridge = intervalsAppliancesOnWall.filter(
    (objInterval) => {
      const { empty, name } = objInterval;
      if (!empty && name !== undefined) {
        return isNullNameAppliancesFridge(name);
      }
    }
  );
  return arrIntervalsFilledFromFridge;
};