export const isOptionIdUpperMolding = (optionId: string) => {
  return optionId.includes("UpperMolding");
};

export const isOptionIdUpperMoldingSize = (optionId: string) => {
  return optionId.includes("UpperMoldingSize");
};

export const isOptionIdLowerMolding = (optionId: string) => {
  return optionId.includes("LowerMolding");
};

export const isOptionIdEdgeScribeMoulding = (optionId: string) => {
  return optionId.includes("EdgeScribeMoulding");
};

export const isOptionIdMolding = (optionId: string) => {
  return (
    isOptionIdUpperMolding(optionId) ||
    isOptionIdUpperMoldingSize(optionId) ||
    isOptionIdLowerMolding(optionId) ||
    isOptionIdEdgeScribeMoulding(optionId)
  );
};
