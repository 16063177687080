import { ATTRIBUTES_NAMES_THREEKIT } from "../../../utils/constants/attributesThreekit";
import { NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { offDecorativeEndAllCabinets } from "../configuration/decorativePanel";

/**
 * Функція вимикає всі декоративні елементи для всіх Island Cabinets.
 * Для вимкнення декоративних панелей під час переміщення однієї з моделей
 * Дозволяє коректно спрацьовувати конекторам (які враховують перетин моделей)
 *
 * @return {Promise<any>} Promice оновлення конфігурацій моделей.
 */
export const offAllDecorativeIslandCabinets = () => {
  return offDecorativeEndAllCabinets(ATTRIBUTES_NAMES_THREEKIT.CABINETS_ISLAND, NODES_THREEKIT.MODEL_CABINET_ISLAND);
};
