// scss
import s from "./MainMenu.module.scss";

import { Logo } from "../Logo/Logo";
import { mainMenu3D, MainMenuItem3DI } from "../../utils/constants/mainMenu3D";
import { funcGetUrlIcon } from "../../functionsUI/funcImg";
import { useAppDispatch, useAppSelector } from "../../hooks/useStoreHooks";
import { setActiveSection, updateSettings } from "../../store/slices/settingsUI/settingsUISlice";
import { getKeySectionActive } from "../../store/selectors/settingsUISelectors";

export const MainMenu = () => {
  const dispatch = useAppDispatch();
  const activeMenu = useAppSelector(getKeySectionActive);

  const handleMenuClick = (menuItemObj: MainMenuItem3DI) => {
    dispatch(updateSettings([{ optionId: "CategoryCabinet", value: "" }]));
    dispatch(setActiveSection(menuItemObj["key"]));
  };

  return (
    <div className={s.mainMenuWrap}>
      <div className={s.mainMenuLogoWrap}>
        <Logo />
      </div>
      <div className={s.mainMenu}>
        <div className={s.mainMenuScroll}>
          {mainMenu3D.map((menuItem: MainMenuItem3DI, indx: number) => {
            const urlSVG: any = menuItem["imgUrl"];
            const isActiveMenu = activeMenu && activeMenu === menuItem["key"];

            return (
              <div
                className={`${s.mainMenuLink} ${isActiveMenu && s.active}`}
                key={menuItem["key"]}
                onClick={() => handleMenuClick(menuItem)}
              >
                {urlSVG && funcGetUrlIcon(urlSVG)}
                <p className={s.mainMenuLinktext}>{menuItem["text"]}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
