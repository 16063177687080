import { IConfiguration, ISetConfiguration } from "@threekit-tools/treble/dist/types";
import { ModelsName_NodesT, NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getTranslationThreekit } from "../../../utils/threekit/general/getFunctions";
import { isEqualCoordsTolerance } from "../addCornerModelBase";
import { getSizeModelBoxFromAssetCabinetBase } from "../cabinetsBase/size";
import { checkDefaultPositionCabinetsWall } from "../cabinetsWall/position";
import { getSizeModelBoxFromAssetCabinetWall } from "../cabinetsWall/size";
import { isUpperPantryCabinetWall } from "../checkModels";
import { getСompletedModelsNullNames } from "../getNodesCabinets";
import { getConfiguratorModelFromNullName } from "./decorativePanel";
import { setNestedConfigurationFromNullName } from "../../../utils/threekit/general/nestedConfigurator";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../../utils/constants/attributesThreekit";

export const enum ATTRIBUTES_COUNTERTOP {
  COUNTERTOP = "Counter Top",
}
export type CountertopValuesT = "no" | "yes";
export type MouldingConfigurationT = {
  [ATTRIBUTES_COUNTERTOP.COUNTERTOP]?: CountertopValuesT,
}

export const isEqualAxisSize = (
  firstAxisSize: number,
  lastAxisSize: number,
): boolean => Math.abs(firstAxisSize - lastAxisSize) < 0.1;

const checkUpperPantryAboveCabinetBase = (
  nullNameCabinetBase: ModelsName_NodesT
): boolean => {

  const posCabinetBase = getTranslationThreekit({name: nullNameCabinetBase});
  const sizeCabinetBase = getSizeModelBoxFromAssetCabinetBase(nullNameCabinetBase);
  const completedCabinetsWall = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_WALL);

  let resultValue = false;

  completedCabinetsWall.forEach((nullNameCabinetWall) => {

    if (resultValue || !isUpperPantryCabinetWall(nullNameCabinetWall)) return;

    const posCabinetWall = getTranslationThreekit({name: nullNameCabinetWall});
    const sizeCabinetWall = getSizeModelBoxFromAssetCabinetWall(nullNameCabinetWall);

    const isEqualCoords = isEqualCoordsTolerance(
      { ...posCabinetBase, y: 0 },
      { ...posCabinetWall, y: 0 },
      0.1
    );

    const isUperPantryDefaultPosY = checkDefaultPositionCabinetsWall(nullNameCabinetWall);

    const isEqualSizeXZ = isEqualAxisSize(sizeCabinetBase["x"], sizeCabinetWall["x"]) && isEqualAxisSize(sizeCabinetBase["z"], sizeCabinetWall["z"]);

    if (
      isEqualCoords &&
      isUperPantryDefaultPosY &&
      isEqualSizeXZ
    ) resultValue = true;

  })

  return resultValue;

}

export const checkCountertopCabinetsBase = () => {

  const completedCabinetsBase = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_BASE);

  completedCabinetsBase.forEach((nullNameCabinetBase) => {

    const isUpperPantryAboveCabinetBase = checkUpperPantryAboveCabinetBase(nullNameCabinetBase);
    const configuratorCabinetBase = getConfiguratorModelFromNullName(nullNameCabinetBase);

    if (isUpperPantryAboveCabinetBase) {

      configuratorCabinetBase.setConfiguration({
        [ATTRIBUTES_COUNTERTOP.COUNTERTOP]: "no",
      });

    } else {

      configuratorCabinetBase.setConfiguration({
        [ATTRIBUTES_COUNTERTOP.COUNTERTOP]: "yes",
      });

    }

  })

}

/**
 * Функція вимикає столешні для всіх моделей.
 * Для вимкнення стільниць під час переміщення однієї з моделей
 * Дозволяє коректно спрацьовувати конекторам по з'єднанню шаф для кладової (які враховують перетин моделей)
 *
 * @param {ATTRIBUTES_NAMES_THREEKIT} attributeCabinets Назва атрибута для зміни конфігурації через setNestedConfiguration.
 * @param {NODES_THREEKIT} modelsCabinetsRegExp regexp для Null моделей, які необхідно оновити.
 * @return {Promise<any>} Promice оновлення конфігурацій моделей.
 */
export const offCountertopBaseCabinets = (): Promise<any> => {

  let arrPromices: Promise<any>[] = [];

  // Об'єкт конфігурації для вимкнення декоративних панелей
  const objConfiguration: ISetConfiguration | IConfiguration = {
    [ATTRIBUTES_COUNTERTOP.COUNTERTOP]: "no",
  }

  const allСompletedNullForCabinets = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_BASE);
  allСompletedNullForCabinets.forEach((nullName) => {
    arrPromices.push(setNestedConfigurationFromNullName({
      nullName: nullName,
      attributeName: ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE,
      configuration: objConfiguration,
    }));
  });

  return Promise.all(arrPromices);
  
};