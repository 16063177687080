import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { ATTRIBUTES_NAMES_THREEKIT, DOORS_MAX_LENGTH } from "../../utils/constants/attributesThreekit";
import { setModalVisible } from "../../store/slices/modalsSlice/modalsSlice";
import { MODAL_KEYS } from "../../store/slices/modalsSlice/modalsSliceT";
import { setLoadFeatures } from "../../store/slices/settingsUI/settingsUISlice";
import { getConfigurationThreekitAttribute } from "../../utils/threekit/general/getFunctions";

export const addFeatureRoomBuilder = ({
  attributeRoomBuilder,
  initialConfiguration,
  dispatch,
  addToSelectedElement
}: {
  attributeRoomBuilder: any;
  initialConfiguration: any;
  dispatch: Dispatch<AnyAction>;
  addToSelectedElement?: {
    selectedElement: any;
    featureType: string;
  }
}) => {

  const wallsConfigutation = getConfigurationThreekitAttribute(ATTRIBUTES_NAMES_THREEKIT.WALLS);
  if (wallsConfigutation.length < 1) {
    return dispatch(
      setModalVisible({
        modalId: MODAL_KEYS.MESSAGE,
        value: true,
        message: `The walls have not yet been created. Please draw the walls.`,
      })
    );
  }

  const attributeMaxLength =
    attributeRoomBuilder.maxLength !== undefined ? attributeRoomBuilder.maxLength : DOORS_MAX_LENGTH;

  if (attributeRoomBuilder.value.length < attributeMaxLength) {

    dispatch(setLoadFeatures(true));

    if (addToSelectedElement !== undefined) {
      addToSelectedElement["selectedElement"].addFeature(addToSelectedElement["featureType"], initialConfiguration)
    } else {
      attributeRoomBuilder["values"][0].add(0, initialConfiguration);
    }

  } else {
    dispatch(
      setModalVisible({
        modalId: MODAL_KEYS.MESSAGE,
        value: true,
        message: `You cannot add more than ${attributeMaxLength} ${attributeRoomBuilder.label} to a room.`,
      })
    );
  }
  // @ts-ignore
  window.threekit.player.evaluate().then(() => {
    dispatch(setLoadFeatures(false));
  });
};
