import { IConfiguration, ISetConfiguration } from "@threekit-tools/treble/dist/types";
import {
  isOptionIdEdgeScribeMoulding,
  isOptionIdLowerMolding,
  isOptionIdUpperMolding,
  isOptionIdUpperMoldingSize,
} from "../../../functionsUI/cabinets/moulding";
import {
  getValueOptionEdgeScribeMoulding,
  getValueOptionLowerMolding,
  getValueOptionUpperMolding,
  getValueOptionUpperMoldingSize,
} from "../../../store/selectors/settingsUISelectors";
import { UpdateSettingsT } from "../../../store/slices/settingsUI/typesSettingsUISlice";
import store from "../../../store/store";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../../utils/constants/attributesThreekit";
import { ModelCabinetWallT, NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getKeys } from "../../../utils/other/getObjKeysFromType";
import { setNestedConfigurationFromNullName } from "../../../utils/threekit/general/nestedConfigurator";
import { getMouldingBottomConfigurationAllCabinets } from "../cabinetsWall/getMouldingBottomConfiguration";
import { AllCabinetsEdgeScribeMouldingConfigurationT, getConfigurationModelsFromLengthEdgeScribeMoulding } from "../cabinetsWall/getMouldingEdgeScribeConfiguration";
import {
  getMouldingTopConfigurationAllCabinets,
  ObjTopMouldingConfigurationT,
} from "../cabinetsWall/getMouldingTopConfiguration";
import { getСompletedModelsNullNames } from "./../getNodesCabinets";

export const enum ATTRIBUTES_MOULDING {
  MOULDING_TOP = "Moulding Upper",
  MOULDING_TOP_STYLE = "Moulding Upper Location",
  MOULDING_BOTTOM = "Moulding Lower",
  MOULDING_BOTTOM_STYLE = "Moulding Lower Location",
  MOULDING_CONNECTION_LEFT = "Moulding connection left",
  MOULDING_CONNECTION_RIGHT = "Moulding connection right",
  MOULDING_CONNECTION_TO_PANTRY = "Moulding connection to pantry",
  EDGE_SCRIBE_MOULDING = "Edge Scribe Molding",
  EDGE_SCRIBE_MOULDING_LOCATION = "Edge Scribe Molding Position",
}

export type MouldingTopValuesT = "no" | "3in" | "6in";
export type MouldingTopStyleValuesT = "Left" | "Right" | "Center" | "Alone";
export type MouldingBottomValuesT = "no" | "yes";
export type MouldingBottomStyleValuesT = "Left" | "Right" | "Center" | "Alone";
export type MouldingConnectionLeftValuesT = "no" | "yes";
export type MouldingConnectionRightValuesT = "no" | "yes";
export type MouldingConnectionToPantryValuesT = "no" | "yes";
export type MouldingConfigurationT = {
  [ATTRIBUTES_MOULDING.MOULDING_TOP]?: MouldingTopValuesT;
  [ATTRIBUTES_MOULDING.MOULDING_TOP_STYLE]?: MouldingTopStyleValuesT;
  [ATTRIBUTES_MOULDING.MOULDING_BOTTOM]?: MouldingBottomValuesT;
  [ATTRIBUTES_MOULDING.MOULDING_BOTTOM_STYLE]?: MouldingBottomStyleValuesT;
  [ATTRIBUTES_MOULDING.MOULDING_CONNECTION_LEFT]?: MouldingConnectionLeftValuesT;
  [ATTRIBUTES_MOULDING.MOULDING_CONNECTION_RIGHT]?: MouldingConnectionRightValuesT;
  [ATTRIBUTES_MOULDING.MOULDING_CONNECTION_TO_PANTRY]?: MouldingConnectionToPantryValuesT;
};

export type MouldingEdgeScribeValuesT = "no" | "yes";
export type MouldingEdgeScribeLocatioValuesT = "Left" | "Right" | "Left Wall" | "Right Wall" | "Alone" | "Center";
export type MouldingEdgeScribeConfigurationT = {
  [ATTRIBUTES_MOULDING.EDGE_SCRIBE_MOULDING]?: MouldingEdgeScribeValuesT;
  [ATTRIBUTES_MOULDING.EDGE_SCRIBE_MOULDING_LOCATION]?: MouldingEdgeScribeLocatioValuesT;
}

// const mergeObjMouldingConfigurations = (
//   obj1: ObjTopMouldingConfigurationT,
//   obj2: ObjTopMouldingConfigurationT
// ): ObjTopMouldingConfigurationT => {
//   const mergedObj: ObjTopMouldingConfigurationT = {} as ObjTopMouldingConfigurationT;
//   for (const key of Object.keys(obj1) as ModelCabinetWallT[]) {
//     mergedObj[key] = { ...obj1[key], ...obj2[key] };
//   }
//   return mergedObj;
// }

const mergeObjMouldingConfigurations = (
  obj1: ObjTopMouldingConfigurationT | null | undefined,
  obj2: ObjTopMouldingConfigurationT | null | undefined,
  obj3: AllCabinetsEdgeScribeMouldingConfigurationT | null | undefined
): ObjTopMouldingConfigurationT => {
  const result: ObjTopMouldingConfigurationT = {} as ObjTopMouldingConfigurationT;

  // Об'єднуємо ключі з об'єктів
  const keys = [...Object.keys(obj1 || {}), ...Object.keys(obj2 || {}), ...Object.keys(obj3 || {})] as ModelCabinetWallT[];

  // Для кожного ключа додаємо MouldingConfiguration з об'єктів
  for (const key of keys) {
    result[key] = {
      ...(obj1?.[key] || {}),
      ...(obj2?.[key] || {}),
      ...(obj3?.[key] || {}),
    };
  }

  return result;
};

export const getMouldingConfiguration = (param?: UpdateSettingsT): ObjTopMouldingConfigurationT => {
  const state = store.getState();

  const isParamUpperMolding = !!param && isOptionIdUpperMolding(param["optionId"]);
  const isParamLowerMolding = !!param && isOptionIdLowerMolding(param["optionId"]);
  const isParamUpperMoldingSize = !!param && isOptionIdUpperMoldingSize(param["optionId"]);
  const isParamEdgeScribeMoulding = !!param && isOptionIdEdgeScribeMoulding(param["optionId"]);

  // отримуємо дані зі стейту по вехніх молдингах
  // Чи ввімкнений верхній молдинг
  let isUpperMoldingStore = getValueOptionUpperMolding(state) as boolean;
  const isUpperMolding = isParamUpperMolding ? (param["value"] as boolean) : isUpperMoldingStore;

  // отримуємо дані зі стейту по нижньому молдингу
  // Вімкнений чи вимкнений нижній молдинг
  let isLowerMoldingStore = getValueOptionLowerMolding(state) as boolean;
  const isLowerMolding = isParamLowerMolding ? (param["value"] as boolean) : isLowerMoldingStore;

  // отримуємо дані зі стейту по молдингу Edge Scribe Moulding
  // Вімкнений чи вимкнений молдинг Edge Scribe Moulding
  let isEdgeScribeMouldingStore = getValueOptionEdgeScribeMoulding(state) as boolean;
  const isEdgeScribeMoulding = isParamEdgeScribeMoulding ? (param["value"] as boolean) : isEdgeScribeMouldingStore;

  if (
    !isUpperMolding &&
    !isParamUpperMolding &&
    !isLowerMolding &&
    !isParamLowerMolding &&
    !isEdgeScribeMoulding &&
    !isParamEdgeScribeMoulding
  )
    return {};

  // перевіряємо чи ввімкнений верхній молдинг в UI
  let mouldingTopConfiguration: MouldingConfigurationT = {};
  if (isUpperMolding || isParamUpperMolding) {
    // Отримуємо по данних зі стейту тип верхнього молдингу
    let sizeUpperMoldingStore = getValueOptionUpperMoldingSize(state) as MouldingTopValuesT;
    const sizeUpperMolding = isParamUpperMoldingSize ? (param["value"] as MouldingTopValuesT) : sizeUpperMoldingStore;

    // отримуємо налаштування конфігурації верхніх молдингів для всіх настінних шкафів
    mouldingTopConfiguration = getMouldingTopConfigurationAllCabinets(isUpperMolding, sizeUpperMolding);
  }

  // перевіряємо чи ввімкнений нижній молдинг в UI
  let mouldingBottomConfiguration: MouldingConfigurationT = {};
  if (isLowerMolding || isParamLowerMolding) {
    // отримуємо налаштування конфігурації нижніх молдингів для всіх настінних шкафів
    mouldingBottomConfiguration = getMouldingBottomConfigurationAllCabinets(isLowerMolding);
  }

  // перевіряємо чи ввімкнений молдинг Edge Scribe Moulding в UI
  let edgeScribeMouldingConfiguration: AllCabinetsEdgeScribeMouldingConfigurationT = {};
  if (isEdgeScribeMoulding || isParamEdgeScribeMoulding) {
    edgeScribeMouldingConfiguration = getConfigurationModelsFromLengthEdgeScribeMoulding(isEdgeScribeMoulding);
  }

  // об'єднуємо конфігурації для верхніх та нижніх молдингів
  const fullMouldingConfiguration = mergeObjMouldingConfigurations(
    mouldingTopConfiguration,
    mouldingBottomConfiguration,
    edgeScribeMouldingConfiguration,
  );

  return fullMouldingConfiguration;
};

export const updateMoulding = async (param?: UpdateSettingsT) => {
  const fullMouldingConfiguration = getMouldingConfiguration(param);
  const arrNullNamesCabinetsWall = getKeys(fullMouldingConfiguration);
  arrNullNamesCabinetsWall.forEach(async (nullNameCabinetWall) => {
    const configuration = fullMouldingConfiguration[nullNameCabinetWall];
    // const configuratorModel = getConfiguratorModelFromNullName(nullNameCabinetWall);
    // await configuratorModel.setConfiguration(configuration);
    await setNestedConfigurationFromNullName({
      nullName: nullNameCabinetWall,
      attributeName: ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL,
      configuration: configuration,
    });
  });
};

/**
 * Функція вимикає молдінги всі для всіх настінних моделей.
 * Для вимкнення молдінгів під час переміщення однієї з моделей
 * Дозволяє коректно спрацьовувати конекторам (які враховують перетин моделей)
 *
 * @return {Promise<any>} Promice оновлення конфігурацій моделей.
 */
export const offMouldingsWallCabinets = (): Promise<any> => {

  let arrPromices: Promise<any>[] = [];

  // Об'єкт конфігурації для вимкнення декоративних панелей
  const objMouldingOffConfiguration: ISetConfiguration | IConfiguration = {
    [ATTRIBUTES_MOULDING.MOULDING_TOP]: "no",
    [ATTRIBUTES_MOULDING.MOULDING_BOTTOM]: "no",
    [ATTRIBUTES_MOULDING.EDGE_SCRIBE_MOULDING]: "no",
  }

  const allСompletedNullWallCabinets = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_WALL);
  allСompletedNullWallCabinets.forEach((nullName) => {
    arrPromices.push(setNestedConfigurationFromNullName({
      nullName: nullName,
      attributeName: ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL,
      configuration: objMouldingOffConfiguration,
    }));
  });

  return Promise.all(arrPromices);
  
};
