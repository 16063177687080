import { IConfiguration, ISetConfiguration } from "@threekit-tools/treble/dist/types";
import { ATTRIBUTES_MOULDING } from "../configuration/moulding";
import { getСompletedModelsNullNames } from "./../getNodesCabinets";
import { setNestedConfigurationFromNullName } from "../../../utils/threekit/general/nestedConfigurator";
import { NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../../utils/constants/attributesThreekit";
import { ATTRIBUTES_DECORATIVE_PANELS } from "../configuration/decorativePanel";

/**
 * Функція вимикає всі декоративні елементи для всіх Wall Cabinets.
 * Для вимкнення декоративних елементів під час переміщення однієї з моделей
 * Дозволяє коректно спрацьовувати конекторам (які враховують перетин моделей)
 *
 * @return {Promise<any>} Promice оновлення конфігурацій моделей.
 */
export const offAllDecorativeWallCabinets = () => {
  let arrPromices: Promise<any>[] = [];

  // Об'єкт конфігурації для вимкнення декоративних панелей
  const objMouldingOffConfiguration: ISetConfiguration | IConfiguration = {
    [ATTRIBUTES_MOULDING.MOULDING_TOP]: "no",
    [ATTRIBUTES_MOULDING.MOULDING_BOTTOM]: "no",
    [ATTRIBUTES_MOULDING.EDGE_SCRIBE_MOULDING]: "no",
    [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No"
  }

  const allСompletedNullWallCabinets = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_WALL);
  allСompletedNullWallCabinets.forEach((nullName) => {
    arrPromices.push(setNestedConfigurationFromNullName({
      nullName: nullName,
      attributeName: ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL,
      configuration: objMouldingOffConfiguration,
    }));
  });

  return Promise.all(arrPromices);
};
