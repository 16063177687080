import { IConfiguration, ICoordinates, ISetConfiguration, IThreekitDisplayAttribute } from "@threekit-tools/treble/dist/types";
import { getValueOptionFrigePanels } from "../../../store/selectors/settingsUISelectors";
import store from "../../../store/store";
import { CabinetsAndFeatures_NodesT, ModelsName_NodesT, NODES_THREEKIT, WallItemT } from "../../../utils/constants/nodesNamesThreekit";
import { getKeys } from "../../../utils/other/getObjKeysFromType";
import { getTranslationThreekit } from "../../../utils/threekit/general/getFunctions";
import { isFeaturesModelNullName } from "../../features/general";
import { getNumberNodeThreekitFromName } from "../../general";
import { RangeT, WallRangeT, getIntervalsWallCabinetsForAllWalls } from "../../intervals/getIntervalsOnWallForCabinetsWall";
import { getIntervalsBaseCabinetsForAllWalls } from "../../intervals/getIntervalsInfoOnWallBase";
import { isEqualCoordsTolerance } from "../addCornerModelBase";
import { getSizeModelBoxFromAssetCabinetBase } from "../cabinetsBase/size";
import { checkDefaultPositionCabinetsWall, checkDefaultPositionCabinetsWallFromParams } from "../cabinetsWall/position";
import { getSizeModelBoxFromAssetCabinetWall } from "../cabinetsWall/size";
import { isNullNameAppliances, isNullNameAppliancesFridge, isNullNameCabinetBase, isOTRCabinetWall, isUpperPantryCabinetWall } from "../checkModels";
import { ATTRIBUTES_DECORATIVE_PANELS, DecorativeFridgeSizeT, DecorativeLocationValuesT, DecorativeValuesT, getConfiguratorModelFromNullName, updateDecorativeForCabinetBaseUnderUpperPantry } from "./decorativePanel";
import { doIntervalsOverlap } from "../../intervals/intersectIntervals";
import { getVerticalIntervalModel } from "../../intervals/verticalIntervals";
import { areNumbersAlmostEqual } from "../../../utils/other/numberData";
import { inchesToMeters } from "../../../utils/other/measurements";
import { getСompletedModelsNullNames } from "./../getNodesCabinets";
import { setNestedConfigurationFromNullName } from "../../../utils/threekit/general/nestedConfigurator";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../../utils/constants/attributesThreekit";

export type FrigeConfigurationT = {
  [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]?: DecorativeValuesT,
  [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]?: DecorativeLocationValuesT,
  [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_FRIDGE_SIZE]?: DecorativeFridgeSizeT,
}

const getObjConfigurationForModel = (
  isPanelLeft: boolean,
  isPanelRight: boolean,
): FrigeConfigurationT => {

  let objConfiguration: FrigeConfigurationT = {
    [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No",
    [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: "Left and Right",
  }

  if (isPanelLeft && isPanelRight) {
    objConfiguration = {
      [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "Yes",
      [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: "Left and Right",
    }
  } else if (isPanelLeft && !isPanelRight) {
    objConfiguration = {
      [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "Yes",
      [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: "Left",
    }
  } else if (!isPanelLeft && isPanelRight) {
    objConfiguration = {
      [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "Yes",
      [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: "Right",
    }
  } else {
    objConfiguration = {
      [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No",
      [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: "Left",
    }
  }

  return objConfiguration;

}

const checkWallNeaborIntervalFree = ({
  intervalNeabor,
  verticalIntervalCurrentModel,
}: {
  intervalNeabor: WallRangeT | undefined,
  verticalIntervalCurrentModel: RangeT,
}): boolean => {
  // Робимо перевірку по настінних шафах:

  // 1. Робимо перевірку чи не пустий сусідній інтервал
  // якщо інтервал пустий та підходящого розміру
  // то повертаємо true (тобто деклративну панель можна додати з цієї сторони)
  if (
    intervalNeabor !== undefined &&
    intervalNeabor["empty"] &&
    intervalNeabor["range"][1] - intervalNeabor["range"][0] > 0.05
  ) return true;

  // 2. Якщо сусідній інтервал не пустий
  // Перевіряємо чи перетинаються проміжки по вертикалі для обраної шафи та шафи з сусіднього інтервалу(горизонтального)
  if (
    intervalNeabor !== undefined &&
    !intervalNeabor["empty"] &&
    intervalNeabor["name"] !== undefined
  ) {
    const positionCabinetWallFromIntervalWall = getTranslationThreekit({ name: intervalNeabor["name"] });
    const sizeCabinetWallFromIntervalWall = getSizeModelBoxFromAssetCabinetWall(intervalNeabor["name"]);
    // Перевіряємо чи перетинаються проміжки по вертикалі для обраної шафи та шафи з сусіднього інтервалу(горизонтального)
    const isVerticalIntervalOverlap = doIntervalsOverlap(
      getVerticalIntervalModel({
        positionModel: positionCabinetWallFromIntervalWall,
        sizeModel: sizeCabinetWallFromIntervalWall
      }),
      verticalIntervalCurrentModel,
      0.005
    );
    return !isVerticalIntervalOverlap;
  }

  return false;

}

const checkBaseNeaborIntervalFree = ({
  intervalNeabor
}: {
  intervalNeabor: WallRangeT | undefined,
}): boolean => {

  // Робимо перевірку по напольних шафах:
  if (
    intervalNeabor !== undefined &&
    intervalNeabor["empty"] &&
    intervalNeabor["range"][1] - intervalNeabor["range"][0] > 0.05
  ) return true;

  return false;

}

const getIsPanelSide = ({
  intervalFloor,
  intervalWall,
  verticalIntervalCurrentModel,
}: {
  intervalFloor: WallRangeT | undefined,
  intervalWall: WallRangeT | undefined,
  verticalIntervalCurrentModel: RangeT,
}): boolean => {

  // Робимо перевірку по настінних шафах:
  let isWallIntervalFree = checkWallNeaborIntervalFree({
    intervalNeabor: intervalWall,
    verticalIntervalCurrentModel
  });

  // Робимо перевірку по напольних шафах:
  let isBaseIntervalFree = checkBaseNeaborIntervalFree({intervalNeabor: intervalFloor});

  return isWallIntervalFree && isBaseIntervalFree


  // let isPanelSide = false;

  // let isDefaultPositionCabinetsWallFromIntervalWall: boolean = true;
  // if (
  //   intervalFloor !== undefined &&
  //   intervalWall !== undefined &&
  //   !intervalWall["empty"] &&
  //   intervalWall["name"] !== undefined
  // ) {
  //   const positionCabinetWallFromIntervalWall = getTranslationThreekit({ name: intervalWall["name"] });
  //   const sizeCabinetWallFromIntervalWall = getSizeModelBoxFromAssetCabinetWall(intervalWall["name"]);
  //   isDefaultPositionCabinetsWallFromIntervalWall = checkDefaultPositionCabinetsWallFromParams(
  //     positionCabinetWallFromIntervalWall,
  //     sizeCabinetWallFromIntervalWall
  //   )
  // }
  
  // if (
  //   intervalFloor !== undefined &&
  //   intervalWall !== undefined &&
  //   intervalFloor["empty"] &&
  //   (intervalWall["empty"] || (!isDefaultPositionCabinetsWallFromIntervalWall)) &&
  //   intervalFloor["range"][1] - intervalFloor["range"][0] > 0.05 &&
  //   intervalWall["range"][1] - intervalWall["range"][0] > 0.05
  // ) {
  //   isPanelSide = true;
  // }
  // return isPanelSide;
}

const getCabinetPosAttributeValue = (cabinetTopPosition: number, panelSizeAttribute: IThreekitDisplayAttribute | undefined): string | undefined => {
  let result: string | undefined = undefined;
  if (panelSizeAttribute !== undefined) {
    // @ts-ignore
    panelSizeAttribute.values.forEach((val) => {
      if (areNumbersAlmostEqual(cabinetTopPosition, inchesToMeters(parseInt(val.value)), 0.005))
        result = val.value;
    })
  }
  return result;
}

const getConfigurationOTRCabinets = ({
  activeObjConfiguration,
  floorNullName,
  positionCabinetWall,
  sizeCabinetWall,
  prevIntervalFloor,
  nextIntervalFloor,
  prevIntervalWall,
  nextIntervalWall,
  panelSizeAttribute,
}: {
  activeObjConfiguration: FrigeConfigurationT,
  floorNullName: CabinetsAndFeatures_NodesT,
  positionCabinetWall: ICoordinates,
  sizeCabinetWall: ICoordinates,
  prevIntervalFloor: WallRangeT | undefined,
  nextIntervalFloor: WallRangeT | undefined,
  prevIntervalWall: WallRangeT | undefined,
  nextIntervalWall: WallRangeT | undefined,
  panelSizeAttribute: IThreekitDisplayAttribute | undefined,
}) => {

  let resultObjConfiguration: FrigeConfigurationT = { ...activeObjConfiguration };

  // Перевіряємо що шафа розташована на потрібній висоті (84"=2.1336m  або 90"=2.286m)
  // Відстань до підлоги обчислюємо від верху шафи
  const cabinetTopPosition = positionCabinetWall["y"] + sizeCabinetWall["y"];
  // const isHeight84 = areNumbersAlmostEqual(cabinetTopPosition, 2.1336, 0.005);
  // const isHeight90 = areNumbersAlmostEqual(cabinetTopPosition, 2.286, 0.005);

  // Перевіряємо чи позиція шафи підходить під якесь зі значень атрибуту
  const cabinetPosAttributeValue = getCabinetPosAttributeValue(cabinetTopPosition, panelSizeAttribute);

  // Якщо шафа знаходиться не на підходящій висоті то вимикаємо декоративні панелі
  // if (!isHeight84 && !isHeight90) {
  //   resultObjConfiguration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE] = "No";
  //   return resultObjConfiguration;
  // }
  
  // Якщо значення атірибуту не азнайдено, вимикаємо декоративні панелі
  if (cabinetPosAttributeValue === undefined) {
    resultObjConfiguration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE] = "No";
    return resultObjConfiguration;
  }

  if (isNullNameAppliances(floorNullName)) {

    const positionFloorModel = getTranslationThreekit({ name: floorNullName });

    const isEqualCoords = isEqualCoordsTolerance(
      { ...positionCabinetWall, y: 0},
      { ...positionFloorModel, y: 0},
      0.05
    )
    // Якщо це холодильник
    // Якщо проекції координат на підлогу для OTR моделі та холодильника співпадають в якомусь околі
    // То модель OTR стоіть над холодильником
    if (
      isNullNameAppliancesFridge(floorNullName) &&
      isEqualCoords
    ) {

      const verticalIntervalUpperPantry = getVerticalIntervalModel({
        positionModel: positionCabinetWall,
        sizeModel: sizeCabinetWall
      });

      // Перевіряємо сусідів зліва для холодильника та OTR шкафа
      // це робимо перевіряючи сусідні інтервали зліва
      // якщо сусідні інтервали зліва пусті, то вмикаємо для OTR шкафа декоративну панель зліва
      let isPanelLeft = getIsPanelSide({
        intervalFloor: prevIntervalFloor,
        intervalWall: prevIntervalWall,
        verticalIntervalCurrentModel: verticalIntervalUpperPantry,
      });
      // Перевіряємо сусідів справа для холодильника та OTR шкафа
      // це робимо перевіряючи сусідні інтервали справа
      // якщо сусідні інтервали справа пусті, то вмикаємо для OTR шкафа декоративну панель справа
      let isPanelRight = getIsPanelSide({
        intervalFloor: nextIntervalFloor,
        intervalWall: nextIntervalWall,
        verticalIntervalCurrentModel: verticalIntervalUpperPantry,
      });

      resultObjConfiguration = getObjConfigurationForModel(isPanelLeft, isPanelRight);

    }

  }

  // if (isHeight84)
  //   resultObjConfiguration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_FRIDGE_SIZE] = "84\"";

  // if (isHeight90)
  //   resultObjConfiguration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_FRIDGE_SIZE] = "90\"";

  resultObjConfiguration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_FRIDGE_SIZE] = cabinetPosAttributeValue;

  return resultObjConfiguration;

}

const getConfigurationUpperPantryCabinets = ({
  activeObjConfiguration,
  floorNullName,
  positionCabinetUpperPantry,
  sizeCabinetUpperPantry,
  prevIntervalFloor,
  nextIntervalFloor,
  prevIntervalWall,
  nextIntervalWall,
}: {
  activeObjConfiguration: FrigeConfigurationT,
  floorNullName: CabinetsAndFeatures_NodesT,
  positionCabinetUpperPantry: ICoordinates,
  sizeCabinetUpperPantry: ICoordinates,
  prevIntervalFloor: WallRangeT | undefined,
  nextIntervalFloor: WallRangeT | undefined,
  prevIntervalWall: WallRangeT | undefined,
  nextIntervalWall: WallRangeT | undefined,
}) => {

  let resultObjConfiguration: FrigeConfigurationT = { ...activeObjConfiguration };

  // Перевіряємо чи це напольний шкаф такого ж розміру як настінна модель Upper Pantry
  if (isNullNameCabinetBase(floorNullName)) {
    const floorNullNameCabinetBase = floorNullName as ModelsName_NodesT;
    const sizeFloorModel = getSizeModelBoxFromAssetCabinetBase(floorNullNameCabinetBase);
    const positionFloorModel = getTranslationThreekit({ name: floorNullName });
    const isEqualCoords = isEqualCoordsTolerance(
      { ...positionCabinetUpperPantry, y: 0},
      { ...positionFloorModel, y: 0},
      0.05
    );

    // Перевіряємо чи напольний шкаф такого ж розміру як і настінний шкаф Upper Pantry
    // та перевіряємо на співпадіння проекції координат на підлогу для Upper Pantry моделі та напольного шкафа
    if (
      Math.abs(sizeCabinetUpperPantry["x"] - sizeFloorModel["x"]) < 0.05 &&
      isEqualCoords
    ) {

      const verticalIntervalUpperPantry = getVerticalIntervalModel({
        positionModel: positionCabinetUpperPantry,
        sizeModel: sizeCabinetUpperPantry
      });

      // Перевіряємо сусідів зліва для напольної шафи та Upper Pantry шафи
      // це робимо перевіряючи сусідні інтервали зліва
      // якщо сусідні інтервали зліва пусті, то вмикаємо для Upper Pantry шафи декоративну панель зліва
      const isPanelLeft = getIsPanelSide({
        intervalFloor: prevIntervalFloor,
        intervalWall: prevIntervalWall,
        verticalIntervalCurrentModel: verticalIntervalUpperPantry,
      });
      // Перевіряємо сусідів справа для напольного шафи та Upper Pantry шафи
      // це робимо перевіряючи сусідні інтервали справа
      // якщо сусідні інтервали справа пусті, то вмикаємо для Upper Pantry шафи декоративну панель справа
      const isPanelRight = getIsPanelSide({
        intervalFloor: nextIntervalFloor,
        intervalWall: nextIntervalWall,
        verticalIntervalCurrentModel: verticalIntervalUpperPantry,
      });

      resultObjConfiguration = getObjConfigurationForModel(isPanelLeft, isPanelRight);

    }
  }

  return resultObjConfiguration;

}

export const updateFrigePanels = (valueParamFrigePanelsUI?: boolean) => {

  const state = store.getState();

  const frigePanelsValueUI = getValueOptionFrigePanels(state) as boolean;

  const valueFrigePanels = valueParamFrigePanelsUI !== undefined ? valueParamFrigePanelsUI : frigePanelsValueUI

  const intervalsCabinetsWallForAllWalls = getIntervalsWallCabinetsForAllWalls();
  const intervalsCabinetsBaseForAllWalls = getIntervalsBaseCabinetsForAllWalls();

  // перебираємо всі стіни для яких створені інтервали
  const arrWallNames = getKeys(intervalsCabinetsWallForAllWalls);
  arrWallNames.forEach((planeName) => {

    const wallIntervals = intervalsCabinetsWallForAllWalls[planeName];

    if (wallIntervals.length < 2) return;

    let prevIntervalWall: WallRangeT | undefined = undefined;
    // перебираємо всі інтервали на стіні
    wallIntervals.forEach((objWallInterval, indx) => {

      const nextIntervalWall: WallRangeT | undefined = wallIntervals[indx + 1];

      if (
        // 1. Перевіряємо чи інтервал не пустий
        objWallInterval["empty"] ||
        objWallInterval["name"] === undefined
      ) {
        prevIntervalWall = objWallInterval;
        return;
      }

      // 2. Перевіряємо чи це не інтервал для вікон, дверей або проемів
      if (isFeaturesModelNullName(objWallInterval["name"])) {
        prevIntervalWall = objWallInterval;
        return;
      }

      const isOTR = isOTRCabinetWall(objWallInterval["name"]);
      const isUpperPantry = isUpperPantryCabinetWall(objWallInterval["name"]);
      const sizeCabinetWall = getSizeModelBoxFromAssetCabinetWall(objWallInterval["name"]);

      if (
        // 3. Перевіряємо, що це не модель OTR
        // 4. Перевіряємо, що модель не Upper Pantry (шафа для кладової, яка встановлюється на напольні шафи відповідного розміру)
        (!isOTR && !isUpperPantry) ||
        // 5. Перевіряємо чи глубина моделі менше 24" (24" === 0.6096m)
        sizeCabinetWall["z"] < 0.58
      ) {
        prevIntervalWall = objWallInterval;
        return;
      }

      // В результаті залишаються шафи для кладової (Upper Pantry) та шафи Bridge Cabinets
      
      const positionCabinetWall = getTranslationThreekit({ name: objWallInterval["name"] });
      const configuratorCabinetWall = getConfiguratorModelFromNullName(objWallInterval["name"]);
      if (
        // // 6. Перевіряємо чи настінна модель знаходиться на стандартній висоті від підлоги
        // !checkDefaultPositionCabinetsWallFromParams(positionCabinetWall, sizeCabinetWall) ||
        // 7. Якщо Frige Panels вимкнено в UI, то вимикаємо декоративні панелі
        !valueFrigePanels
      ) {
        prevIntervalWall = objWallInterval;
        return configuratorCabinetWall.setConfiguration({
          [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No",
        });
      }

      // 8. Перевіряємо, якщо модель Upper Pantry і знаходиться не на дефолтній висоті (не з'єднана з напольною моделлю)
      // То вимикаємо декоративні панелі
      if (
        isUpperPantry &&
        !checkDefaultPositionCabinetsWall(objWallInterval["name"])
      ) {
        prevIntervalWall = objWallInterval;
        return configuratorCabinetWall.setConfiguration({
          [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No",
        });
      }

      // ПЕРЕВІРЯЄМО для настінної моделі умови по напольних моделях,
      // які розташовуються на тій же стіні
      
      const numPlane = getNumberNodeThreekitFromName(planeName);
      const nameWall = `${NODES_THREEKIT.WALL_ITEM}${numPlane}` as WallItemT;
      const floorIntervals = intervalsCabinetsBaseForAllWalls[nameWall];


      let objConfiguration: FrigeConfigurationT = {
        [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No",
        [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: "Left and Right",
      }
      let prevIntervalFloor: WallRangeT | undefined = undefined;
      // перебираємо інтервали для напольних моделей,
      // та шукаємо:
      // холодильник під Bridge Cabinets
      // напольну шафу відповідного розміру під шафою Upper Pantry
      floorIntervals.forEach((objFloorInterval, indx) => {

        const nextIntervalFloor: WallRangeT | undefined = floorIntervals[indx + 1];

        if (
          // 1. Перевіряємо чи інтервал на підлозі не пустий
          objFloorInterval["empty"] ||
          objFloorInterval["name"] === undefined
        ) {
          prevIntervalFloor = objFloorInterval;
          // Пропускаємо пусті інтервали
          return;
        }
        
        // Робимо перевірки для OTR шкафа
        // Та отримуємо конфігурацію для OTR шкафа
        if (isOTR) {

          const displayAttributesCabinetWall = configuratorCabinetWall.getDisplayAttributes();
          const panelSizeAttribute = displayAttributesCabinetWall.find((attr) => attr.name === ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_FRIDGE_SIZE);

          objConfiguration = getConfigurationOTRCabinets({
            activeObjConfiguration: objConfiguration,
            floorNullName: objFloorInterval["name"],
            positionCabinetWall: positionCabinetWall,
            sizeCabinetWall: sizeCabinetWall,
            prevIntervalFloor: prevIntervalFloor,
            nextIntervalFloor: nextIntervalFloor,
            prevIntervalWall: prevIntervalWall,
            nextIntervalWall: nextIntervalWall,
            panelSizeAttribute: panelSizeAttribute,
          });

        }

        // Робимо перевірки для Upper Pantry шкафа
        // Та отримуємо конфігурацію для Upper Pantry шкафа
        if (isUpperPantry) {

          objConfiguration = getConfigurationUpperPantryCabinets({
            activeObjConfiguration: objConfiguration,
            floorNullName: objFloorInterval["name"],
            positionCabinetUpperPantry: positionCabinetWall,
            sizeCabinetUpperPantry: sizeCabinetWall,
            prevIntervalFloor: prevIntervalFloor,
            nextIntervalFloor: nextIntervalFloor,
            prevIntervalWall: prevIntervalWall,
            nextIntervalWall: nextIntervalWall,
          });

          updateDecorativeForCabinetBaseUnderUpperPantry({
            floorNullName: objFloorInterval["name"],
            objConfigurationUpperPantry: objConfiguration,
            positionCabinetUpperPantry: positionCabinetWall,
            sizeCabinetUpperPantry: sizeCabinetWall,
          });

        }

        prevIntervalFloor = objFloorInterval;

      })

      prevIntervalWall = objWallInterval;

      configuratorCabinetWall.setConfiguration(objConfiguration);

    })

  })

}

/**
 * Функція вимикає великі декоративні панелі для всіх настінних моделей, які мають глубину 24".
 * Для вимкнення великих декоративних панелей під час переміщення однієї з моделей
 * Дозволяє коректно спрацьовувати конекторам (які враховують перетин моделей)
 *
 * @return {Promise<any>} Promice оновлення конфігурацій моделей.
 */
export const offFridgePanelsWallCabinets = (): Promise<any> => {

  let arrPromices: Promise<any>[] = [];

  // Об'єкт конфігурації для вимкнення декоративних панелей
  let objConfiguration: FrigeConfigurationT = {
    [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No"
  }

  const allСompletedNullWallCabinets = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_WALL);
  allСompletedNullWallCabinets.forEach((nullName) => {

    const isOTR = isOTRCabinetWall(nullName);
    const isUpperPantry = isUpperPantryCabinetWall(nullName);
    const sizeCabinetWall = getSizeModelBoxFromAssetCabinetWall(nullName);

    if ((isOTR || isUpperPantry) && sizeCabinetWall["z"] > 0.58) {
      arrPromices.push(setNestedConfigurationFromNullName({
        nullName: nullName,
        attributeName: ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL,
        configuration: objConfiguration,
      }));
    }
    
  });

  return Promise.all(arrPromices);
  
};