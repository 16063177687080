import { NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getTranslationThreekit } from "../../../utils/threekit/general/getFunctions";
import { getSizeModelRelativeTransform } from "../../intervals/getIntervalsOnWallForCabinetsWall";
import { isOTRCabinetWall } from "../checkModels";
import { getСompletedModelsNullNames } from "../getNodesCabinets";
import { getConfiguratorModelFromNullName } from "./decorativePanel";
import { areNumbersAlmostEqual } from "../../../utils/other/numberData";

export const FILLER_STRIP_WIDTH = 0.075; // 2.992in
const COORDS_MATCH_ACCURACY = 0.02;

export const enum ATTRIBUTES_FILLER_STRIP {
  FILLER_STRIP = "Filler_Strip",
}
export type CountertopValuesT = "no" | "yes";
export type MouldingConfigurationT = {
  [ATTRIBUTES_FILLER_STRIP.FILLER_STRIP]?: CountertopValuesT,
}

export const updateFillerStripForCabinetsWallOTR = () => {

  const allCabinetsWall = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_WALL);
  const allAppliances = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_APPLIANCES);

  allCabinetsWall.forEach((nullNameCabinetWall) => {
    if (isOTRCabinetWall(nullNameCabinetWall)) {
      const configuratorORTCabinetWall = getConfiguratorModelFromNullName(nullNameCabinetWall);
      const positionOTRCabinetWall = getTranslationThreekit({name: nullNameCabinetWall});

      if (allAppliances.length < 1)
        return configuratorORTCabinetWall.setConfiguration({ [ATTRIBUTES_FILLER_STRIP.FILLER_STRIP]: "no" });

      allAppliances.forEach((nullNameAppliance) => {
        const sizeAppliance = getSizeModelRelativeTransform(nullNameAppliance);
        const positionAppliance = getTranslationThreekit({name: nullNameAppliance});
        if (
          areNumbersAlmostEqual(positionAppliance["x"], positionOTRCabinetWall["x"], COORDS_MATCH_ACCURACY) &&
          areNumbersAlmostEqual(positionAppliance["z"], positionOTRCabinetWall["z"], COORDS_MATCH_ACCURACY)
        ) {
          if (
            positionOTRCabinetWall["y"] - sizeAppliance["y"] > FILLER_STRIP_WIDTH &&
            positionOTRCabinetWall["y"] - sizeAppliance["y"] < FILLER_STRIP_WIDTH * 3
          ) {
            configuratorORTCabinetWall.setConfiguration({ [ATTRIBUTES_FILLER_STRIP.FILLER_STRIP]: "yes" });
          } else {
            configuratorORTCabinetWall.setConfiguration({ [ATTRIBUTES_FILLER_STRIP.FILLER_STRIP]: "no" });
          }
        } else {
          configuratorORTCabinetWall.setConfiguration({ [ATTRIBUTES_FILLER_STRIP.FILLER_STRIP]: "no" });
        }
      })
    }
  });

}