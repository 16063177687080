import { ICoordinates } from "@threekit-tools/treble/dist/types";
import { FeaturesName_NodesT } from "../../utils/constants/nodesNamesThreekit";
import { ArrWallRangesT, RangeT, getSizeModelRelativeTransform } from "../intervals/getIntervalsOnWallForCabinetsWall";
import { getVector3FromCoordinates } from "../../utils/three/general/getFunctionsTHREE";
import { getFeaturesPosition } from "./positionsFeature";
import { calculatePerpendicularProjectionPointToLine } from "../../utils/three/perpendicularProjectionPointToLine";
import { getFeaturesSize } from "./sizeFeatures";

export const getWallRangesFeaturesFilled = (
  arrNullNamesBaseFeatures: FeaturesName_NodesT[],
  planeStartEnd: [ICoordinates, ICoordinates]
): ArrWallRangesT => {
  let wallRangesFeaturesFilled: ArrWallRangesT = [];

  const planeStartVector = getVector3FromCoordinates({ ...planeStartEnd[0], y: 0 });
  const planeEndVector = getVector3FromCoordinates({ ...planeStartEnd[1], y: 0 });

  arrNullNamesBaseFeatures.forEach((nullNameFeature) => {
    const modelNullTranslation = getFeaturesPosition(nullNameFeature);
    const featuresSize = getFeaturesSize(nullNameFeature);
    const modelWidth = featuresSize["x"];

    const pointInPlane = calculatePerpendicularProjectionPointToLine(
      modelNullTranslation,
      planeStartVector,
      planeEndVector
    );

    const distanceFromPlaneStartToFeatureNull = planeStartVector.distanceTo(pointInPlane);
    const rangeFeature: RangeT = [
      distanceFromPlaneStartToFeatureNull,
      distanceFromPlaneStartToFeatureNull + modelWidth,
    ];
    const rangeVertical: RangeT = [
      modelNullTranslation["y"],
      modelNullTranslation["y"] + featuresSize["y"],
    ];
    wallRangesFeaturesFilled.push({
      empty: false,
      range: rangeFeature,
      name: nullNameFeature,
      rangeVertical
    });
  })

  return wallRangesFeaturesFilled;

}