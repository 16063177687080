import { CabinetsAndFeatures_NodesT, NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import {
  getInstanceIdAssetFromNullModel,
  getNameNodeThreekit,
  getTranslationThreekit,
} from "../../../utils/threekit/general/getFunctions";
import { getSizeModelBoxFromAssetCabinetWall } from "./size";
import { getСompletedModelsNullNames } from "../getNodesCabinets";
import { areNumbersAlmostEqual } from "../../../utils/other/numberData";
import { setActiveTagsThreekit } from "../../../utils/threekit/general/setFunctions";
import { CONNECTORS_CABINET_WALL } from "../../../utils/constants/connectors";

const updateTopConnectorsTagsCabinetWall = (nullNameModel: string, value: boolean) => {
  const instanceIdAssetModel = getInstanceIdAssetFromNullModel({ name: nullNameModel });
  // Верхній лівий конектор
  setActiveTagsThreekit({
    from: instanceIdAssetModel,
    name: CONNECTORS_CABINET_WALL.TOP_LEFT,
    value: value,
  });
  // Верхній правий конектор
  setActiveTagsThreekit({
    from: instanceIdAssetModel,
    name: CONNECTORS_CABINET_WALL.TOP_RIGHT,
    value: value,
  });
};

export const checkConnectorsForUpperPantry = (idSelectedModel: string, valueActiveConectors: boolean) => {
  const nullNameSelectedModel = getNameNodeThreekit({ id: idSelectedModel }) as CabinetsAndFeatures_NodesT;
  const sizeSelectedModel = getSizeModelBoxFromAssetCabinetWall(nullNameSelectedModel);
  const positionSelectedModel = getTranslationThreekit({ name: nullNameSelectedModel });
  const topPositionSelectedModel = positionSelectedModel["y"] + sizeSelectedModel["y"];
  const allСompletedCabinetsWall = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_WALL);
  allСompletedCabinetsWall.forEach((nullNameModel) => {
    if (nullNameModel === nullNameSelectedModel) return;
    const sizeModel = getSizeModelBoxFromAssetCabinetWall(nullNameModel);
    const positionModel = getTranslationThreekit({ name: nullNameModel });
    const topPositionModel = positionModel["y"] + sizeModel["y"];
    if (!areNumbersAlmostEqual(topPositionSelectedModel, topPositionModel, 0.005)) {
      // позиція по верху для виділеної моделі Upper Pantry не співпадає з позицією по верху для іншої доданої моделі
      updateTopConnectorsTagsCabinetWall(nullNameModel, valueActiveConectors)
    }
  });
};
