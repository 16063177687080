import React, { useContext, useEffect, useRef, useState } from "react";
import { SceneControlsBottom2D } from "../../components/SceneControls/SceneControlsBottom2D/SceneControlsBottom2D";
import { useAppDispatch, useAppSelector } from "../../hooks/useStoreHooks";
import { setModalVisible } from "../../store/slices/modalsSlice/modalsSlice";
import InitializationProvider from "../../utils/initializationProvider/initializationProvider";

// scss
import s from "./RoomBuilder2D.module.scss";
import { MainMenuRoomBuilder } from "../../components/MainMenu/MainMenuRoomBuilder/MainMenuRoomBuilder";
import { SceneControlsTop2D } from "../../components/SceneControls/SceneControlsTop2D/SceneControlsTop2D";
import { buildWalls2DFromTemplate } from "../../functionsConfigurator/roomBuilder/buildWallsFromTemplate";
import { PlayerRoomBuilder2D } from "../../components/PlayerThreeKit/PlayerRoomBuilder2D/PlayerRoomBuilder2D";
import {
  getActiveSettingUI,
  getInitialWalls,
  getMeasurement,
} from "../../store/selectors/settingsUISelectors";
import { MODAL_KEYS } from "../../store/slices/modalsSlice/modalsSliceT";
import { useSpaces, useThreekitInitStatus } from "@threekit-tools/treble/dist";
import { IModes } from "@threekit-tools/treble/dist/hooks/useSpaces/types";
import { setHistory2D } from "../../store/slices/history/historySlice";
import { getPriceDataTableFromThreekit } from "../../api/apiThreekit";
import { setPriceTable } from "../../store/slices/dataTablesSlice/dataTablesSlice";
import { ModalsGlobal } from "../../components/Modals/ModalsGlobal/ModalsGlobal";
import { setAllSettingConfig } from "../../store/slices/settingsUI/settingsUISlice";
import { EditSvg } from "../../assets/images/svg/Edit";
import { WallModes } from "@threekit-tools/treble/dist/hooks/useSpaces/constants";
import WhalenClickItLogo from "./../../assets/images/Whalen_Click_It_Logo_White.png"

export const RoomBuilder2D = () => {
  const { initializationThreekit } = useContext(InitializationProvider);
  const [isLoadTemplate, setIsLoadTemplate] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const hasLoadedThreekit = useThreekitInitStatus();
  const measurementValue = useAppSelector(getMeasurement);
  const activeSettingUI = useAppSelector(getActiveSettingUI);

  const dataRoomBuilder = useSpaces({
    config: {
      unit: measurementValue,
      zoomDefault: 20,
      translateDefault: [0, 0],
      zoomSensitivity: 0.02,
    },
    attributes: {
      walls: "Walls",
      windows: "Windows",
      doors: "Doors",
      openings: "Openings",
    },
  });

  const {
    canvasRef,
    walls,
    mode,
    zoom,
    reset,
    dimensions,
    translate,
    unit,
    recenter,
    hasLoaded,
    wallMode,
  } = dataRoomBuilder;

  const initialTemplateWalls = useAppSelector((state) =>
    getInitialWalls(state)
  );
  const sceneContentDiv = useRef<HTMLDivElement>(null);

  // const setTranslateRoom2DToCenter = (arrWalls: any) => {
  //   let areaRoomSize: AreaRoomI = { width: 0, length: 0 };
  //   if (arrWalls[0].hasOwnProperty("assetId")) {
  //     areaRoomSize = calculateAreaFromThreekit(arrWalls);
  //   } else {
  //     areaRoomSize = calculateAreaFromTemplate(arrWalls);
  //   }
  //   if (sceneContentDiv["current"] !== null) {
  //     const canvasWidth = sceneContentDiv["current"]["clientWidth"];
  //     const canvasHeight = sceneContentDiv["current"]["clientHeight"];
  //     translate.handleChange([
  //       canvasWidth / 2 - (areaRoomSize["width"] * 100) / 2 / 2,
  //       canvasHeight / 2 - (areaRoomSize["length"] * 100) / 2 / 2,
  //     ]);
  //   }
  // };

  // Обпрацювання браузерної події оновлення або закриття сторінки
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Perform actions before the component unloads
      event.preventDefault();
      event.returnValue = "Refreshing the page or returning to the browser will cause all progress to be lost.";
    };
    if (walls !== undefined && walls["value"].length > 0) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [walls]);

  useEffect(() => {
    if (activeSettingUI.length < 1) {
      // loadCollisionPhysics();
      dispatch(setAllSettingConfig());
    };
  }, [])

  useEffect(() => {
    dispatch(setModalVisible({ modalId: MODAL_KEYS.HELP_2D, value: true }));
    dispatch(setModalVisible({ modalId: MODAL_KEYS.LOADER, value: false }));
    // window.history.pushState(null, "", window.location.href);

    if (
      !!walls &&
      walls["value"].length > 0 &&
      sceneContentDiv["current"] !== null
    ) recenter(false);
      // setTranslateRoom2DToCenter(walls["value"]);
  }, []);

  useEffect(() => {
    if (hasLoadedThreekit) {
      if (hasLoadedThreekit && initialTemplateWalls.length === 0) {
        setIsLoadTemplate(false);
      } else if (hasLoadedThreekit && initialTemplateWalls.length > 0) {
        mode.handleChange(IModes.SELECT);
      }
      setIsLoadTemplate(false);
    }
  }, [initialTemplateWalls]);

  useEffect(() => {
    if (
      hasLoaded &&
      initialTemplateWalls.length > 0 &&
      !!walls &&
      walls["value"].length === 0
    ) {
      setIsLoadTemplate(true);
      buildWalls2DFromTemplate(initialTemplateWalls).then(() => {
        setIsLoadTemplate(false);
        setTimeout(() => {
          recenter(false);
        }, 100);
      });
      // if (sceneContentDiv["current"] !== null)
      //   setTranslateRoom2DToCenter(initialTemplateWalls);
      mode.handleChange(IModes.SELECT);
      wallMode.handleChange(WallModes.WALL)
    }

    if (initialTemplateWalls.length === 0) {
      mode.handleChange(IModes.DRAW);
      wallMode.handleChange(WallModes.WALL)
      setIsLoadTemplate(false);
    }

  }, [hasLoaded]);

  
  // 2D Builder History
  useEffect(() => {
    if (hasLoadedThreekit) {
      dispatch(setHistory2D({
        configuration: window.threekit.configurator.getFullConfiguration()
      }));
    }
  }, [dataRoomBuilder]);

  // Get Price Table
  useEffect(() => {
    if (hasLoadedThreekit) {
      getPriceDataTableFromThreekit().then((res) => {
        dispatch(setPriceTable(res));
      })
    }
  }, [hasLoadedThreekit]);

  useEffect(() => {
    if (hasLoaded) {
      recenter(false);
    }
  }, [initialTemplateWalls])

  // useEffect(() => {

  //   if (hasLoaded) {
  //     const attributeWall = getValueAttributeThreekit(ATTRIBUTES_NAMES_THREEKIT.WALLS);
  //     const fullConfigurationWalls = getFullConfigurationAttribute(ATTRIBUTES_NAMES_THREEKIT.WALLS)

  //     // @ts-ignore
  //     window.threekit.player.evaluate().then(() => {
  //       const appliedConfigurationWalls = getAppliedConfigurationThreekitAttribute(ATTRIBUTES_NAMES_THREEKIT.WALLS) as string[];
  //       appliedConfigurationWalls.forEach((id: string) => {
  //         const evalNodeWall = getEvalNode({ id });
  //         if(evalNodeWall === undefined) return;
  //         const wallConfigurator = evalNodeWall["configurator"];
  //         // @ts-ignore
  //         const currentConfiguration = wallConfigurator["configuration"];
  //       })
  //     })
      
  //   }
    
  // }, [hasLoaded, dataRoomBuilder]);

  return (
    <div className={s.pageGrid}>
      <div className={s.mobHeader}><img src={WhalenClickItLogo} alt="Whalen Click-It" /></div>
      <aside className={s.mainSidebar}>
        <MainMenuRoomBuilder {...dataRoomBuilder} />
      </aside>
      <div className={s.sceneContent} ref={sceneContentDiv}>
        <PlayerRoomBuilder2D {...dataRoomBuilder} />
        {(!hasLoaded || isLoadTemplate) && <div className={s.sceneLoader}>Loading...</div>}
        <SceneControlsTop2D unit={unit} />
        <SceneControlsBottom2D
          zoom={zoom}
          deleteAllWalls={reset}
          dimensions={dimensions}
        />
        {mode["value"] !== "select" &&
          <div className={s.infoSelectModify}>
            <EditSvg />
            <p className={s.text}>To edit or delete a specific object, choose <b>'Select & Modify'</b> from the side menu</p>
          </div>
        }
      </div>
      <ModalsGlobal />
    </div>
  );
};
