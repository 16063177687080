// images
import Cabinet01 from "./../../assets/images/Cabinets/Cabinet_01.png";
import Cabinet02 from "./../../assets/images/Cabinets/Cabinet_02.png";
import Cabinet03 from "./../../assets/images/Cabinets/Cabinet_03.png";
import Cabinet04 from "./../../assets/images/Cabinets/Cabinet_04.png";
import Cabinet05 from "./../../assets/images/Cabinets/Cabinet_05.png";
import NotImage from "./../../assets/images/Cabinets/not_image.png";
// Cabinets Base Images
import Cabinet1001 from "./../../assets/images/Cabinets/Base_Cabinet/1001_12in_Single_Door_Base_Cabinet.png";
import Cabinet1002 from "./../../assets/images/Cabinets/Base_Cabinet/1002_18in_Single_Door_Base_Cabinet.png";
import Cabinet1003 from "./../../assets/images/Cabinets/Base_Cabinet/1003_18in_Single_Door_One_Drawer_Base_Cabinet.png";
import Cabinet1004 from "./../../assets/images/Cabinets/Base_Cabinet/1004_18in_Three_Drawer_Base_Cabinet.png";
import Cabinet1005 from "./../../assets/images/Cabinets/Base_Cabinet/1005_24in_Two_Door_Base_Cabinet.png";
import Cabinet1006 from "./../../assets/images/Cabinets/Base_Cabinet/1006_24in_Two_Door_One_Drawer_Base_Cabinet.png";
import Cabinet1007 from "./../../assets/images/Cabinets/Base_Cabinet/1007_24in_Three_Drawer_Base_Cabinet.png";
import Cabinet1008 from "./../../assets/images/Cabinets/Base_Cabinet/1008_30in_Two_Door_Base_Cabinet.png";
import Cabinet1009 from "./../../assets/images/Cabinets/Base_Cabinet/1009_30in_Two_Door_One_Drawer_Base_Cabinet.png";
import Cabinet1010 from "./../../assets/images/Cabinets/Base_Cabinet/1010_30in_Three_Drawer_Base_Cabinet.png";
import Cabinet1011 from "./../../assets/images/Cabinets/Base_Cabinet/1011_30in_Two_Door_Sink_Base_Cabinet.png";
import Cabinet1012 from "./../../assets/images/Cabinets/Base_Cabinet/1012_36in_Two_Door_Base_Cabinet.png";
import Cabinet1013 from "./../../assets/images/Cabinets/Base_Cabinet/1013_36in_Two_Door_One_Drawer_Base_Cabinet.png";
import Cabinet1014 from "./../../assets/images/Cabinets/Base_Cabinet/1014_36in_Three_Drawer_Base_Cabinet.png";
import Cabinet1015 from "./../../assets/images/Cabinets/Base_Cabinet/1015_36in_Two_Door_Sink_Base_Cabinet.png";
import Cabinet1016_Left from "./../../assets/images/Cabinets/Base_Cabinet/1016_36in_Blind_Corner_Base_Cabinet_Left.png";
import Cabinet1016_Right from "./../../assets/images/Cabinets/Base_Cabinet/1016_36in_Blind_Corner_Base_Cabinet_Right.png";
import Cabinet1017 from "./../../assets/images/Cabinets/Base_Cabinet/1017_36in_Lazy_Suzan_Corner_Base_cabinet.png";

// Cabinets Wall Images
import Cabinet1018 from "./../../assets/images/Cabinets/Wall_Cabinet/1018_12in_Single_Door_Wall_Cabinet.png";
import Cabinet1019 from "./../../assets/images/Cabinets/Wall_Cabinet/1019_18in_Single_Door_Wall_Cabinet.png";
import Cabinet1020 from "./../../assets/images/Cabinets/Wall_Cabinet/1020_24in_Two_Door_Wall_Cabinet.png";
import Cabinet1021 from "./../../assets/images/Cabinets/Wall_Cabinet/1021_24in_Two_Door_Wall_Cabinet_36in_H.png";
import Cabinet1022 from "./../../assets/images/Cabinets/Wall_Cabinet/1022_24in_Corner_Wall_Cabinet.png";
import Cabinet1023 from "./../../assets/images/Cabinets/Wall_Cabinet/1023_24in_Corner_Wall_Cabinet_36in_H.png";
import Cabinet1024 from "./../../assets/images/Cabinets/Wall_Cabinet/1024_30in_Two_Door_Wall_Cabinet.png";
import Cabinet1025 from "./../../assets/images/Cabinets/Wall_Cabinet/1025_30in_Two_Door_Wall_Cabinet_36in_H.png";
import Cabinet1026 from "./../../assets/images/Cabinets/Wall_Cabinet/1026_30in_Two_Door_Above_the_Fridge_or_OTR_Wall_Cabinet.png";
import Cabinet1027 from "./../../assets/images/Cabinets/Wall_Cabinet/1027_30in_Two_Door_Above_the_Fridge_or_OTR_Wall_Cabinet_24in_D.png";
import Cabinet1028 from "./../../assets/images/Cabinets/Wall_Cabinet/1028_36in_Two_Door_Wall_Cabinet.png";
import Cabinet1029 from "./../../assets/images/Cabinets/Wall_Cabinet/1029__36in_Two_Door_Above_the_Fridge_or_OTR_Wall_Cabinet.png";
import Cabinet1030 from "./../../assets/images/Cabinets/Wall_Cabinet/1030_36in_Two_Door_Above_the_Fridge_or_OTR_Wall_Cabinet_24in_D.png";
import Cabinet1031 from "./../../assets/images/Cabinets/Wall_Cabinet/1031_18in_Single_Door_Upper_Pantry_Cabinet.png";
import Cabinet1032 from "./../../assets/images/Cabinets/Wall_Cabinet/1032_24in_Two_Door_Upper_Pantry_Cabinet.png";
import Cabinet1033 from "./../../assets/images/Cabinets/Wall_Cabinet/1033_30in_Two_Door_Above_the_Fridge_or_OTR_Wall_Cabinet_18in_H.png";

// Appliances
import Sink from "./../../assets/images/Appliances/Group/Sink.png";
import Refrigerator from "./../../assets/images/Appliances/Group/Refrigerator.png";
import Oven from "./../../assets/images/Appliances/Group/Oven.png";
import Dishwasher from "./../../assets/images/Appliances/Group/Dishwasher.png";
import { THREEKIT_IMAGES_URL } from "../threekit/threekitConfig/threekitConfig";

const IMAGES_URL = THREEKIT_IMAGES_URL;

export const CABINETS_BASE_MAX_LENGTH = 25;
export const CABINETS_WALL_MAX_LENGTH = 25;
export const CABINETS_ISLAND_MAX_LENGTH = 10;
export const APPLIANCES_MAX_LENGTH = 10;

export const ID_CORNER = "LWSCLICK-22";
export const ID_BLIND_CORNER = "LWSCLICK-23";

export type CategoryOptionImagesT = {
  framedWhite: string;
  framedGrey: string;
  // framelessWhite: string;
  // framelessGrey: string;
};

export interface CategoryOption {
  id: string;
  value: PRODUCT_GROUP_ALL_T;
  label: string;
  images: CategoryOptionImagesT;
}
export type GroupCabinetsOptionsPositionsT = {
  [key in PRODUCT_SECTION_GROUP_T]?: CategoryOption[];
};
export type GroupCabinetsOptionsT = {
  [key in PRODUCT_POSITIONS_KEYS]: GroupCabinetsOptionsPositionsT;
};

export interface CabinetSizeI {
  width: number;
  depth: number;
  height: number;
}

export const enum PRODUCT_POSITIONS_KEYS {
  BASE_CABINET = "Cabinets Base",
  WALL_CABINET = "Cabinets Wall",
  ISLAND_CABINET = "Cabinets Island",
  APPLIANCES = "Appliances",
}

export enum PRODUCT_SECTION_GROUP_BASE {
  DOORS_ONLY = "Doors Only",
  DRAWERS_ONLY = "Drawers Only",
  DRAWER_AND_DOOR = "Drawer and Door",
  CORNER_UNITS = "Corner Units",
  SINK_UNITS = "Sink Units",
}
export enum PRODUCT_SECTION_GROUP_WALL {
  DOORS_ONLY = "Doors Only",
  CORNER_UNITS = "Corner Units",
  UPPER_PANTRY_UNIT = "Upper Pantry Unit",
  ABOVE_THE_FRIDGE_RANGE = "Above the Fridge/Range",
}
export enum PRODUCT_SECTION_GROUP_APPLIANCES {
  // SINK = "Sink",
  REFRIGERATOR = "Refrigerator",
  RANGE = "Range",
  DISHWASHER = "Dishwasher",
}
export const PRODUCT_SECTION_GROUP_ALL = {
  ...PRODUCT_SECTION_GROUP_BASE,
  ...PRODUCT_SECTION_GROUP_WALL,
};
export type PRODUCT_SECTION_GROUP_T =
  | PRODUCT_SECTION_GROUP_BASE
  | PRODUCT_SECTION_GROUP_WALL
  | PRODUCT_SECTION_GROUP_APPLIANCES;
export type PRODUCT_SECTION_GROUP_ALL_T =
  | PRODUCT_SECTION_GROUP_BASE
  | PRODUCT_SECTION_GROUP_WALL
  | PRODUCT_SECTION_GROUP_APPLIANCES
  | "All";

export enum PRODUCT_GROUP_BASE {
  ONE_DOOR = "1 Door",
  TWO_DOOR = "2 Door",
  ONE_DRIWER = "1 Drawer",
  TWO_DRIWER = "2 Drawer",
  THREE_DRIWER = "3 Drawer",
  ONE_DRIWER_ONE_DOOR = "1 Drawer, 1 Door",
  ONE_DRIWER_TWO_DOOR = "1 Drawer, 2 Door",
  TWO_DRIWER_TWO_DOOR = "2 Drawer, 2 Door",
  BLIND_CORNER = "Blind Corner",
  LAZY_SUSAN_CORNER = "Corner",
  SINK_UNITS = "Sink Units",
}
export enum PRODUCT_GROUP_WALL {
  ONE_DOOR = "1 Door",
  TWO_DOOR = "2 Door",
  CORNER_UNITS = "Corner Units",
  UPPER_PANTRY_ONE_DOOR = "Upper Pantry 1 Door",
  UPPER_PANTRY_TWO_DOOR = "Upper Pantry 2 Door",
  ABOVE_THE_FRIDGE_RANGE = "Above the Fridge/Range",
}
export enum PRODUCT_GROUP_APPLIANCES {
  // SINK = "Sink",
  REFRIGERATOR = "Refrigerator",
  RANGE = "Range",
  DISHWASHER = "Dishwasher",
}
export const PRODUCT_GROUP_ALL = {
  ...PRODUCT_GROUP_BASE,
  ...PRODUCT_GROUP_WALL,
};
export type PRODUCT_GROUP_ALL_T =
  | PRODUCT_GROUP_BASE
  | PRODUCT_GROUP_WALL
  | PRODUCT_GROUP_APPLIANCES;

export interface ProductI {
  id: string;
  position: PRODUCT_POSITIONS_KEYS;
  group: PRODUCT_GROUP_ALL_T;
  value: string;
  label: string;
  img: string;
  description?: string;
  doorDirection?: string;
  size: CabinetSizeI;
  shelfs?: number;
  adjustableFeet?: number;
  sink?: boolean;
  disabled?: boolean;
}
export type ProductArrT = ProductI[];

export const groupCabinetsOptions: GroupCabinetsOptionsT = {
  [PRODUCT_POSITIONS_KEYS.BASE_CABINET]: {
    [PRODUCT_SECTION_GROUP_BASE.DOORS_ONLY]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.ONE_DOOR,
        label: "1 Door",
        images: {
          // Cabinet1001,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-713dc8d3c6f89de2d431ce6d3036fed302eebb8a8412db62c3d2499532b1bac0`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-acd2c2a1796cd1abecdf0412046f9cf4acaf232cf06fe7cbcf277a831afaac4c`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-22644f1be30f14e18d5d057d46115666067f2c65b330bab6c94c06b107037621`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-ee66bd889fb93f004538ab8a3e2681eff3740ebe912c3637887db6be08471902`,
        },
      },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.TWO_DOOR,
        label: "2 Door",
        images: {
          // Cabinet1008,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-f977393f07606b5a95c3aea12586b98b081a829bcf0d474b9e92afd0fa5d9e5a`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-977981699e13b68ab10ab365777e38fa19b741d18277f47436e74ed4f2fc93dc`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-a6695c33991b01307498b699d5f18f7abcbba55ab9b459c5c73dbe543c43aa65`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-b37b17d55397bb502790dae2853cda00b60888e97007a0570b6ac273a22079b0`,
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_BASE.DRAWERS_ONLY]: [
      // {
      //   id: Math.floor(100000 + Math.random() * 900000),
      //   value: PRODUCT_GROUP_BASE.ONE_DRIWER,
      //   label: "1 Drawer",
      //   img: Cabinet1004,
      // },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.TWO_DRIWER,
        label: "2 Drawer",
        images: {
          // Cabinet1004,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-58b7985f0966845e20f10a810b38b512e3a0513c07909946d506f81ecc720120`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-d913ebba6b93c805ebcf521a1e69b834a131e54556128f48ccc21a0e827a35f0`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-2b2b0b5d6bd0593dd793d49048741bc8f8175de3e0986660276fd8c1ed9f502c`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-000fa5287899fef28aa7e8295b7bd87ad54b25bb0fab5d78a431c968efb27341`,
        },
      },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.THREE_DRIWER,
        label: "3 Drawer",
        images: {
          // Cabinet1004,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-d629eaf284cdba57e5af6f826455d74bd8fe4528b57d3e97e50f546efcabc0b0`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-d913ebba6b93c805ebcf521a1e69b834a131e54556128f48ccc21a0e827a35f0`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-2b2b0b5d6bd0593dd793d49048741bc8f8175de3e0986660276fd8c1ed9f502c`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-000fa5287899fef28aa7e8295b7bd87ad54b25bb0fab5d78a431c968efb27341`,
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_BASE.DRAWER_AND_DOOR]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.ONE_DRIWER_ONE_DOOR,
        label: "1 Drawer, 1 Door",
        images: {
          // Cabinet1003,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-f612081241181082cac0e203f98300a06492e947cf097546ba9c374fdfa3a86a`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-60365842fedb964916221ef7bf5ca588380488a08a7d1c4fd1f67e66d657c772`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-f0f2132bbfab956298a9fa010a25588ac0eea4f69b045838c7f5dec7453aae87`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-d493740f83d419c4edcc99cbfde7962b6b1c89ff8a4acd9ac094169dac603c9d`,
        },
      },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.ONE_DRIWER_TWO_DOOR,
        label: "1 Drawer, 2 Doors",
        images: {
          // Cabinet1006,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-2860448561abfc6974a0e010da76437484d09cb4e44e33b7a536f283ed234bf3`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-c45b265d809ec50bfa3a381b295f82a530d1c5dbb88ff90b17398afa835a7adb`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-f7aa32bbb5e467f82aa4e0f8b5dc4351fda9b7080dd02ca86bff8e85f699ba7f`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-d1a5a71b5661e04e7d31626744ebf7759a39ebe784a7438c4ab1d74c359bc26e`,
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_BASE.CORNER_UNITS]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.BLIND_CORNER,
        label: "Blind Corner",
        images: {
          // Cabinet1016_Left,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-2ff7e8e10724f9a4dd32c6a941fa6951abbf963340db8ce3d4fd8d2986356f8b`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-1e8a3233e200324f66ab904612b48ddffac8a1025f481820272304e0ffcacc62`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-799a3048362c87c3db3fcfcc6c2f9603f69ad028f777b4af430ecf1127dd4469`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-2ff84c0bfea75ef3f8b43322c7f3e9ea3c571fc9e7bde7fdaa0d006a79a521b7`,
        },
      },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.LAZY_SUSAN_CORNER,
        label: "Corner",
        images: {
          // Cabinet1017,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-7857cce208d7dc2877f7868af64b539c0f12b2ea80d6274977772ce381d9a06c`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-5e736834dada45278d8be40d2142d002b8aa07f874ffd9e044964da7d5889ef1`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-1c89a19278e987a67617ef851c224a42140b0bd12dcc2a1d13f5e4168e1e2754`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-5aecca5444d575893af0e6bdcc499aa82094a406e9f6903b7255768b0cd2cfa9`,
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_BASE.SINK_UNITS]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.SINK_UNITS,
        label: "Sink Units",
        images: {
          // Cabinet1011,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-5248827fc3e3227ddc1ba01535e0393c7f9c3dfe7f8d58314fe79edcb752fea4`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-13bea9555e0315fef119070b4d06c7454fb6e9bc4edd71fdf8af36de0a161acc`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-f19a5c7eff1f7dd0c1f320016e5d1d7308c4c37bd05280ccbe4f6533c371eaff`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-1eaebc78b5c8f3f024fb6d9baf7787aaabdefe0aa1b661010773f967aec0e2e6`,
        },
      },
    ],
  },
  [PRODUCT_POSITIONS_KEYS.WALL_CABINET]: {
    [PRODUCT_SECTION_GROUP_WALL.DOORS_ONLY]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_WALL.ONE_DOOR,
        label: "1 Door",
        images: {
          // Cabinet1019,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-3341211b1b7586b1dce9c76862bcf53147c3315e53779007b91f20c77982dac2`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-59cc2b5c6667d5f14514f4b672fc9f79fd12e6b42128d68988f80946363542b3`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-a18c14d5277764053f5d93f433505a610adb7dcd4c8491ddc2019cbfe5f9c1f2`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-953582648b09ac7595bb6194d6065d9ca359e41d37e7370a438ddbf0fbe032e7`,
        },
      },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_WALL.TWO_DOOR,
        label: "2 Door",
        images: {
          // Cabinet1020,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-e39fb5f45e62b138349e365c15c423377245c58862dd68912d63cbe42fd5e369`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-e3620fe0c4cc0d355f3c0b366688b6d6e236c8221fb226d6ef6d48c6dbc80b8a`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-bf5baabcbe9fb48ca7725728d68383cfebe693e5fa029eef3b009610521c8f26`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-6dec4d0f138e85315c34284ce4e5f6aaeb9ece5888a1c046f95354535dbd70fb`,
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_WALL.CORNER_UNITS]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_WALL.CORNER_UNITS,
        label: "Corner Units",
        images: {
          // Cabinet1022,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-3c42eeaf20285edf27e7ef291e2e92f833a7f8d8cb8ed480778133e396d1e946`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-45454117287932a1b5b8e2a7cd9bffb1455a91728bcd4ea9442eeacb0b3c8284`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-4626e913189f01fdabcbf6aa63015e977b39c89bc13792535c8d9653560b8518`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-3419f6cd50a6dfbd44f3639a58315224d60a751b03ab5a8fd696284740431ab7`,
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_WALL.UPPER_PANTRY_UNIT]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_WALL.UPPER_PANTRY_ONE_DOOR,
        label: "Upper Pantry 1 Door",
        images: {
          // Cabinet1031,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-ab930fa4e9f341f375d005c6f2151f3429872fa354792b6ddf98fd963724e03d`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-6d6fb2637ccf5c02a6c3ab03da0a351ec710ca868cc68361320c31c1c323597b`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-db2574dabdd368d0bb3905b183a181c75cc3771d9671a135fcad77c166c3c2e6`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-6e3e18ccfc13abc17a6327306e69b4ad4d488828175bd338fc4aee70e75d4263`,
        },
      },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_WALL.UPPER_PANTRY_TWO_DOOR,
        label: "Upper Pantry 2 Door",
        images: {
          // Cabinet1032,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-d0958257272ee31f762c45e7e02e2e24b29611aad7f8a6fea815e5e321454601`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-0d7b9863dc7efb83e98c4564995ca743920249cecda50b22276ffe9b99ea255a`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-679a89756b83c19eb5d1d7efc24df4a83ffe339ed9e603cc280b654f1a53d4c9`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-cfc462013886b0fcbfeada97b295d514a8252a24e51f412e32116251d3406a6f`,
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
        label: "Above the Fridge/Range",
        images: {
          // Cabinet1026,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-42a4a81491e11a4f50706e1dde891761c85f0720970343d1f148857122fa1504`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-abc0a298e589e21f30aaa0d2c13a8a56d3cb52794b8d1df12a3dd962e8ace0af`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-107d2f5bcb118e028ed2d3d274535ffb91099cd48582f46b9a969e32f755e19d`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-049c6b842b4858baccb4352ba24a00c40a992c8628aaa33899c61b54188bb654`,
        },
      },
    ],
  },
  [PRODUCT_POSITIONS_KEYS.ISLAND_CABINET]: {
    [PRODUCT_SECTION_GROUP_BASE.DOORS_ONLY]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.ONE_DOOR,
        label: "1 Door",
        images: {
          // Cabinet1001,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-713dc8d3c6f89de2d431ce6d3036fed302eebb8a8412db62c3d2499532b1bac0`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-acd2c2a1796cd1abecdf0412046f9cf4acaf232cf06fe7cbcf277a831afaac4c`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-22644f1be30f14e18d5d057d46115666067f2c65b330bab6c94c06b107037621`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-ee66bd889fb93f004538ab8a3e2681eff3740ebe912c3637887db6be08471902`,
        },
      },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.TWO_DOOR,
        label: "2 Door",
        images: {
          // Cabinet1008,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-f977393f07606b5a95c3aea12586b98b081a829bcf0d474b9e92afd0fa5d9e5a`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-977981699e13b68ab10ab365777e38fa19b741d18277f47436e74ed4f2fc93dc`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-a6695c33991b01307498b699d5f18f7abcbba55ab9b459c5c73dbe543c43aa65`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-b37b17d55397bb502790dae2853cda00b60888e97007a0570b6ac273a22079b0`,
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_BASE.DRAWERS_ONLY]: [
      // {
      //   id: Math.floor(100000 + Math.random() * 900000),
      //   value: PRODUCT_GROUP_BASE.ONE_DRIWER,
      //   label: "1 Drawer",
      //   img: Cabinet03,
      // },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.TWO_DRIWER,
        label: "2 Drawer",
        images: {
          // Cabinet1004,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-58b7985f0966845e20f10a810b38b512e3a0513c07909946d506f81ecc720120`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-d913ebba6b93c805ebcf521a1e69b834a131e54556128f48ccc21a0e827a35f0`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-2b2b0b5d6bd0593dd793d49048741bc8f8175de3e0986660276fd8c1ed9f502c`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-000fa5287899fef28aa7e8295b7bd87ad54b25bb0fab5d78a431c968efb27341`,
        },
      },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.THREE_DRIWER,
        label: "3 Drawer",
        images: {
          // Cabinet1004,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-d629eaf284cdba57e5af6f826455d74bd8fe4528b57d3e97e50f546efcabc0b0`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-d913ebba6b93c805ebcf521a1e69b834a131e54556128f48ccc21a0e827a35f0`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-2b2b0b5d6bd0593dd793d49048741bc8f8175de3e0986660276fd8c1ed9f502c`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-000fa5287899fef28aa7e8295b7bd87ad54b25bb0fab5d78a431c968efb27341`,
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_BASE.DRAWER_AND_DOOR]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.ONE_DRIWER_ONE_DOOR,
        label: "1 Drawer, 1 Door",
        images: {
          // Cabinet1003,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-f612081241181082cac0e203f98300a06492e947cf097546ba9c374fdfa3a86a`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-60365842fedb964916221ef7bf5ca588380488a08a7d1c4fd1f67e66d657c772`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-f0f2132bbfab956298a9fa010a25588ac0eea4f69b045838c7f5dec7453aae87`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-d493740f83d419c4edcc99cbfde7962b6b1c89ff8a4acd9ac094169dac603c9d`,
        },
      },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.ONE_DRIWER_TWO_DOOR,
        label: "1 Drawer, 2 Doors",
        images: {
          // Cabinet1006,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-2860448561abfc6974a0e010da76437484d09cb4e44e33b7a536f283ed234bf3`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-c45b265d809ec50bfa3a381b295f82a530d1c5dbb88ff90b17398afa835a7adb`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-f7aa32bbb5e467f82aa4e0f8b5dc4351fda9b7080dd02ca86bff8e85f699ba7f`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-d1a5a71b5661e04e7d31626744ebf7759a39ebe784a7438c4ab1d74c359bc26e`,
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_BASE.CORNER_UNITS]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.BLIND_CORNER,
        label: "Blind Corner",
        images: {
          // Cabinet1016_Left,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-2ff7e8e10724f9a4dd32c6a941fa6951abbf963340db8ce3d4fd8d2986356f8b`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-1e8a3233e200324f66ab904612b48ddffac8a1025f481820272304e0ffcacc62`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-799a3048362c87c3db3fcfcc6c2f9603f69ad028f777b4af430ecf1127dd4469`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-2ff84c0bfea75ef3f8b43322c7f3e9ea3c571fc9e7bde7fdaa0d006a79a521b7`,
        },
      },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.LAZY_SUSAN_CORNER,
        label: "Corner",
        images: {
          // Cabinet1017,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-7857cce208d7dc2877f7868af64b539c0f12b2ea80d6274977772ce381d9a06c`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-5e736834dada45278d8be40d2142d002b8aa07f874ffd9e044964da7d5889ef1`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-1c89a19278e987a67617ef851c224a42140b0bd12dcc2a1d13f5e4168e1e2754`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-5aecca5444d575893af0e6bdcc499aa82094a406e9f6903b7255768b0cd2cfa9`,
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_BASE.SINK_UNITS]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.SINK_UNITS,
        label: "Sink Units",
        images: {
          // Cabinet1011,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-5248827fc3e3227ddc1ba01535e0393c7f9c3dfe7f8d58314fe79edcb752fea4`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-13bea9555e0315fef119070b4d06c7454fb6e9bc4edd71fdf8af36de0a161acc`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-f19a5c7eff1f7dd0c1f320016e5d1d7308c4c37bd05280ccbe4f6533c371eaff`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-1eaebc78b5c8f3f024fb6d9baf7787aaabdefe0aa1b661010773f967aec0e2e6`,
        },
      },
    ],
  },
  [PRODUCT_POSITIONS_KEYS.APPLIANCES]: {
    // [PRODUCT_SECTION_GROUP_APPLIANCES.SINK]: [
    //   {
    //     // id: 4011,
    //     id: "sink 25",
    //     value: PRODUCT_GROUP_APPLIANCES.SINK,
    //     label: "25\"",
    //     img: Sink,
    // images: {
    //   // Sink,
    //   framedWhite: `${IMAGES_URL}/api/files/hash/sha256-2e55d49a95ab54b0c927837118b2f65edc07bb2a29ef381b522593a714af2487",
    //   framedGrey: `${IMAGES_URL}/api/files/hash/sha256-2e55d49a95ab54b0c927837118b2f65edc07bb2a29ef381b522593a714af2487",
    //   framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-2e55d49a95ab54b0c927837118b2f65edc07bb2a29ef381b522593a714af2487",
    //   framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-2e55d49a95ab54b0c927837118b2f65edc07bb2a29ef381b522593a714af2487",
    // },
    //   },
    //   {
    //     // id: 4012,
    //     id: "sink 33",
    //     value: PRODUCT_GROUP_APPLIANCES.SINK,
    //     label: "33\"",
    // images: {
    //   // Sink,
    //   framedWhite: `${IMAGES_URL}/api/files/hash/sha256-3fb17d467c708b939dcaf141b73ef54dc613c6751282fd826a3f04f11cc54317",
    //   framedGrey: `${IMAGES_URL}/api/files/hash/sha256-3fb17d467c708b939dcaf141b73ef54dc613c6751282fd826a3f04f11cc54317",
    //   framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-3fb17d467c708b939dcaf141b73ef54dc613c6751282fd826a3f04f11cc54317",
    //   framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-3fb17d467c708b939dcaf141b73ef54dc613c6751282fd826a3f04f11cc54317",
    // },
    //   },
    // ],
    [PRODUCT_SECTION_GROUP_APPLIANCES.REFRIGERATOR]: [
      {
        id: String(4021),
        value: PRODUCT_GROUP_APPLIANCES.REFRIGERATOR,
        label: '30"',
        images: {
          // Refrigerator,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-7957c47b1d3c506794ba373f8b2184fc9c8facb9608602bb6c82ab3b062f4f82`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-7957c47b1d3c506794ba373f8b2184fc9c8facb9608602bb6c82ab3b062f4f82`,
          //   framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-7957c47b1d3c506794ba373f8b2184fc9c8facb9608602bb6c82ab3b062f4f82`,
          //   framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-7957c47b1d3c506794ba373f8b2184fc9c8facb9608602bb6c82ab3b062f4f82`,
        },
      },
      {
        id: String(4022),
        value: PRODUCT_GROUP_APPLIANCES.REFRIGERATOR,
        label: '36"',
        images: {
          // Refrigerator,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-4ed8584d9b0fcd6c08611e7760e11e6299a0baf822dbbe3476155a1087815d96`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-4ed8584d9b0fcd6c08611e7760e11e6299a0baf822dbbe3476155a1087815d96`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-4ed8584d9b0fcd6c08611e7760e11e6299a0baf822dbbe3476155a1087815d96`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-4ed8584d9b0fcd6c08611e7760e11e6299a0baf822dbbe3476155a1087815d96`,
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_APPLIANCES.DISHWASHER]: [
      {
        id: String(4031),
        value: PRODUCT_GROUP_APPLIANCES.DISHWASHER,
        label: '24"',
        images: {
          // Dishwasher,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-145eb067882b1f8450c470f714ebb30b47ca062dbdcd452906f26bcd9a1fb91a`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-145eb067882b1f8450c470f714ebb30b47ca062dbdcd452906f26bcd9a1fb91a`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-145eb067882b1f8450c470f714ebb30b47ca062dbdcd452906f26bcd9a1fb91a`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-145eb067882b1f8450c470f714ebb30b47ca062dbdcd452906f26bcd9a1fb91a`,
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_APPLIANCES.RANGE]: [
      {
        id: String(4041),
        value: PRODUCT_GROUP_APPLIANCES.RANGE,
        label: '30"',
        images: {
          // Oven,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-64e58cb579cbeb97524f553c94162ddd89921e527183b6f2ec04f5f79604befd`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-64e58cb579cbeb97524f553c94162ddd89921e527183b6f2ec04f5f79604befd`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-64e58cb579cbeb97524f553c94162ddd89921e527183b6f2ec04f5f79604befd`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-64e58cb579cbeb97524f553c94162ddd89921e527183b6f2ec04f5f79604befd`,
        },
      },
      {
        id: String(4042),
        value: PRODUCT_GROUP_APPLIANCES.RANGE,
        label: '36"',
        images: {
          // Oven,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-92e9cf8111b7f6a671da7c1bb7422989f06c410cb6f63c0219f09f760e577347`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-92e9cf8111b7f6a671da7c1bb7422989f06c410cb6f63c0219f09f760e577347`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-92e9cf8111b7f6a671da7c1bb7422989f06c410cb6f63c0219f09f760e577347`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-92e9cf8111b7f6a671da7c1bb7422989f06c410cb6f63c0219f09f760e577347`,
        },
      },
      {
        id: String(4043),
        value: PRODUCT_GROUP_APPLIANCES.RANGE,
        label: '48"',
        images: {
          // Oven,
          framedWhite: `${IMAGES_URL}/api/files/hash/sha256-3f1336b3f6ef7a63d79f3fee64bcb7d2571b62fb9ddae9d64441804a2d0ca6c8`,
          framedGrey: `${IMAGES_URL}/api/files/hash/sha256-3f1336b3f6ef7a63d79f3fee64bcb7d2571b62fb9ddae9d64441804a2d0ca6c8`,
          // framelessWhite: `${IMAGES_URL}/api/files/hash/sha256-3f1336b3f6ef7a63d79f3fee64bcb7d2571b62fb9ddae9d64441804a2d0ca6c8`,
          // framelessGrey: `${IMAGES_URL}/api/files/hash/sha256-3f1336b3f6ef7a63d79f3fee64bcb7d2571b62fb9ddae9d64441804a2d0ca6c8`,
        },
      },
    ],
  },
};

export const ProductsBaseCabinet: ProductArrT = [
  // NEW Cabinets Base START
  // ONE_DOOR
  {
    id: "LWSCLICK-01",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.ONE_DOOR,
    value: '9" Single Door Base Cabinet',
    label: '9" Single Door Base Cabinet',
    img: NotImage,
    size: {
      width: 9,
      depth: 24,
      height: 34.5,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "LWSCLICK-02",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.ONE_DOOR,
    value: '12" Single Door Base Cabinet',
    label: '12" Single Door Base Cabinet',
    img: NotImage,
    size: {
      width: 12,
      depth: 24,
      height: 34.5,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "LWSCLICK-03",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.ONE_DOOR,
    value: '15" Single Door Base Cabinet',
    label: '15" Single Door Base Cabinet',
    img: NotImage,
    size: {
      width: 15,
      depth: 24,
      height: 34.5,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "LWSCLICK-04",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.ONE_DOOR,
    value: '18" Single Door Base Cabinet',
    label: '18" Single Door Base Cabinet',
    img: NotImage,
    size: {
      width: 18,
      depth: 24,
      height: 34.5,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "LWSCLICK-05",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.ONE_DOOR,
    value: '21" Single Door Base Cabinet',
    label: '21" Single Door Base Cabinet',
    img: NotImage,
    size: {
      width: 21,
      depth: 24,
      height: 34.5,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },

  // TWO_DOOR
  {
    id: "LWSCLICK-06",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.TWO_DOOR,
    value: '24" Two Door Base Cabinet',
    label: '24" Two Door Base Cabinet',
    img: NotImage,
    size: {
      width: 24,
      depth: 24,
      height: 34.5,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "LWSCLICK-07",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.TWO_DOOR,
    value: '30" Two Door Base Cabinet',
    label: '30" Two Door Base Cabinet',
    img: NotImage,
    size: {
      width: 30,
      depth: 24,
      height: 34.5,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "LWSCLICK-08",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.TWO_DOOR,
    value: '36" Two Door Base Cabinet',
    label: '36" Two Door Base Cabinet',
    img: NotImage,
    size: {
      width: 36,
      depth: 24,
      height: 34.5,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },

  // TWO_DRIWER
  {
    id: "LWSCLICK-13",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.TWO_DRIWER,
    value: '30" Two Drawer Base Cabinet',
    label: '30" Two Drawer Base Cabinet',
    img: NotImage,
    size: {
      width: 30,
      depth: 24,
      height: 34.5,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "LWSCLICK-14",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.TWO_DRIWER,
    value: '36" Two Drawer Base Cabinet',
    label: '36" Two Drawer Base Cabinet',
    img: NotImage,
    size: {
      width: 36,
      depth: 24,
      height: 34.5,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },

  // THREE_DRIWER
  {
    id: "LWSCLICK-15",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.THREE_DRIWER,
    value: '12" Three Drawer Base Cabinet',
    label: '12" Three Drawer Base Cabinet',
    img: NotImage,
    size: {
      width: 12,
      depth: 24,
      height: 34.5,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "LWSCLICK-16",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.THREE_DRIWER,
    value: '18" Three Drawer Base Cabinet',
    label: '18" Three Drawer Base Cabinet',
    img: Cabinet1004,
    size: {
      width: 18,
      depth: 24,
      height: 30,
    },
    shelfs: 0,
    adjustableFeet: 4,
  },
  {
    id: "LWSCLICK-17",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.THREE_DRIWER,
    value: '24" Three Drawer Base Cabinet',
    label: '24" Three Drawer Base Cabinet',
    img: Cabinet1007,
    size: {
      width: 24,
      depth: 24,
      height: 30,
    },
    shelfs: 0,
    adjustableFeet: 4,
  },
  {
    id: "LWSCLICK-18",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.THREE_DRIWER,
    value: '30" Three Drawer Base Cabinet',
    label: '30" Three Drawer Base Cabinet',
    img: Cabinet1010,
    size: {
      width: 30,
      depth: 24,
      height: 30,
    },
    shelfs: 0,
    adjustableFeet: 4,
  },
  {
    id: "LWSCLICK-19",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.THREE_DRIWER,
    value: '36" Three Drawer Base Cabinet',
    label: '36" Three Drawer Base Cabinet',
    img: Cabinet1014,
    size: {
      width: 36,
      depth: 24,
      height: 30,
    },
    shelfs: 0,
    adjustableFeet: 4,
  },

  // ONE_DRIWER_ONE_DOOR
  { 
    id: "LWSCLICK-09",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.ONE_DRIWER_ONE_DOOR,
    value: '18" Single Door, One Drawer Base Cabinet',
    label: '18" Single Door, One Drawer Base Cabinet',
    img: NotImage,
    size: {
      width: 18,
      depth: 24,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },

  // ONE_DRIWER_TWO_DOOR
  {
    id: "LWSCLICK-10",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.ONE_DRIWER_TWO_DOOR,
    value: '24" Two Door, One Drawer Base Cabinet',
    label: '24" Two Door, One Drawer Base Cabinet',
    img: NotImage,
    size: {
      width: 24,
      depth: 24,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "LWSCLICK-11",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.ONE_DRIWER_TWO_DOOR,
    value: '30" Two Door, One Drawer Base Cabinet',
    label: '30" Two Door, One Drawer Base Cabinet',
    img: NotImage,
    size: {
      width: 30,
      depth: 24,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "LWSCLICK-12",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.ONE_DRIWER_TWO_DOOR,
    value: '36" Two Door, One Drawer Base Cabinet',
    label: '36" Two Door, One Drawer Base Cabinet',
    img: Cabinet1013,
    size: {
      width: 36,
      depth: 24,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },

  // SINK_UNITS
  {
    id: "LWSCLICK-20",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.SINK_UNITS,
    value: '30" Two Door Sink Base Cabinet',
    label: '30" Two Door Sink Base Cabinet',
    img: Cabinet1011,
    size: {
      width: 30,
      depth: 24,
      height: 30,
    },
    shelfs: 0,
    adjustableFeet: 4,
    sink: true,
  },
  {
    id: "LWSCLICK-21",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.SINK_UNITS,
    value: '36" Two Door Sink Base Cabinet',
    label: '36" Two Door Sink Base Cabinet',
    img: Cabinet1015,
    size: {
      width: 36,
      depth: 24,
      height: 30,
    },
    shelfs: 0,
    adjustableFeet: 4,
    sink: true,
  },

  // LAZY_SUSAN_CORNER
  {
    id: "LWSCLICK-22_1",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.LAZY_SUSAN_CORNER,
    value: 'LWSCLICK-22_36in_Corner_Base_Cabinet__Lazy_Susan__Rev_D_Left',
    label: '36" Corner Base Cabinet Left',
    img: Cabinet1017,
    size: {
      width: 36,
      depth: 36,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "LWSCLICK-22_2",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.LAZY_SUSAN_CORNER,
    value: 'LWSCLICK-22_36in_Corner_Base_Cabinet__Lazy_Susan__Rev_D_Right',
    label: '36" Corner Base Cabinet Right',
    img: Cabinet1017,
    size: {
      width: 36,
      depth: 36,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },

  // BLIND_CORNER
  {
    id: "LWSCLICK-23_1",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.BLIND_CORNER,
    value: '36" Blind Corner Base Cabinet Left',
    label: '36" Blind Corner Base Cabinet Left',
    img: Cabinet1016_Left,
    size: {
      width: 36,
      depth: 24,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "LWSCLICK-23_2",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.BLIND_CORNER,
    value: '36" Blind Corner Base Cabinet Right',
    label: '36" Blind Corner Base Cabinet Right',
    img: Cabinet1016_Right,
    size: {
      width: 36,
      depth: 24,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  // NEW Cabinets Base END
];

export const ProductsWallCabinet: ProductArrT = [
  // NEW Cabinets Base START
  // 30in Height
  {
    id: "LWSCLICK-24",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ONE_DOOR,
    value: '9" Single Door Wall Cabinet (30"H)',
    label: '9" Single Door Wall Cabinet (30"H)',
    img: NotImage,
    size: {
      width: 9,
      depth: 12,
      height: 30,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-25",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ONE_DOOR,
    value: '12" Single Door Wall Cabinet (30"H)',
    label: '12" Single Door Wall Cabinet (30"H)',
    img: NotImage,
    size: {
      width: 12,
      depth: 12,
      height: 30,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-26",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ONE_DOOR,
    value: '15" Single Door Wall Cabinet (30"H)',
    label: '15" Single Door Wall Cabinet (30"H)',
    img: NotImage,
    size: {
      width: 15,
      depth: 12,
      height: 30,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-27",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ONE_DOOR,
    value: '18" Single Door Wall Cabinet (30"H)',
    label: '18" Single Door Wall Cabinet (30"H)',
    img: NotImage,
    size: {
      width: 18,
      depth: 12,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 0,
  },
  {
    id: "LWSCLICK-28",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ONE_DOOR,
    value: '21" Single Door Wall Cabinet (30"H)',
    label: '21" Single Door Wall Cabinet (30"H)',
    img: NotImage,
    size: {
      width: 21,
      depth: 12,
      height: 30,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-29",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.TWO_DOOR,
    value: '24" Two Door Wall Cabinet (30"H)',
    label: '24" Two Door Wall Cabinet (30"H)',
    img: NotImage,
    size: {
      width: 24,
      depth: 12,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 0,
  },
  {
    id: "LWSCLICK-30",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.CORNER_UNITS,
    value: '24" Single Door Corner Wall Cabinet (30"H)',
    label: '24" Single Door Corner Wall Cabinet (30"H)',
    img: NotImage,
    size: {
      width: 24,
      depth: 24,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 0,
  },
  {
    id: "LWSCLICK-31",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.TWO_DOOR,
    value: '30" Two Door Wall Cabinet (30"H)',
    label: '30" Two Door Wall Cabinet (30"H)',
    img: NotImage,
    size: {
      width: 30,
      depth: 12,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 0,
  },
  {
    id: "LWSCLICK-32",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.TWO_DOOR,
    value: '36" Two Door Wall Cabinet (30"H)',
    label: '36" Two Door Wall Cabinet (30"H)',
    img: NotImage,
    size: {
      width: 36,
      depth: 12,
      height: 30,
    },
    shelfs: 2,
  },

  // 36in Height
  {
    id: "LWSCLICK-33",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ONE_DOOR,
    value: '9" Single Door Wall Cabinet (36"H)',
    label: '9" Single Door Wall Cabinet (36"H)',
    img: NotImage,
    size: {
      width: 9,
      depth: 12,
      height: 36,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-34",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ONE_DOOR,
    value: '12" Single Door Wall Cabinet (36"H)',
    label: '12" Single Door Wall Cabinet (36"H)',
    img: NotImage,
    size: {
      width: 12,
      depth: 12,
      height: 36,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-35",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ONE_DOOR,
    value: '15" Single Door Wall Cabinet (36"H)',
    label: '15" Single Door Wall Cabinet (36"H)',
    img: NotImage,
    size: {
      width: 15,
      depth: 12,
      height: 36,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-36",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ONE_DOOR,
    value: '18" Single Door Wall Cabinet (36"H)',
    label: '18" Single Door Wall Cabinet (36"H)',
    img: NotImage,
    size: {
      width: 18,
      depth: 12,
      height: 36,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-37",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ONE_DOOR,
    value: '21" Single Door Wall Cabinet (36"H)',
    label: '21" Single Door Wall Cabinet (36"H)',
    img: NotImage,
    size: {
      width: 21,
      depth: 12,
      height: 36,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-38",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.TWO_DOOR,
    value: '24" Two Door Wall Cabinet (36"H)',
    label: '24" Two Door Wall Cabinet (36"H)',
    img: NotImage,
    size: {
      width: 24,
      depth: 12,
      height: 36,
    },
    shelfs: 2,
    adjustableFeet: 0,
  },
  {
    id: "LWSCLICK-39",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.CORNER_UNITS,
    value: '24" Single Door Corner Wall Cabinet (36" H)',
    label: '24" Single Door Corner Wall Cabinet (36" H)',
    img: NotImage,
    size: {
      width: 24,
      depth: 24,
      height: 36,
    },
    shelfs: 2,
    adjustableFeet: 0,
  },
  {
    id: "LWSCLICK-40",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.TWO_DOOR,
    value: '30" Two Door Wall Cabinet (36"H)',
    label: '30" Two Door Wall Cabinet (36"H)',
    img: Cabinet1025,
    size: {
      width: 30,
      depth: 12,
      height: 36,
    },
    shelfs: 2,
    adjustableFeet: 0,
  },
  {
    id: "LWSCLICK-41",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.TWO_DOOR,
    value: '36" Two Door Wall Cabinet (36"H)',
    label: '36" Two Door Wall Cabinet (36"H)',
    img: NotImage,
    size: {
      width: 36,
      depth: 12,
      height: 36,
    },
    shelfs: 1,
  },

  // 42in Height
  {
    id: "LWSCLICK-42",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ONE_DOOR,
    value: '9" Single Door Wall Cabinet (42"H)',
    label: '9" Single Door Wall Cabinet (42"H)',
    img: NotImage,
    size: {
      width: 9,
      depth: 12,
      height: 42,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-43",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ONE_DOOR,
    value: '12" Single Door Wall Cabinet (42"H)',
    label: '12" Single Door Wall Cabinet (42"H)',
    img: NotImage,
    size: {
      width: 12,
      depth: 12,
      height: 42,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-44",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ONE_DOOR,
    value: '15" Single Door Wall Cabinet (42"H)',
    label: '15" Single Door Wall Cabinet (42"H)',
    img: NotImage,
    size: {
      width: 15,
      depth: 12,
      height: 42,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-45",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ONE_DOOR,
    value: '18" Single Door Wall Cabinet (42"H)',
    label: '18" Single Door Wall Cabinet (42"H)',
    img: NotImage,
    size: {
      width: 18,
      depth: 12,
      height: 42,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-46",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ONE_DOOR,
    value: '21" Single Door Wall Cabinet (42"H)',
    label: '21" Single Door Wall Cabinet (42"H)',
    img: NotImage,
    size: {
      width: 21,
      depth: 12,
      height: 42,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-47",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.TWO_DOOR,
    value: '24" Two Door Wall Cabinet (42"H)',
    label: '24" Two Door Wall Cabinet (42"H)',
    img: NotImage,
    size: {
      width: 24,
      depth: 12,
      height: 42,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-48",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.CORNER_UNITS,
    value: '24" Single Door Corner Wall Cabinet (42"H)',
    label: '24" Single Door Corner Wall Cabinet (42"H)',
    img: NotImage,
    size: {
      width: 24,
      depth: 24,
      height: 42,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-49",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.TWO_DOOR,
    value: '30" Two Door Wall Cabinet (42"H)',
    label: '30" Two Door Wall Cabinet (42"H)',
    img: NotImage,
    size: {
      width: 30,
      depth: 12,
      height: 42,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-50",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.TWO_DOOR,
    value: '36" Two Door Wall Cabinet (42"H)',
    label: '36" Two Door Wall Cabinet (42"H)',
    img: NotImage,
    size: {
      width: 36,
      depth: 12,
      height: 42,
    },
    shelfs: 1,
  },

  // Bridge Cabinet
  {
    id: "LWSCLICK-51",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
    value: '30" Two Door Bridge Cabinet (12"H x 12"D)',
    label: '30" Two Door Bridge Cabinet (12"H x 12"D)',
    img: NotImage,
    size: {
      width: 30,
      depth: 12,
      height: 12,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-52",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
    value: '36" Two Door Bridge Cabinet (12"H x 12"D)',
    label: '36" Two Door Bridge Cabinet (12"H x 12"D)',
    img: NotImage,
    size: {
      width: 36,
      depth: 12,
      height: 12,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-53",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
    value: '30" Two Door Bridge Cabinet (15"H x 12"D)',
    label: '30" Two Door Bridge Cabinet (15"H x 12"D)',
    img: NotImage,
    size: {
      width: 30,
      depth: 12,
      height: 15,
    },
    shelfs: 0,
  },
  {
    id: "LWSCLICK-54",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
    value: '36" Two Door Bridge Cabinet (15"H x 12"D)',
    label: '36" Two Door Bridge Cabinet (15"H x 12"D)',
    img: NotImage,
    size: {
      width: 36,
      depth: 12,
      height: 15,
    },
    shelfs: 0,
  },
  {
    id: "LWSCLICK-55",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
    value: '30" Two Door Bridge Cabinet (18"H x 12"D)',
    label: '30" Two Door Bridge Cabinet (18"H x 12"D)',
    img: NotImage,
    size: {
      width: 30,
      depth: 12,
      height: 18,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-56",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
    value: '36" Two Door Bridge Cabinet (18"H x 12"D)',
    label: '36" Two Door Bridge Cabinet (18"H x 12"D)',
    img: NotImage,
    size: {
      width: 36,
      depth: 12,
      height: 18,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-57",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
    value: '30" Two Door Bridge Cabinet (24"H x 12"D)',
    label: '30" Two Door Bridge Cabinet (24"H x 12"D)',
    img: NotImage,
    size: {
      width: 30,
      depth: 12,
      height: 24,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-58",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
    value: '36" Two Door Bridge Cabinet (24"H x 12"D)',
    label: '36" Two Door Bridge Cabinet (24"H x 12"D)',
    img: NotImage,
    size: {
      width: 36,
      depth: 12,
      height: 24,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-59",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
    value: '30" Two Door Bridge Cabinet (18"H x 24"D)',
    label: '30" Two Door Bridge Cabinet (18"H x 24"D)',
    img: NotImage,
    size: {
      width: 30,
      depth: 24,
      height: 18,
    },
    shelfs: 0,
  },
  {
    id: "LWSCLICK-60",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
    value: '36" Two Door Bridge Cabinet (12"H x 24"D)',
    label: '36" Two Door Bridge Cabinet (12"H x 24"D)',
    img: NotImage,
    size: {
      width: 36,
      depth: 24,
      height: 12,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-61",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
    value: '36" Two Door Bridge Cabinet (18"H x 24"D)',
    label: '36" Two Door Bridge Cabinet (18"H x 24"D)',
    img: NotImage,
    size: {
      width: 36,
      depth: 24,
      height: 18,
    },
    shelfs: 0,
  },
  {
    id: "LWSCLICK-62",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
    value: '30" Two Door Bridge Cabinet (24"H x 24"D)',
    label: '30" Two Door Bridge Cabinet (24"H x 24"D)',
    img: NotImage,
    size: {
      width: 30,
      depth: 24,
      height: 24,
    },
    shelfs: 1,
  },

  // Upper Pantry Cabinet
  {
    id: "LWSCLICK-63",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.UPPER_PANTRY_ONE_DOOR,
    value: '18" Single Door Upper Pantry Cabinet (49.5"H)',
    label: '18" Single Door Upper Pantry Cabinet (49.5"H)',
    description: "To be paired with 18” Base Cabinet",
    img: NotImage,
    size: {
      width: 18,
      depth: 24,
      height: 49.5,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-64",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.UPPER_PANTRY_ONE_DOOR,
    value: '18" Single Door Upper Pantry Cabinet (55.5"H)',
    label: '18" Single Door Upper Pantry Cabinet (55.5"H)',
    description: "To be paired with 18” Base Cabinet",
    img: NotImage,
    size: {
      width: 18,
      depth: 24,
      height: 55.5,
    },
    shelfs: 3,
  },
  {
    id: "LWSCLICK-65",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.UPPER_PANTRY_TWO_DOOR,
    value: '24" Two Door Upper Pantry Cabinet (49.5"H)',
    label: '24" Two Door Upper Pantry Cabinet (49.5"H)',
    description: "To be paired with 24” Base Cabinet",
    img: NotImage,
    size: {
      width: 24,
      depth: 24,
      height: 49.5,
    },
    shelfs: 1,
  },
  {
    id: "LWSCLICK-66",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.UPPER_PANTRY_TWO_DOOR,
    value: '24" Two Door Upper Pantry Cabinet (55.5"H)',
    label: '24" Two Door Upper Pantry Cabinet (55.5"H)',
    description: "To be paired with 24” Base Cabinet",
    img: NotImage,
    size: {
      width: 24,
      depth: 24,
      height: 55.5,
    },
    shelfs: 3,
  },
  // NEW Cabinets Base END










  // {
  //   id: "1027",
  //   position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
  //   group: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
  //   value: '30" Two Door Above the Fridge Wall Cabinet (24"D)',
  //   label: '30" Two Door Above the Fridge Wall Cabinet (24"D)',
  //   img: Cabinet1027,
  //   size: {
  //     width: 30,
  //     depth: 24,
  //     height: 15,
  //   },
  //   shelfs: 0,
  // },



  // {
  //   id: "1030",
  //   position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
  //   group: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
  //   value: '36" Two Door Above the Fridge Wall Cabinet (24"D)',
  //   label: '36" Two Door Above the Fridge Wall Cabinet (24"D)',
  //   img: Cabinet1030,
  //   size: {
  //     width: 36,
  //     depth: 24,
  //     height: 15,
  //   },
  //   shelfs: 0,
  // },



];

export interface ProductOtherI {
  id: string;
  position: string;
  group: string;
  value: string;
  label: string;
  img: string;
  size: CabinetSizeI;
}
export type ProductOtherT = ProductOtherI[];
export const ProductsOther: ProductOtherT = [
  {
    id: "1101",
    position: "Island Back Panel",
    group: "",
    value: '12" Island Back / Deco Side Panel',
    label: '12" Island Back / Deco Side Panel',
    img: Cabinet01,
    size: {
      width: 12,
      depth: 0.75,
      height: 30,
    },
  },
  {
    id: "1102",
    position: "Island Back Panel",
    group: "",
    value: '18" Island Back / Deco Side Panel',
    label: '18" Island Back / Deco Side Panel',
    img: Cabinet01,
    size: {
      width: 18,
      depth: 0.75,
      height: 30,
    },
  },
  {
    id: "1105",
    position: "Pantry / Fridge Cabinet End Panel",
    group: "",
    value: '24"D x 43"H Pantry/Fridge Side Panels (2pk)',
    label: '24"D x 43"H Pantry/Fridge Side Panels (2pk)',
    img: Cabinet01,
    size: {
      width: 24,
      depth: 0.75,
      height: 42.75,
    },
  },
  {
    id: "LWSCLICK-67",
    position: "Fridge End Panel",
    group: "",
    value: "Fridge Side Panel 84H",
    label: "Fridge Side Panel 84H",
    img: Cabinet01,
    size: {
      width: 25,
      depth: 0.8,
      height: 84,
    },
  },
  {
    id: "LWSCLICK-68",
    position: "Fridge End Panel",
    group: "",
    value: "Fridge Side Panel 90H",
    label: "Fridge Side Panel 90H",
    img: Cabinet01,
    size: {
      width: 25,
      depth: 0.8,
      height: 90,
    },
  },
  {
    id: "LWSCLICK-69",
    position: "Base Cabinet and Island End Panel",
    group: "",
    value: 'Base Cabinet/Dishwasher/Island End Panel',
    label: 'Base Cabinet/Dishwasher/Island End Panel',
    img: Cabinet01,
    size: {
      width: 24,
      depth: 0.8,
      height: 34.5,
    },
  },
  {
    id: "1107",
    position: "Base Cabinet and Island End Panel",
    group: "",
    value:
      '24"D x 34"H Base Cabinet / Dishwasher / Island End Panel (Flat Panel)',
    label:
      '24"D x 34"H Base Cabinet / Dishwasher / Island End Panel (Flat Panel)',
    img: Cabinet01,
    size: {
      width: 0.75,
      depth: 25,
      height: 35.5,
    },
  },
  {
    id: "LWSCLICK-70",
    position: "Panels & Access",
    group: "",
    value: '3" Crown Moulding',
    label: '3" Crown Moulding',
    img: Cabinet01,
    size: {
      width: 3,
      depth: 1.5,
      height: 96,
    },
  },
  {
    id: "1112",
    position: "Panels & Access",
    group: "",
    value: '6" Crown Moulding',
    label: '6" Crown Moulding',
    img: Cabinet01,
    size: {
      width: 6,
      depth: 1.5,
      height: 96,
    },
  },
  {
    id: "LWSCLICK-71",
    position: "Panels & Access",
    group: "",
    value: '3/4" Under Cabinet Moulding',
    label: '3/4" Under Cabinet Moulding',
    img: Cabinet01,
    size: {
      width: 0.75,
      depth: 1.5,
      height: 96,
    },
  },
  {
    id: "LWSCLICK-73",
    position: "Panels & Access",
    group: "",
    value: '3" Filler Strip',
    label: '3" Filler Strip',
    img: Cabinet01,
    size: {
      width: 3,
      depth: 0.75,
      height: 48,
    },
  },
  {
    id: "LWSCLICK-75",
    position: "Panels & Access",
    group: "",
    value: '4.5" Toe Kick',
    label: '4.5" Toe Kick',
    img: Cabinet01,
    size: {
      width: 4.5,
      depth: 0.625,
      height: 96,
    },
  },
];

export const ProductsAppliances: ProductArrT = [
  // {
  //   id: "4011",
  //   position: PRODUCT_POSITIONS_KEYS.APPLIANCES,
  //   group: PRODUCT_GROUP_APPLIANCES.SINK,
  //   value: '25" Stainless Single Bowl Sink',
  //   label: '25" Stainless Single Bowl Sink',
  //   img: Sink,
  //   size: {
  //     width: 25,
  //     depth: 22,
  //     height: 0,
  //   },
  // },
  // {
  //   id: "4012",
  //   position: PRODUCT_POSITIONS_KEYS.APPLIANCES,
  //   group: PRODUCT_GROUP_APPLIANCES.SINK,
  //   value: '33" Stainless Single Bowl Sink',
  //   label: '33" Stainless Single Bowl Sink',
  //   img: Sink,
  //   size: {
  //     width: 33,
  //     depth: 22,
  //     height: 0,
  //   },
  // },

  {
    id: "4021",
    position: PRODUCT_POSITIONS_KEYS.APPLIANCES,
    group: PRODUCT_GROUP_APPLIANCES.REFRIGERATOR,
    value: '30" Fridge',
    label: '30" Fridge',
    img: Refrigerator,
    size: {
      width: 30,
      depth: 31,
      height: 70,
    },
  },
  {
    id: "4022",
    position: PRODUCT_POSITIONS_KEYS.APPLIANCES,
    group: PRODUCT_GROUP_APPLIANCES.REFRIGERATOR,
    value: '36" Fridge',
    label: '36" Fridge',
    img: Refrigerator,
    size: {
      width: 36,
      depth: 35,
      height: 70,
    },
  },

  {
    id: "4031",
    position: PRODUCT_POSITIONS_KEYS.APPLIANCES,
    group: PRODUCT_GROUP_APPLIANCES.DISHWASHER,
    value: '24" Dichwasher',
    label: '24" Dichwasher',
    img: Dishwasher,
    size: {
      width: 24,
      depth: 24,
      height: 34,
    },
  },

  {
    id: "4041",
    position: PRODUCT_POSITIONS_KEYS.APPLIANCES,
    group: PRODUCT_GROUP_APPLIANCES.RANGE,
    value: '30" Range',
    label: '30" Range',
    img: Oven,
    size: {
      width: 30,
      depth: 24,
      height: 36,
    },
  },
  {
    id: "4042",
    position: PRODUCT_POSITIONS_KEYS.APPLIANCES,
    group: PRODUCT_GROUP_APPLIANCES.RANGE,
    value: '36" Range',
    label: '36" Range',
    img: Oven,
    size: {
      width: 36,
      depth: 24,
      height: 36,
    },
  },
  {
    id: "4043",
    position: PRODUCT_POSITIONS_KEYS.APPLIANCES,
    group: PRODUCT_GROUP_APPLIANCES.RANGE,
    value: '48" Range',
    label: '48" Range',
    img: Oven,
    size: {
      width: 48,
      depth: 24,
      height: 36,
    },
  },
];

export const ProductsList: ProductArrT = [
  ...ProductsBaseCabinet,
  ...ProductsWallCabinet,
  ...ProductsAppliances,
];
