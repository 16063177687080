import { ISceneResult } from "@threekit-tools/treble/dist/types";
import { ModelsName_NodesT, NODES_THREEKIT, PlaneCabinetsWallT, WallItemT } from "../../utils/constants/nodesNamesThreekit";
import { getVector3FromCoordinates } from "../../utils/three/general/getFunctionsTHREE";
import { getBoxWidthThreekit, getItemNodeFromNullModel, getTranslationThreekit, getWorldTransformEvalNode } from "../../utils/threekit/general/getFunctions";
import { ArrWallRangesT, createRangesEmptyAndFilled, getDistanceFromLineToPoint, isPointOnLine, RangeT, WallRangeT } from "./getIntervalsOnWallForCabinetsWall";
import { getModelsBaseNullOnWall } from "../cabinets/getNodesCabinets";
import { getAllPlanesNode, getNeighborPlanes, getStartEndCoordsPlane } from "../wallsAndFloor/getWallPlanesInfo";
import { getSizeModelBoxFromAssetCabinetBase } from "../cabinets/cabinetsBase/size";
import { getNumberNodeThreekitFromName } from "../general";
import { getСompletedModelsNullNames } from "../../functionsConfigurator/cabinets/getNodesCabinets";
import { filteringBaseFeatures, getArrNullNamesWallConnections } from "../features/general";
import { getWallRangesFeaturesFilled } from "../features/intervalsBaseFeatures";
import { checkIfBlindCornerCabinet, checkIfBlindCornerCabinetLeft, checkIfCornerCabinetFromNullName } from "../cabinets/cabinetsBase/checkCornersCabinetsBase";
import { getPointSpacing } from "../../utils/three/distance";
import * as THREE from "three";
import { moveCoordsByVector } from "../wallsAndFloor/buildWallFromData";
import { isEqualCoordsTolerance } from "../cabinets/addCornerModelBase";
import { getWallNameFromPlaneName } from "../wallsAndFloor/getGeneralWallInfo";
import { checkLeftInterval, checkRightInterval } from "./intervalsInfoForPositionedNewCabinetsBase";
import { calculatePerpendicularProjectionPointToLine } from "../../utils/three/perpendicularProjectionPointToLine";

// Враховує глубину напольної шафи, товщину дверки разом з ручками та товщину декоративної панелі
const OFFSET_DEPTH_CABINETS_BASE_IN_CORNER = 0.67;

/**
 * Створює массив всіх проміжків(заповнених та пустих) на стіні ДЛЯ ШКАФІВ НА ПІДЛОЗІ.
 *
 * @param {ArrWallRangesT} arrModelsNullNamesOnWall Массив імен для Models Null які розташовані біля стіни wallName.
 * @param {Number} planeName Name Threekit стіни.
 * @return {ArrWallRangesT} Массив всіх проміжків(заповнених та пустих) на стіні ДЛЯ ШКАФІВ НА ПІДЛОЗІ.
 */
export const getIntervalsInfoOnWallForCabinetsBase = (
  arrModelsNullNamesOnWall: ModelsName_NodesT[],
  planeName: PlaneCabinetsWallT,
): ArrWallRangesT => {

  // const [wallCoordsLeft, wallCoordsRight] = getStartEndPointWall(wallName);
  const [currentPlaneCoordsLeft, currentPlaneCoordsRight] =
    getStartEndCoordsPlane(planeName);
  const planeStartVector = getVector3FromCoordinates({ ...currentPlaneCoordsLeft, y: 0 });
  const planeEndVector = getVector3FromCoordinates({ ...currentPlaneCoordsRight, y: 0 });
  // const wallSize = getSizeModelRelativeTransform(wallName);
  const currentPlaneWidth = getBoxWidthThreekit({ name: planeName });
  // const wallWidth = wallSize["x"];
  // const wallDepth = wallSize["z"];

  const fullRangeWall: WallRangeT = {
    empty: true,
    range: [0, currentPlaneWidth],
  }

  // if (arrModelsNullNamesOnWall.length === 0)
  //   return [fullRangeWall]


  let wallRangesFilled: ArrWallRangesT = [];

  // додаємо інтервали для дверей та проемів в загальний список інтервалів на стіні
  const wallNum = getNumberNodeThreekitFromName(planeName);
  const arrNullNamesWallConnections = getArrNullNamesWallConnections(wallNum);
  const arrNullNamesBaseFeatures = filteringBaseFeatures(arrNullNamesWallConnections);
  if (arrNullNamesBaseFeatures.length > 0) {
    const wallRangesFeaturesFilled = getWallRangesFeaturesFilled(
      arrNullNamesBaseFeatures,
      [currentPlaneCoordsLeft, currentPlaneCoordsRight]
    );
    wallRangesFilled = [ ...wallRangesFeaturesFilled ];
  }

  arrModelsNullNamesOnWall.forEach((modelNullName: ModelsName_NodesT) => {
    const modelNullTranslation = getTranslationThreekit({
      name: modelNullName,
    });
    const modelNullTranslationVector3 = getVector3FromCoordinates({ ...modelNullTranslation, y: 0 })
    // const isModelNullOnPlane = isPointOnLine(
    //   { ...currentPlaneCoordsLeft, y: 0 },
    //   { ...currentPlaneCoordsRight, y: 0 },
    //   { ...modelNullTranslation, y: 0},
    //   0.005
    // );

    // if (isModelNullOnPlane) {
      const sizeModel = getSizeModelBoxFromAssetCabinetBase(modelNullName);
      const modelWidth = sizeModel["x"];
      const pointInPlane = calculatePerpendicularProjectionPointToLine(
        modelNullTranslationVector3,
        planeStartVector,
        planeEndVector
      );
      const distanceFromPlaneStartToModelNull = planeStartVector.distanceTo(pointInPlane);
      const rangeModel: RangeT = [
        distanceFromPlaneStartToModelNull - modelWidth / 2,
        distanceFromPlaneStartToModelNull + modelWidth / 2,
      ];
      wallRangesFilled.push({
        empty: false,
        range: rangeModel,
        name: modelNullName,
      });
    // }
    
  });

  // check Cabinets Base Corner for the next wall
  const completedModelsNullNamesCabinetsBase = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_BASE
  );
  const modelsBaseNullNotOnWall = completedModelsNullNamesCabinetsBase.filter((modelNullName) => !arrModelsNullNamesOnWall.includes(modelNullName));
  const cornerCabinetsBaseNotOnWall = modelsBaseNullNotOnWall.filter((modelNullName) => {
    // const modelItem = getItemNodeFromNullModel({ name: modelNullName });
    return checkIfCornerCabinetFromNullName(modelNullName);
  })
  cornerCabinetsBaseNotOnWall.forEach((modelNullName) => {
    const sizeCornerCabinet = getSizeModelBoxFromAssetCabinetBase(modelNullName);
    const posCornerCabinet = getTranslationThreekit({
      name: modelNullName,
    });

    // Перевірка для Cabinets Base Blind Corner (START)
    const modelItem = getItemNodeFromNullModel({ name: modelNullName });
    const isBlindCornerCabinet = checkIfBlindCornerCabinet(modelItem);

    if (isBlindCornerCabinet) {
      const isLeftBlindCornerCabinet = checkIfBlindCornerCabinetLeft(modelItem["name"]);
      const worldTransformModel = getWorldTransformEvalNode(modelNullName);

      if (isLeftBlindCornerCabinet) {

        const modelDirRightBlindCorner = new THREE.Vector3(1, 0, 0).transformDirection(
          worldTransformModel
        );
        const rightExtremePointBlindCorner = moveCoordsByVector(
          posCornerCabinet,
          modelDirRightBlindCorner,
          sizeCornerCabinet["x"] / 2
        );
        const isEqualCoordsCorner = isEqualCoordsTolerance(
          { ...currentPlaneCoordsLeft, y: 0 },
          { ...rightExtremePointBlindCorner, y: 0 },
          0.05
        );
        if (isEqualCoordsCorner) {
          return wallRangesFilled.push({
            empty: false,
            range: [0, sizeCornerCabinet["z"]],
            name: modelNullName,
          });
        }
        
      } else {

        const modelDirLeftBlindCorner = new THREE.Vector3(-1, 0, 0).transformDirection(
          worldTransformModel
        );
        const leftExtremePointBlindCorner = moveCoordsByVector(
          posCornerCabinet,
          modelDirLeftBlindCorner,
          sizeCornerCabinet["x"] / 2
        );
        const isEqualCoordsCorner = isEqualCoordsTolerance(
          { ...currentPlaneCoordsRight, y: 0 },
          { ...leftExtremePointBlindCorner, y: 0 },
          0.05
        );
        if (isEqualCoordsCorner) {
          return wallRangesFilled.push({
            empty: false,
            range: [currentPlaneWidth - sizeCornerCabinet["z"], currentPlaneWidth],
            name: modelNullName,
          });
        }

      }

      return;
    }
    // Перевірка для Cabinets Base Blind Corner (END)

    const distanceFromPlaneToCornerCabinet = getDistanceFromLineToPoint(
      { ...currentPlaneCoordsLeft, y: 0 },
      { ...currentPlaneCoordsRight, y: 0 },
      { ...posCornerCabinet, y: 0 },
    )
    if (Math.abs(distanceFromPlaneToCornerCabinet - sizeCornerCabinet["x"] / 2) <= 0.05) {
      const distanceFromCabinetToPlaneStart = getPointSpacing(
        { ...posCornerCabinet, y: 0 },
        { ...currentPlaneCoordsLeft, y: 0 },
      );
      const distanceFromCabinetToPlaneEnd = getPointSpacing(
        { ...posCornerCabinet, y: 0 },
        { ...currentPlaneCoordsRight, y: 0 },
      );
      // перевіряємо до якого краю плейну наближена модель
      // для того щоб визначити в початок чи в кінець додавати інтервал
      const range: RangeT =
        distanceFromCabinetToPlaneStart <= distanceFromCabinetToPlaneEnd
          ? [0, sizeCornerCabinet["z"]]
          : [currentPlaneWidth - sizeCornerCabinet["z"], currentPlaneWidth];
      wallRangesFilled.push({
        empty: false,
        range,
        name: modelNullName,
      });
    }
  })

  if (wallRangesFilled.length === 0)
    return [fullRangeWall];

  const wallRangesEmptyAndFilled = createRangesEmptyAndFilled(
    wallRangesFilled,
    currentPlaneWidth
  );

  return wallRangesEmptyAndFilled;

};

export const getIntervalsCabinetsBaseOnWall = (planeName: PlaneCabinetsWallT) => {
  const modelsBaseNullOnWall = getModelsBaseNullOnWall(getWallNameFromPlaneName(planeName));
  const intervalsInfoOnWallForCabinetsBase = getIntervalsInfoOnWallForCabinetsBase(modelsBaseNullOnWall, planeName);
  return intervalsInfoOnWallForCabinetsBase;
}

export const getIntervalsOnWallFromNeighborWallsForCabinetsBase = (planeName: PlaneCabinetsWallT) => {
  let resultArrIntervals: ArrWallRangesT = [];
  // Мінімальна відстань від кута, на якій мають розташовуватись шкафи на сусідніх стінах
  // Для того щоб їх не враховувати при додаванні нових шкафів в той самий кут
  // 0.0404478 - ширина декоративної панелі разом з додатковою столешнею збоку для Cabinets Base
  // 0.0193023 - товщина дверей для Cabinets Base
  // const offsetCorner = sizeCabinetBaseNew["z"] + 0.0404478 + 0.0193023;
  const offsetCorner = OFFSET_DEPTH_CABINETS_BASE_IN_CORNER;

  // 1. Отримуємо массив інтервалів, які розташовані на обраній стіні
  resultArrIntervals = getIntervalsCabinetsBaseOnWall(planeName)

  // 2. Отримуємо імена для сусідніх плейнів зліва та справа
  const { planeLeftName, planeRightName } = getNeighborPlanes(planeName);

  // 3. Визначаємо чи присутній на сусідній стіні зліва в спільному куті Cabinet Base.
  // Та, якщо шкаф в куті присутній, додає відповідний інтервал в загальний масив інтервалів.
  resultArrIntervals = checkLeftInterval(
    resultArrIntervals,
    planeLeftName,
    offsetCorner
  );

  // 4. Визначаємо чи присутній на сусідній стіні зправа в спільному куті Cabinet Base.
  // Та, якщо шкаф в куті присутній, додає відповідний інтервал в загальний масив інтервалів.
  resultArrIntervals = checkRightInterval(
    resultArrIntervals,
    planeName,
    planeRightName,
    offsetCorner
  );

  return resultArrIntervals;
}

export type AllWallsIntervalsFloorT = {
  [key in WallItemT]: ArrWallRangesT;
}
/**
 * Створює об'єкт массивів проміжків(заповнених та пустих) ДЛЯ ШКАФІВ НА ПІДЛОЗІ для УСІХ стін.
 *
 * @return {AllWallsIntervalsFloorT} Об'єкт массивів проміжків(заповнених та пустих) ДЛЯ ШКАФІВ НА ПІДЛОЗІ для УСІХ стін.
 */
export const getIntervalsBaseCabinetsForAllWalls = (): AllWallsIntervalsFloorT => {
  // const nodesAllWalls: {[key in string]: ISceneResult} = getAllWallsNode();
  // const objWallsIntervals = Object.values(nodesAllWalls).reduce((accumulator: AllWallsIntervalsFloorT, node: ISceneResult) => {
  //   const wallName = node["name"] as WallItemT;
  //   const modelsBaseNullOnWall = getModelsBaseNullOnWall(wallName);
  //   const intervalsInfoOnWallForCabinetsBase = getIntervalsInfoOnWallForCabinetsBase(modelsBaseNullOnWall, wallName);
  //   return { ...accumulator, [wallName]: intervalsInfoOnWallForCabinetsBase }
  // }, {});
  // return objWallsIntervals;
  const nodesAllPlanes = getAllPlanesNode();
  const objWallsIntervals = Object.values(nodesAllPlanes).reduce((accumulator: AllWallsIntervalsFloorT, node: ISceneResult) => {
    const planeName = node["name"] as PlaneCabinetsWallT;
    const wallNum = getNumberNodeThreekitFromName(planeName);
    const currentWallName: WallItemT = `${NODES_THREEKIT.WALL_ITEM}${wallNum}`;
    const modelsBaseNullOnWall = getModelsBaseNullOnWall(currentWallName);
    const intervalsInfoOnWallForCabinetsBase = getIntervalsInfoOnWallForCabinetsBase(modelsBaseNullOnWall, planeName);
    return { ...accumulator, [currentWallName]: intervalsInfoOnWallForCabinetsBase }
  }, {});
  return objWallsIntervals;
}