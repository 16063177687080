import {
  IConfiguration,
  ISetConfiguration,
} from "@threekit-tools/treble/dist/types";
import { NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getExtremeModelsAppliances } from "../getExtremeModels";
import {
  ATTRIBUTES_DECORATIVE_PANELS,
  getConfiguratorModelFromNullName,
  getSideDecorativeEnd,
} from "./decorativePanel";
import { getKeys } from "../../../utils/other/getObjKeysFromType";
import { getСompletedModelsNullNames } from "../getNodesCabinets";

/**
 * Функція оновлює декоративні панелі для всіх Appliances, відповідно до значення з UI та розташування моделі.
 * Оновлюються моделі, для яких доданий атрибут "Decorative"
 *
 * @param {Boolean} decorativeEndCabinetsBaseValue Знасення перемикача декоративних панелей Cabinets Base з UI.
 * @param {Boolean} decorativeEndCabinetsIslandValue Знасення перемикача декоративних панелей Cabinets Island з UI.
 * Оновлює для ітемів моделей Appliances значення атрибутів, що стосуються декоративних панелей.
 */
export const updateDecorativeEndAppliances = (
  decorativeEndCabinetsBaseValue: boolean,
  decorativeEndCabinetsIslandValue: boolean
): Promise<any> => {
  const objExtremeModelsAppliances = getExtremeModelsAppliances();
  const objExtremeModelsAppliancesKeys = getKeys(objExtremeModelsAppliances);

  let arrPromices: Promise<any>[] = [];

  if (objExtremeModelsAppliancesKeys.length > 0) {
    objExtremeModelsAppliancesKeys.forEach((nullNameAppliances) => {
      const { leftNeighbors, rightNeighbors } =
        objExtremeModelsAppliances[nullNameAppliances];

      const configuratorModel =
        getConfiguratorModelFromNullName(nullNameAppliances);
      const configurationModel = configuratorModel.getConfiguration();

      if (
        configurationModel.hasOwnProperty(
          ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE
        )
      ) {
        const sideDecorativeEnd = getSideDecorativeEnd(
          objExtremeModelsAppliances[nullNameAppliances]
        );

        let objDecorativeEndConfiguration: ISetConfiguration | IConfiguration =
          {
            [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No",
            [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]:
              sideDecorativeEnd,
            [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_ISLAND]: "No",
          };

        if (
          leftNeighbors.includes(NODES_THREEKIT.MODEL_CABINET_ISLAND) ||
          rightNeighbors.includes(NODES_THREEKIT.MODEL_CABINET_ISLAND)
        ) {
          objDecorativeEndConfiguration = {
            ...objDecorativeEndConfiguration,
            [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]:
              decorativeEndCabinetsIslandValue ? "Yes" : "No",
            [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_ISLAND]:
              decorativeEndCabinetsIslandValue ? "Yes" : "No",
          };
        } else if (
          leftNeighbors.includes(NODES_THREEKIT.MODEL_CABINET_BASE) ||
          rightNeighbors.includes(NODES_THREEKIT.MODEL_CABINET_BASE)
        ) {
          objDecorativeEndConfiguration = {
            ...objDecorativeEndConfiguration,
            [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]:
              decorativeEndCabinetsBaseValue ? "Yes" : "No",
            [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_ISLAND]: "No",
          };
        }

        arrPromices.push(configuratorModel.setConfiguration(objDecorativeEndConfiguration));
      }
    });
  } else {
    const allCompletedAppliancesNullNames = getСompletedModelsNullNames(
      NODES_THREEKIT.MODEL_APPLIANCES
    );
    allCompletedAppliancesNullNames.forEach((nullNameAppliance) => {
      const configuratorModel =
        getConfiguratorModelFromNullName(nullNameAppliance);
      const configurationModel = configuratorModel.getConfiguration();

      if (configurationModel.hasOwnProperty("Decorative")) {
        arrPromices.push(configuratorModel.setConfiguration({
          [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No",
        }));
      }
    });
  }
  return Promise.all(arrPromices);
};
