import { areNumbersAlmostEqual } from "../../utils/other/numberData";
import { ArrWallRangesFilledT, WallRangeFilledT, checkPointInIntervalWithTolerance, getFilledInterval } from "./generalIntervals";
import { ArrWallRangesT, RangeT, WallRangeT } from "./getIntervalsOnWallForCabinetsWall";

export const doIntervalsOverlap = (interval1: RangeT, interval2: RangeT, tolerance: number) => {
  return interval1[1] + tolerance >= interval2[0] && interval2[1] + tolerance >= interval1[0];
}

export const checkIntervalsNeaborsLeft = (targetInterval: WallRangeFilledT, secondInterval: WallRangeFilledT, tolerance: number) => {
  return areNumbersAlmostEqual(targetInterval["range"][0], secondInterval["range"][1], tolerance)
}

export const checkIntervalsNeaborsRight = (targetInterval: WallRangeFilledT, secondInterval: WallRangeFilledT, tolerance: number) => {
  return areNumbersAlmostEqual(targetInterval["range"][1], secondInterval["range"][0], tolerance)
}

export const checkIntervalsNeaborsBottom = (targetInterval: WallRangeFilledT, secondInterval: WallRangeFilledT, tolerance: number) => {
  return areNumbersAlmostEqual(targetInterval["rangeVertical"][0], secondInterval["rangeVertical"][1], tolerance)
}

export const checkIntersectIntervalFromIntervals = (
  targetInterval: RangeT,
  arrIntervals: ArrWallRangesT,
): boolean => {
  let isIntersect: boolean = false;
  arrIntervals.forEach((interval) => {
    if (!interval.empty && doIntervalsOverlap(targetInterval, interval["range"], 0.005)) {
      isIntersect = true;
    }
  })
  return isIntersect;
}

/**
 * Функція шукає інтервали, які перетинаються з обраним інтервалом по горизонтальним інтервалам
 *
 * @param {WallRangeT} targetInterval Інтервал, для якого шукаємо інтервали з якими він перетинається
 * @param {ArrWallRangesT} arrIntervals Масив інтервалів
 * @param {number} tolerance Точність перетину інтервалів
 * @return {ArrWallRangesT} Інтервали, які перетинаються з обраним інтервалом
 */
export const getFilledIntervalsIntersectWithInterval = (
  targetInterval: WallRangeT,
  arrIntervals: ArrWallRangesT,
  tolerance: number
): ArrWallRangesT => {
  let arrIntervalsIntersect: ArrWallRangesT = [];
  arrIntervals.forEach((interval) => {
    if (
      !interval.empty &&
      doIntervalsOverlap(targetInterval["range"], interval["range"], tolerance) &&
      targetInterval.name !== interval.name
    ) {
      arrIntervalsIntersect.push(interval);
    }
  })
  return arrIntervalsIntersect;
}

/**
 * Функція шукає інтервали, які перетинаються з обраним інтервалом по вертикальним інтервалам
 *
 * @param {WallRangeT} targetInterval Інтервал, для якого шукаємо інтервали з якими він перетинається
 * @param {ArrWallRangesT} arrIntervals Масив інтервалів
 * @param {number} tolerance Точність перетину інтервалів
 * @return {ArrWallRangesT} Інтервали, які перетинаються з обраним інтервалом
 */
export const getFilledIntervalsIntersectWithIntervalVertical = (
  targetInterval: WallRangeT,
  arrIntervals: ArrWallRangesT,
  tolerance: number
): ArrWallRangesT => {
  let arrIntervalsIntersect: ArrWallRangesT = [];
  arrIntervals.forEach((interval) => {
    if (
      !interval.empty &&
      interval["rangeVertical"] !== undefined &&
      targetInterval["rangeVertical"] !== undefined &&
      doIntervalsOverlap(targetInterval["rangeVertical"], interval["rangeVertical"], tolerance) &&
      targetInterval.name !== interval.name
    ) {
      arrIntervalsIntersect.push(interval);
    }
  })
  return arrIntervalsIntersect;
}

export const checkPointInIntervals = (point: number, arrIntervals: ArrWallRangesT): boolean => {
  let isInclude: boolean = false;
  arrIntervals.forEach((interval) => {
    const isPointInInterval =  checkPointInIntervalWithTolerance({
      point: point,
      startInterval: interval["range"][0],
      endInterval: interval["range"][1],
      tolerance: 0.005
    });
    if (!interval.empty && isPointInInterval) {
      isInclude = true;
    }
  })
  return isInclude;
}

/**
 * Функція шукає інтервали, які дотикаються до обраного інтервалу зліва
 *
 * @param {WallRangeT} targetInterval Інтервал, для якого шукаємо інтервали з якими він перетинається
 * @param {ArrWallRangesT} arrIntervals Масив інтервалів
 * @param {number} tolerance Точність перетину інтервалів
 * @return {ArrWallRangesFilledT} Інтервали, які дотикаються до обраного інтервалу зліва
 */
export const getNeaborIntervalsLeft = (
  targetInterval: WallRangeFilledT,
  arrIntervals: ArrWallRangesT,
  tolerance: number,
): ArrWallRangesFilledT => {
  let arrIntervalsIntersect: ArrWallRangesFilledT = [];
  arrIntervals.forEach((interval) => {
    const filledInterval = getFilledInterval(interval);
    if (filledInterval === undefined) return;
    if (!checkIntervalsNeaborsLeft(targetInterval, filledInterval, tolerance) || targetInterval.name === interval.name) return;
    arrIntervalsIntersect.push(filledInterval);
  })
  return arrIntervalsIntersect;
}

/**
 * Функція шукає інтервали, які дотикаються до обраного інтервалу зправа
 *
 * @param {WallRangeT} targetInterval Інтервал, для якого шукаємо інтервали з якими він перетинається
 * @param {ArrWallRangesT} arrIntervals Масив інтервалів
 * @param {number} tolerance Точність перетину інтервалів
 * @return {ArrWallRangesFilledT} Інтервали, які дотикаються до обраного інтервалу зправа
 */
export const getNeaborIntervalsRight = (
  targetInterval: WallRangeFilledT,
  arrIntervals: ArrWallRangesT,
  tolerance: number,
): ArrWallRangesFilledT => {
  let arrIntervalsIntersect: ArrWallRangesFilledT = [];
  arrIntervals.forEach((interval) => {
    const filledInterval = getFilledInterval(interval);
    if (filledInterval === undefined) return;
    if (!checkIntervalsNeaborsRight(targetInterval, filledInterval, tolerance) || targetInterval.name === interval.name) return;
    arrIntervalsIntersect.push(filledInterval);
  })
  return arrIntervalsIntersect;
}

/**
 * Функція шукає інтервали, які дотикаються до обраного інтервалу знизу
 *
 * @param {WallRangeT} targetInterval Інтервал, для якого шукаємо інтервали з якими він перетинається
 * @param {ArrWallRangesT} arrIntervals Масив інтервалів
 * @param {number} tolerance Точність перетину інтервалів
 * @return {ArrWallRangesFilledT} Інтервали, які дотикаються до обраного інтервалу знизу
 */
export const getNeaborIntervalsBottom = (
  targetInterval: WallRangeFilledT,
  arrIntervals: ArrWallRangesT,
  tolerance: number,
): ArrWallRangesFilledT => {
  let arrIntervalsIntersect: ArrWallRangesFilledT = [];
  arrIntervals.forEach((interval) => {
    const filledInterval = getFilledInterval(interval);
    if (filledInterval === undefined) return;
    if (!checkIntervalsNeaborsBottom(targetInterval, filledInterval, tolerance) || targetInterval.name === interval.name) return;
    arrIntervalsIntersect.push(filledInterval);
  })
  return arrIntervalsIntersect;
}

/**
 * Функція , яка визначає довжину перетину двох інтервалів
 *
 * @param {RangeT} interval1 Інтервал, для якого шукаємо інтервали з якими він перетинається
 * @param {RangeT} interval2 Масив інтервалів
 * @return {number} Довжина перетину двох інтервалів
 */
export const getIntersectionIntervalsLength = (interval1: RangeT, interval2: RangeT): number => {
  const [start1, end1] = interval1;
  const [start2, end2] = interval2;

  const maxStart = Math.max(start1, start2);
  const minEnd = Math.min(end1, end2);

  const intersectionLength = minEnd - maxStart;

  // Перетин існує, якщо довжина більша за нуль
  return Math.max(0, intersectionLength);
}