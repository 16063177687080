export const CABINETS_BASE_STANDART_HEIGHT = 0.8759952; // 34.488"
export const CABINETS_BASE_STANDART_DEPTH_FROM_DOOR = 0.6585476;
// export const CABINETS_BASE_COUNTERTOP_HEIGHT = 0.0130048; // 0.512"
export const CABINETS_BASE_COUNTERTOP_HEIGHT = 0.0384048; // 1.512"
export const CABINETS_BASE_HEIGHT_FROM_COUTERTOP = CABINETS_BASE_STANDART_HEIGHT + CABINETS_BASE_COUNTERTOP_HEIGHT; // 34.488" + 0.512"

// export const CABINETS_WALL_BOTTOM_POSITION = 1.3716;
export const DISTANCE_FROM_COUNTERTOP_TO_WALL_CABINET = 0.4572; // 18"
export const CABINETS_WALL_BOTTOM_POSITION = CABINETS_BASE_HEIGHT_FROM_COUTERTOP + DISTANCE_FROM_COUNTERTOP_TO_WALL_CABINET; // 34.488" + 0.512" + 18"
// Correction for exact alignment with conventional wall cabinets.
// !!!!! Present in connectors for 18" and 24" floor cabinets (0.876 -> 0.8763). !!!!!
export const CABINETS_WALL_UPPER_PANTRY_BOTTOM_POSITION = CABINETS_BASE_STANDART_HEIGHT + 0.00035;
export const CABINETS_WALL_STANDART_HEIGHT = 0.762;
export const CABINETS_WALL_DEPTH_12 = 0.3119978;
export const CABINETS_WALL_DEPTH_24 = 0.5918138;

// export const WALL_PLANE_BOTTOM_POSITION = 0.8627;
export const WALL_PLANE_BOTTOM_POSITION = CABINETS_WALL_BOTTOM_POSITION;

export const CORNER_EMPTY_BASE_SIZE_X = 0.6858000;
export const CORNER_EMPTY_BASE_SIZE_Z = 0.6858000;

export const CORNER_EMPTY_WALL_SIZE_X = 0.3984838;
export const CORNER_EMPTY_WALL_SIZE_Z = 0.3975967;

export const HELP_DISTANCE_CORNER_EMPTY_CORRECTED_OUTSIDE = 0.2;
export const HELP_DISTANCE_CORNER_EMPTY_CORRECTED_INSIDE = 0.005;