import { ICoordinates } from "@threekit-tools/treble/dist/types";
import {
  PlaneCabinetsWallT,
  WallItemT,
} from "../../utils/constants/nodesNamesThreekit";
import {
  ArrWallRangesT,
  WallRangeT,
  addIntervalToArrIntervals,
} from "./getIntervalsOnWallForCabinetsWall";
import { getModelsBaseNullOnWall } from "../cabinets/getNodesCabinets";
import {
  getNeighborPlanes,
  getPlaneNameFromWallName,
} from "../wallsAndFloor/getWallPlanesInfo";
import { getIntervalsInfoOnWallForCabinetsBase } from "./getIntervalsInfoOnWallBase";
import { getWallNameFromPlaneName } from "../wallsAndFloor/getGeneralWallInfo";
import { getIntervalSize } from "./generalIntervals";
import { getBoxWidthThreekit } from "../../utils/threekit/general/getFunctions";

/**
 * Визначає чи присутній на сусідній стіні зліва в спільному куті Cabinet Base.
 * Та, якщо шкаф в куті присутній, додає відповідний інтервал в загальний масив інтервалів.
 *
 * @param {ArrWallRangesT} baseIntervals Загальний масив інтервалів.
 * @param {PlaneCabinetsWallT | undefined} planeLeftName Ім'я сусіднього зліва плейну.
 * @param {number} offsetCorner Мінімальна відстань від кута, на якій мають розташовуватись шкафи на сусідніх стінах.
 * @return {ArrWallRangesT} Змінений або той самий масив інтервалів baseIntervals.
 */
export const checkLeftInterval = (
  baseIntervals: ArrWallRangesT,
  planeLeftName: PlaneCabinetsWallT | undefined,
  offsetCorner: number
): ArrWallRangesT => {
  // Якщо не знайдений сусідній зліва плейн, повертаємо той самий масив інтервалів
  if (planeLeftName === undefined) return baseIntervals;

  // Отримуємо масив інтервалів для сусіднього зліва плейну
  const modelsBaseNullOnWallLeft = getModelsBaseNullOnWall(
    getWallNameFromPlaneName(planeLeftName)
  );
  const intervalsOnLeftWall = getIntervalsInfoOnWallForCabinetsBase(
    modelsBaseNullOnWallLeft,
    planeLeftName
  );

  // Шукаємо останній інтервал для данного плейну
  const lastIntervalOnLeftWall =
    intervalsOnLeftWall[intervalsOnLeftWall.length - 1];

  // Перевіряємо:
  // - якщо останній інтервал пустий І має розмір більший ніж розмір глубини моделі
  // то повертаємо той самий масив інтервалів
  if (
    lastIntervalOnLeftWall["empty"] &&
    getIntervalSize(lastIntervalOnLeftWall) > offsetCorner
  )
    return baseIntervals;

  // В іншому випадку додаємо в загальний масив інтервалів на стіні ще один заповнений інтервал на початок
  // який визначатиме що на сусідній стіні зліва близько до кута розташований шкаф

  // Шукаємо ім'я моделі, що шнаходиться близько до кута на сусідній зліва стіні
  const reversedIntervalsOnLeftWall = [...intervalsOnLeftWall].reverse();
  const indexLastFilledInterval = reversedIntervalsOnLeftWall.findIndex(
    (objInterval) => objInterval["name"] !== undefined
  );
  const nameLeftModel =
    reversedIntervalsOnLeftWall[indexLastFilledInterval]["name"];

  // Створюємо новий інтервал зліва
  const newLeftInterval: WallRangeT = {
    empty: false,
    name: nameLeftModel,
    range: [0, offsetCorner],
  };

  // Додаємо новий інтервал в загальний масив інтервалів на початок і повертаємо його
  return addIntervalToArrIntervals(baseIntervals, newLeftInterval);
};

/**
 * Визначає чи присутній на сусідній стіні зправа в спільному куті Cabinet Base.
 * Та, якщо шкаф в куті присутній, додає відповідний інтервал в загальний масив інтервалів.
 *
 * @param {ArrWallRangesT} baseIntervals Загальний масив інтервалів.
 * @param {PlaneCabinetsWallT} currentPlaneName Ім'я обраного плейну (на який додаємо новий шкаф).
 * @param {PlaneCabinetsWallT | undefined} planeLeftName Ім'я сусіднього зправа плейну.
 * @param {number} offsetCorner Мінімальна відстань від кута, на якій мають розташовуватись шкафи на сусідніх стінах.
 * @return {ArrWallRangesT} Змінений або той самий масив інтервалів baseIntervals.
 */
export const checkRightInterval = (
  baseIntervals: ArrWallRangesT,
  currentPlaneName: PlaneCabinetsWallT,
  planeRightName: PlaneCabinetsWallT | undefined,
  offsetCorner: number
): ArrWallRangesT => {
  // Якщо не знайдений сусідній зghfdf плейн, повертаємо той самий масив інтервалів
  if (planeRightName === undefined) return baseIntervals;

  // Отримуємо масив інтервалів для сусіднього зправа плейну
  const modelsBaseNullOnWallRight = getModelsBaseNullOnWall(
    getWallNameFromPlaneName(planeRightName)
  );
  const intervalsOnRightWall = getIntervalsInfoOnWallForCabinetsBase(
    modelsBaseNullOnWallRight,
    planeRightName
  );

  // Шукаємо перший інтервал для данного плейну
  const firstIntervalOnRightWall = intervalsOnRightWall[0];

  // Перевіряємо:
  // - якщо перший інтервал пустий І має розмір більший ніж розмір глубини моделі
  // то повертаємо той самий масив інтервалів
  if (
    firstIntervalOnRightWall["empty"] &&
    getIntervalSize(firstIntervalOnRightWall) > offsetCorner
  )
    return baseIntervals;

  // В іншому випадку додаємо в загальний масив інтервалів на стіні ще один заповнений інтервал в кінець
  // який визначатиме що на сусідній стіні зправа близько до кута розташований шкаф

  // Шукаємо ім'я моделі, що шнаходиться близько до кута на сусідній зправа стіні
  const indexFirstFilledInterval = intervalsOnRightWall.findIndex(
    (objInterval) => objInterval["name"] !== undefined
  );
  const nameRightModel = intervalsOnRightWall[indexFirstFilledInterval]["name"];

  // Отримуємо розмір плейну, для формування нового інтервалу
  const currentPlaneWidth = getBoxWidthThreekit({ name: currentPlaneName });

  // Створюємо новий інтервал зправа
  const newLeftInterval: WallRangeT = {
    empty: false,
    name: nameRightModel,
    range: [currentPlaneWidth - offsetCorner, currentPlaneWidth],
  };

  // Додаємо новий інтервал в загальний масив інтервалів в кінець і повертаємо його
  return addIntervalToArrIntervals(baseIntervals, newLeftInterval);
};

/**
 * Формує массив інтервалів для на стіні для Cabinets Base для визначення позиції для нового шкафа.
 * З урахуванням інтервалів на сусідніх стінах.
 *
 * @param {WallItemT} targetWall Name стіни, на якій відбувається позиціонування Cabinet Base в правому куті.
 * @param {ICoordinates} sizeCabinetBaseNew Null Name нової моделі, яку потрібно поставити у позицію правого кута на стіні.
 * @return {ArrWallRangesT} Змінений або оригінальний масив інтервалів baseIntervals.
 */
export const getIntervalsForPositionedNewCabinetBase = (
  targetWall: WallItemT,
  sizeCabinetBaseNew: ICoordinates
): ArrWallRangesT => {
  let resultArrIntervals: ArrWallRangesT = [];

  // Мінімальна відстань від кута, на якій мають розташовуватись шкафи на сусідніх стінах
  // Для того щоб їх не приховувати при додаванні нових шкафів в той самий кут
  // 0.0404478 - ширина декоративної панелі разом з додатковою столешнею збоку для Cabinets Base
  // 0.0193023 - товщина дверей для Cabinets Base
  const offsetCorner = sizeCabinetBaseNew["z"] + 0.0404478 + 0.0193023;

  // 1. Отримуємо массив інтервалів, які розташовані на обраній стіні
  const modelsBaseNullOnWall = getModelsBaseNullOnWall(targetWall);
  const currentPlaneName = getPlaneNameFromWallName(targetWall);
  resultArrIntervals = getIntervalsInfoOnWallForCabinetsBase(
    modelsBaseNullOnWall,
    currentPlaneName
  );

  // 2. Отримуємо імена для сусідніх плейнів зліва та справа
  const { planeLeftName, planeRightName } = getNeighborPlanes(currentPlaneName);

  // 3. Визначаємо чи присутній на сусідній стіні зліва в спільному куті Cabinet Base.
  // Та, якщо шкаф в куті присутній, додає відповідний інтервал в загальний масив інтервалів.
  resultArrIntervals = checkLeftInterval(
    resultArrIntervals,
    planeLeftName,
    offsetCorner
  );

  // 4. Визначаємо чи присутній на сусідній стіні зправа в спільному куті Cabinet Base.
  // Та, якщо шкаф в куті присутній, додає відповідний інтервал в загальний масив інтервалів.
  resultArrIntervals = checkRightInterval(
    resultArrIntervals,
    currentPlaneName,
    planeRightName,
    offsetCorner
  );

  return resultArrIntervals;
};
