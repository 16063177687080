import { getValueOption } from "../../../store/selectors/settingsUISelectors";
import store from "../../../store/store";
import {
  getEvalNodeFromId, getNodeFromName, getNodeThreekit,
  getTranslationThreekit,
} from "../../../utils/threekit/general/getFunctions";
import { ExtremeModelI, getExtremeModels } from "../getExtremeModels";
import {
  CabinetsAndFeatures_NodesT,
  ModelsName_NodesT,
  NODES_THREEKIT,
} from "../../../utils/constants/nodesNamesThreekit";
import { getСompletedModelsNull, getСompletedModelsNullNames } from "../../../functionsConfigurator/cabinets/getNodesCabinets";
import { PRODUCT_POSITIONS_KEYS } from "../../../utils/constants/cabinets";
import { getCornerDistanceSorted, getWallNameFromMaxDistanceInCorner } from "../../../utils/threekit/tools/toolsDragCabinetsBase/generalFunc";
import { getSizeModelRelativeTransform } from "../../intervals/getIntervalsOnWallForCabinetsWall";
import { IConfiguration, ICoordinates, ISetConfiguration, IThreekitPrivateConfigurator } from "@threekit-tools/treble/dist/types";
import { UpdateSettingsT } from "../../../store/slices/settingsUI/typesSettingsUISlice";
import { checkModelPositionInCorner } from "../../../utils/threekit/tools/toolsDragCabinetsIsland/generalFunc";
import { updateDecorativeEndAppliances } from "./decorativePanelAppliances";
import { buildCountertopForIsland } from "../cabinetsIsland/buildCountertopForIsland";
import { getConnectorConnected } from "../connectors";
import { CONNECTORS_CABINET_ISLAND } from "../../../utils/constants/connectors";
import { getSizeModelBoxFromAssetCabinetBase } from "../cabinetsBase/size";
import { FrigeConfigurationT } from "./fridgePanels";
import { isNullNameCabinetBase } from "../checkModels";
import { isEqualCoordsTolerance } from "../addCornerModelBase";
import { getKeys } from "../../../utils/other/getObjKeysFromType";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../../utils/constants/attributesThreekit";
import { setNestedConfigurationFromNullName } from "../../../utils/threekit/general/nestedConfigurator";

export const enum ATTRIBUTES_DECORATIVE_PANELS {
  DECORATIVE = "Decorative",
  DECORATIVE_LOCATION = "Decorative Location",
  DECORATIVE_ISLAND = "Island Option",
  DECORATIVE_FRIDGE_SIZE = "Decorative Size",
}

export type DecorativeValuesT = "No" | "Yes";
export type DecorativeLocationValuesT = "Left" | "Right" | "Left and Right";
export type DecorativeIslandValuesT = "No" | "Yes";
export type DecorativeFridgeSizeT = string;
export type MouldingConfigurationT = {
  [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]?: DecorativeValuesT,
  [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]?: DecorativeLocationValuesT,
  [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_ISLAND]?: DecorativeIslandValuesT,
}

type SideDecorativeEndT = "Left" | "Right" | "Left and Right";
/**
 * Функція визначає сторону (значення для атрибуту Decorative Location з Threekit), на яку потрібно додати декоративну панель.
 *
 * @param {ExtremeModelI} objExtremeModel Інформація про модель, необхідна для визначення сторони.
 * @return {ModelsName_NodesT[]} Массив з Nodes для Models Null ["Model_Cabinets_Base_0", ...] які заповнені (з встановленими ассетами).
 */
export const getSideDecorativeEnd = (
  objExtremeModel: ExtremeModelI
): SideDecorativeEndT => {
  const { isLeftPointFree, isRightPointFree } = objExtremeModel;
  let resultSide: SideDecorativeEndT = "Left";

  if (isLeftPointFree) resultSide = "Left";

  if (isRightPointFree) resultSide = "Right";

  if (isLeftPointFree && isRightPointFree) resultSide = "Left and Right";

  return resultSide;
};

/**
 * Функція визначає сторону (значення для атрибуту Decorative Location з Threekit), на яку потрібно додати декоративну панель, враховуючи значення коннекторів.
 *
 * @param {boolean} isDecorativeLeft Індикатор, чи є зайняті коннекктори з лівої сторони.
 * @param {boolean} isDecorativeRight Індикатор, чи є зайняті коннекктори з правої сторони.
 * @return {DecorativeLocationValuesT} Значення яке вказує розташування декоративних панелей.
 */
export const getSideDecorativeEndFromConnectors = (
  isDecorativeLeft: boolean,
  isDecorativeRight: boolean,
): DecorativeLocationValuesT => {

  let resultSide: SideDecorativeEndT = "Left";

  if (isDecorativeLeft) resultSide = "Left";

  if (isDecorativeRight) resultSide = "Right";

  if (isDecorativeLeft && isDecorativeRight) resultSide = "Left and Right";

  return resultSide;
};

/**
 * Функція повертає конфігуратор(сonfigurator) для ітему моделі з Threekit.
 *
 * @param {ModelsName_NodesT} nameModelNull Name для Model Null з Threekit.
 * @return {IThreekitPrivateConfigurator} Конфігуратор(сonfigurator) для ітему моделі з Threekit.
 */
export const getConfiguratorModelFromNullName = (nameModelNull: CabinetsAndFeatures_NodesT): IThreekitPrivateConfigurator => {
  const modelNullNode = getNodeFromName(nameModelNull);
  //@ts-ignore
  const assetIdModel = modelNullNode["plugs"]["Null"][0]["asset"];
  const evalNodeModel = getEvalNodeFromId(assetIdModel);
  //@ts-ignore
  return evalNodeModel["configurator"];
}

/**
 * Функція повертає конфігуратор(сonfigurator) для ітему моделі з Threekit.
 *
 * @param {string} idModel Name для Model Null з Threekit.
 * @return {IThreekitPrivateConfigurator} Конфігуратор(сonfigurator) для ітему моделі з Threekit.
 */
export const getConfiguratorModelFromIdModel = (idModel: string): IThreekitPrivateConfigurator => {
  const modelNullNode = getNodeThreekit({id: idModel});
  //@ts-ignore
  const assetIdModel = modelNullNode["plugs"]["Null"][0]["asset"];
  const evalNodeModel = getEvalNodeFromId(assetIdModel);
  //@ts-ignore
  return evalNodeModel["configurator"];
}

interface DecorativeEndConfigurationI {
  "Decorative": "Yes" | "No"
  "Decorative Location"?: SideDecorativeEndT,
}
/**
 * Функція оновлює декоративні панелі для моделей Base Cabinets або Wall Cabinets або Island Cabinets, відповідно до значення з UI та розташування моделі.
 *
 * @param {NODES_THREEKIT} modelsCabinetsRegExp regexp для Null моделей, які необхідно оновити.
 */
const updateDecorativeEndForCabinets = (
  modelsCabinetsRegExp: NODES_THREEKIT,
  valueDecorativeEnd: boolean
): Promise<any> => {
  const allСompletedNullForCabinets =
    getСompletedModelsNull(modelsCabinetsRegExp);
  const objExtremeModels = getExtremeModels(modelsCabinetsRegExp);
  const arrExtremeModelsKeys = Object.keys(objExtremeModels);
  let arrPromices: Promise<any>[] = [];

  if (!valueDecorativeEnd) {
    allСompletedNullForCabinets.forEach((nullNode) => {
      const nullNodeName = nullNode["name"] as ModelsName_NodesT;
      const configuratorModel = getConfiguratorModelFromNullName(nullNodeName);
      arrPromices.push(configuratorModel.setConfiguration({
        [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No",
        [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_ISLAND]: "No",
      }));
    });
    return Promise.all(arrPromices);
  }

  allСompletedNullForCabinets.forEach((nullNode) => {
    const nullNodeName = nullNode["name"] as ModelsName_NodesT;
    const configuratorModel = getConfiguratorModelFromNullName(nullNodeName);

    // якщо модель внутрішня, маємо прибрати декоративні панелі.
    let objDecorativeEndConfiguration: ISetConfiguration | IConfiguration = {
      [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No",
      [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_ISLAND]: "No",
    }

    // Встановлюємо декоративні панелі для шкафів в залежності від з'єднань коннекторів
    // тільки для Cabinets Island
    if (modelsCabinetsRegExp === NODES_THREEKIT.MODEL_CABINET_ISLAND) {

      const connectingLeft = getConnectorConnected(nullNodeName, CONNECTORS_CABINET_ISLAND.LEFT);
      const connectingLeftFront = getConnectorConnected(nullNodeName, CONNECTORS_CABINET_ISLAND.LEFT_FRONT);

      const connectingRight = getConnectorConnected(nullNodeName, CONNECTORS_CABINET_ISLAND.RIGHT);
      const connectingRightFront = getConnectorConnected(nullNodeName, CONNECTORS_CABINET_ISLAND.RIGHT_FRONT);

      const connectingBackLeft = getConnectorConnected(nullNodeName, CONNECTORS_CABINET_ISLAND.BACK_LEFT);
      const connectingBackRight = getConnectorConnected(nullNodeName, CONNECTORS_CABINET_ISLAND.BACK_RIGHT);

      const isDecorativeLeft = connectingLeft === null && connectingLeftFront === null;
      const isDecorativeRight = connectingRight === null && connectingRightFront === null;
      const isDecorativeBack = connectingBackLeft === null && connectingBackRight === null;

      const decorativeSide = isDecorativeLeft || isDecorativeRight ? "Yes" : "No";
      const decorativeSideLocation = getSideDecorativeEndFromConnectors(isDecorativeLeft, isDecorativeRight);
      const decorativeBack = isDecorativeBack ? "Yes" : "No";

      objDecorativeEndConfiguration = {
        [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: decorativeSide,
        [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: decorativeSideLocation,
        [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_ISLAND]: decorativeBack,
      }

      return arrPromices.push(configuratorModel.setConfiguration(objDecorativeEndConfiguration));

    }

    if (arrExtremeModelsKeys.includes(nullNodeName)) {
      // маємо додати для моделі декоративні панелі

      // визначаємо з якої сторони потрібно додати декоративну панель
      const sideDecorativeEnd = getSideDecorativeEnd(
        objExtremeModels[nullNodeName]
      );

      objDecorativeEndConfiguration = {
        ...objDecorativeEndConfiguration,
        [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "Yes",
        [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: sideDecorativeEnd,
      }

      // Перевіряємо чи не є модель в куті.
      // Чи не притиснута вона боковою стінкою до сусідньої кутової стіни.
      const cornerDistance = checkModelPositionInCorner(nullNodeName);

      if (!!cornerDistance) {
        // const modelSize = getSizeModelRelativeTransform(nullNodeName);
        const modelSize = getSizeModelBoxFromAssetCabinetBase(nullNodeName);
        const maxDistanceWallName =
          getWallNameFromMaxDistanceInCorner(cornerDistance);
        const cornerWallsSortedLeftRight =
          getCornerDistanceSorted(cornerDistance);
        if (
          cornerDistance[maxDistanceWallName]["distanceFront"] <
            modelSize["x"] &&
          sideDecorativeEnd === "Left and Right"
        ) {
          if (maxDistanceWallName === cornerWallsSortedLeftRight[0]) {
            objDecorativeEndConfiguration = {
              ...objDecorativeEndConfiguration,
              [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: "Right",
            };
          }

          if (maxDistanceWallName === cornerWallsSortedLeftRight[1]) {
            objDecorativeEndConfiguration = {
              ...objDecorativeEndConfiguration,
              [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: "Left",
            };
          }

          // перевірка на те, що модель стоїть одною стороною в куті а другою стороною з'єднується з іншою тумбою
        } else if (
          sideDecorativeEnd !== "Left and Right" &&
          cornerDistance[maxDistanceWallName]["distanceFront"] < modelSize["x"]
        ) {
          objDecorativeEndConfiguration = {
            [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No",
          }
        }

      }
    }

    arrPromices.push(configuratorModel.setConfiguration(objDecorativeEndConfiguration));
  });

  return Promise.all(arrPromices);
};

/**
 * Функція оновлює декоративні панелі для всіх моделей, відповідно до значення з UI та розташування моделі.
 * Для оновлення після переміщення однієї з моделей
 *
 */
export const updateDecorativeEnd = (
  movedModelPosition?: PRODUCT_POSITIONS_KEYS,
  decorativeEndValue?: UpdateSettingsT
) => {
  const state = store.getState();

  // отримуємо активні значення Decorative End з UI для: Base Cabinets, Island Cabinets
  let decorativeEndCabinetsBaseValue = getValueOption({
    idOption: "CabinetsBase_DecorativeEnd",
    sectionId: "CabinetBuild",
  })(state) as boolean;
  // let decorativeEndCabinetsWallValue = getValueOption({
  //   idOption: "CabinetsWall_DecorativeEnd",
  //   sectionId: "CabinetBuild",
  // })(state) as boolean;
  let decorativeEndCabinetsIslandValue = getValueOption({
    idOption: "CabinetsIsland_DecorativeEnd",
    sectionId: "CabinetBuild",
  })(state) as boolean;

  let isUpdateCabinetsBase =
    !!movedModelPosition &&
    movedModelPosition === PRODUCT_POSITIONS_KEYS.BASE_CABINET;
  // let isUpdateCabinetsWall = !!movedModelPosition && movedModelPosition === PRODUCT_POSITIONS_KEYS.WALL_CABINET;
  let isUpdateCabinetsIsland =
    !!movedModelPosition &&
    movedModelPosition === PRODUCT_POSITIONS_KEYS.ISLAND_CABINET;

  if (!!movedModelPosition && movedModelPosition === PRODUCT_POSITIONS_KEYS.APPLIANCES) {
    isUpdateCabinetsBase = true;
    isUpdateCabinetsIsland = true;
  }

  if (decorativeEndValue) {
    const { optionId, value } = decorativeEndValue;
    const activeValue = value as boolean;
    switch (optionId) {
      case "CabinetsBase_DecorativeEnd":
        decorativeEndCabinetsBaseValue = activeValue;
        isUpdateCabinetsBase = true;
        break;
      // case 'CabinetsWall_DecorativeEnd':
      //   decorativeEndCabinetsWallValue = activeValue;
      //   isUpdateCabinetsWall = true;
      //   break;
      case "CabinetsIsland_DecorativeEnd":
        decorativeEndCabinetsIslandValue = activeValue;
        isUpdateCabinetsIsland = true;
        break;
      default:
        decorativeEndCabinetsBaseValue = activeValue;
        // decorativeEndCabinetsWallValue = activeValue;
        decorativeEndCabinetsIslandValue = activeValue;
        isUpdateCabinetsBase = true;
        // isUpdateCabinetsWall = true;
        isUpdateCabinetsIsland = true;
        break;
    }
  }

  let arrPromices: Promise<any>[] = [];

  // оновити Decorative End для Base Cabinets
  if (isUpdateCabinetsBase) {
    arrPromices.push(updateDecorativeEndForCabinets(
      NODES_THREEKIT.MODEL_CABINET_BASE,
      decorativeEndCabinetsBaseValue
    ));
  }

  // оновити Decorative End для Wall Cabinets
  // if (isUpdateCabinetsWall) {
  //   updateDecorativeEndForCabinets(NODES_THREEKIT.MODEL_CABINET_WALL, decorativeEndCabinetsWallValue);
  // }

  // оновити Decorative End для Island Cabinets
  if (isUpdateCabinetsIsland) {
    arrPromices.push(updateDecorativeEndForCabinets(
      NODES_THREEKIT.MODEL_CABINET_ISLAND,
      decorativeEndCabinetsIslandValue
    ).then(() => {
      buildCountertopForIsland();
    }))
  }

  // оновлюємо декоративні панелі для Appliances
  arrPromices.push(updateDecorativeEndAppliances(decorativeEndCabinetsBaseValue, decorativeEndCabinetsIslandValue));

  return Promise.all(arrPromices);
  
};

/**
 * Функція оновлює декоративні панелі для напольних шаф, які розташовуються під настінними Upper Pantry шафами.
 *
 */
export const updateDecorativeForCabinetBaseUnderUpperPantry = ({
  floorNullName,
  objConfigurationUpperPantry,
  positionCabinetUpperPantry,
  sizeCabinetUpperPantry,
}: {
  floorNullName: CabinetsAndFeatures_NodesT,
  objConfigurationUpperPantry: FrigeConfigurationT,
  positionCabinetUpperPantry: ICoordinates,
  sizeCabinetUpperPantry: ICoordinates,
}) => {

  const { "Decorative": decorativeUpperPantry, 'Decorative Location': decorativeLocationUpperPantry } = objConfigurationUpperPantry;
  const floorNullNameCabinetBase = floorNullName as ModelsName_NodesT;
  const sizeFloorModel = getSizeModelBoxFromAssetCabinetBase(floorNullNameCabinetBase);
  const positionFloorModel = getTranslationThreekit({ name: floorNullName });
  const isEqualCoords = isEqualCoordsTolerance(
    { ...positionCabinetUpperPantry, y: 0},
    { ...positionFloorModel, y: 0},
    0.05
  );

  if (
    objConfigurationUpperPantry["Decorative"] === undefined ||
    !isNullNameCabinetBase(floorNullName)
  ) return;

  if (
    !(Math.abs(sizeCabinetUpperPantry["x"] - sizeFloorModel["x"]) < 0.05) &&
    !isEqualCoords
  ) return;

  const objExtremeModels = getExtremeModels(NODES_THREEKIT.MODEL_CABINET_BASE);
  const arrExtremeModelsKeys = getKeys(objExtremeModels);
  const objExtremeForCurentFloorModel = objExtremeModels[floorNullNameCabinetBase];
  const configuratorCabinetBase = getConfiguratorModelFromNullName(floorNullName);

  let objDecorativeEndCabinetBase: ISetConfiguration | IConfiguration = {};

  if (decorativeLocationUpperPantry !== undefined) {
    if (decorativeLocationUpperPantry === "Left and Right") {
      objDecorativeEndCabinetBase[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE] = "No";
    } else {
      const isLeftFree = objExtremeForCurentFloorModel?.isRightPointFree;
      const isRightFree = objExtremeForCurentFloorModel?.isLeftPointFree;
  
      if (decorativeLocationUpperPantry === "Left" && isLeftFree) {
        objDecorativeEndCabinetBase = {
          [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "Yes",
          [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: "Right",
        };
      } else if (decorativeLocationUpperPantry === "Right" && isRightFree) {
        objDecorativeEndCabinetBase = {
          [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "Yes",
          [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: "Left",
        };
      } else {
        objDecorativeEndCabinetBase[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE] = "No";
      }
    }
  
    if (decorativeUpperPantry === "No" && arrExtremeModelsKeys.includes(floorNullNameCabinetBase)) {
      const sideDecorativeEnd = getSideDecorativeEnd(objExtremeForCurentFloorModel);
      objDecorativeEndCabinetBase = {
        ...objDecorativeEndCabinetBase,
        [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "Yes",
        [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: sideDecorativeEnd,
      };
    }
  }

  configuratorCabinetBase.setConfiguration(objDecorativeEndCabinetBase);

}

/**
 * Функція вимикає декоративні панелі для всіх моделей.
 * Для вимкнення декоративних панелей під час переміщення однієї з моделей
 * Дозволяє коректно спрацьовувати конекторам (які враховують перетин моделей)
 *
 * @param {ATTRIBUTES_NAMES_THREEKIT} attributeCabinets Назва атрибута для зміни конфігурації через setNestedConfiguration.
 * @param {NODES_THREEKIT} modelsCabinetsRegExp regexp для Null моделей, які необхідно оновити.
 * @return {Promise<any>} Promice оновлення конфігурацій моделей.
 */
export const offDecorativeEndAllCabinets = (
  attributeCabinets: ATTRIBUTES_NAMES_THREEKIT,
  modelsCabinetsRegExp: NODES_THREEKIT
): Promise<any> => {

  let arrPromices: Promise<any>[] = [];

  // Об'єкт конфігурації для вимкнення декоративних панелей
  const objDecorativeEndOffConfiguration: ISetConfiguration | IConfiguration = {
    [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No",
    [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_ISLAND]: "No",
  }

  const allСompletedNullForCabinets = getСompletedModelsNullNames(modelsCabinetsRegExp);
  allСompletedNullForCabinets.forEach((nullName) => {
    arrPromices.push(setNestedConfigurationFromNullName({
      nullName: nullName,
      attributeName: attributeCabinets,
      configuration: objDecorativeEndOffConfiguration,
    }));
  });

  return Promise.all(arrPromices);
  
};