import { createSlice, current } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { mainMenu3D } from '../../../utils/constants/mainMenu3D';
import { ActiveSectionT, ArrRemovedArrOptionsSettingsT, ArrUpdateSettingsT, CabinetControlsI, listActiveSettingT, SettingsUII, UpdateSettingsT } from './typesSettingsUISlice';
import { settingCabinetsBuilderUI } from '../../../utils/constants/settingConfig3D';
import { createArrayActiveSetting } from '../../../functionsUI/createActiveSetting';
import { ProductsList } from '../../../utils/constants/cabinets';
import { replaceValuesForAppliances } from '../../../functionsUI/appliances';
import { IUnits } from '@threekit-tools/treble/dist/hooks/useSpaces/types';
import { ArrWallTemplateT, initialTemplatesList } from '../../../utils/constants/presetsRoom';
import { IConfiguration } from '@threekit-tools/treble/dist/types';

const initialState: SettingsUII = {
  sections: {
    listSection: mainMenu3D,
    activeSection: null,
  },
  settingUI: settingCabinetsBuilderUI,
  activeSettingUI: [],
  errorMessageField: [],
  allProducts: ProductsList,
  room2D: {
    initialWalls: [],
    presets: {
      allPresets: initialTemplatesList,
      active: '',
    },
    savedConfiguration2D: {},
    loadFeatures: false,
  },
  measurement: IUnits["FEET"],
  player: {
    objectOptions: {
      visible: false,
      selectedModelName: '',
    }
  }
}

export const settingsUISlice = createSlice({
  name: 'settingsUI',
  initialState,
  reducers: {
    setActiveSection: (state, action: PayloadAction<ActiveSectionT>) => {
      state["sections"]["activeSection"] = action.payload;
    },
    setAllSettingConfig: (state) => {
      // відповідно активним значенням формує обект активних параметрів
      let listActiveSettings: listActiveSettingT[] = createArrayActiveSetting({
        settingUIState: settingCabinetsBuilderUI,
      });

      state['activeSettingUI'] = listActiveSettings;
    },
    setReconfigurationSettings: (state, action: PayloadAction<listActiveSettingT[]>) => {
      state['activeSettingUI'] = action.payload;
    },
    updateSettings: (state, action: PayloadAction<ArrUpdateSettingsT>) => {
      const updatedSettings = action.payload;
      const currentState = current(state);
      const activeSection = state["sections"]["activeSection"];
      const activeSettingsUI = JSON.parse(JSON.stringify(state['activeSettingUI']));

      const newActiveSettingsUI = activeSettingsUI.map((settingItem: listActiveSettingT) => {
        // let listOptionsObj: listActiveOption = settingItem["listOptionsObj"];
        const { sectionId, listOptionsObj } = settingItem

        updatedSettings.forEach((itemNewSetting: UpdateSettingsT) => {
          const { optionId, value, parentSectionId } = itemNewSetting;
          let newValue = value;
          const paramSectionId = !!parentSectionId ? parentSectionId : activeSection;

          if (sectionId === paramSectionId) {

            if (optionId.includes("AllCabinets")) {
              const optionIdArrString = optionId.split('_');
              const optionName = optionIdArrString[optionIdArrString.length - 1];
              Object.keys(listOptionsObj).forEach((optionKey: string) => {
                if (optionKey.includes(`_${optionName}`)) {
                  listOptionsObj[optionKey]["value"] = value;
                }
              })
            }
            
            if (!!listOptionsObj[optionId]) {

              if (Array.isArray(listOptionsObj[optionId]["value"])) {
                const currentValue = listOptionsObj[optionId]["value"] as string[];
                const newValueType = Array.isArray(newValue) ? value as string[] : [value] as string[];
                newValue = [ ...currentValue, ...newValueType ];

                if (optionId.includes("Cabinets Appliances")) {
                  newValue = replaceValuesForAppliances(currentValue, newValueType)
                }

              }
              
              listOptionsObj[optionId]["value"] = newValue;

            }

          }
            
        })

        return {
          ...settingItem,
          listOptionsObj,
        }
      })

      state['activeSettingUI'] = newActiveSettingsUI;
      
    },
    // для видалення продуктів та опцій типу массив
    removeOptionArrValues: (state, action: PayloadAction<ArrRemovedArrOptionsSettingsT>) => {
      const removedSettings = action.payload;
      const currentState = current(state);
      const activeSection = state["sections"]["activeSection"];
      const activeSettingsUI = JSON.parse(JSON.stringify(state['activeSettingUI']));

      const newActiveSettingsUI = activeSettingsUI.map((settingItem: listActiveSettingT) => {
        // let listOptionsObj: listActiveOption = settingItem["listOptionsObj"];
        const { sectionId, listOptionsObj } = settingItem;

        removedSettings.forEach((removedSettingItem) => {
          const { optionId, value, removedIndexes } = removedSettingItem;

          if (!!!listOptionsObj[optionId]) return;

          const newArrValues = JSON.parse(JSON.stringify(listOptionsObj[optionId]["value"]));
          removedIndexes.forEach((removedIndex) => {
            newArrValues.splice(removedIndex, 1);
          })

          listOptionsObj[optionId]["value"] = newArrValues;
            
        })

        return {
          ...settingItem,
          listOptionsObj,
        }
      })

      state['activeSettingUI'] = newActiveSettingsUI;
    },
    setInitialWalls: (state, action: PayloadAction<ArrWallTemplateT>) => {
      state["room2D"]["initialWalls"] = action.payload;
    },
    setMeasurement: (state, action: PayloadAction<IUnits>) => {
    console.log("RadioButtonsSmall", { payload: action.payload });

      state["measurement"] = action.payload;
    },
    setActivePreset: (state, action: PayloadAction<string>) => {
      state["room2D"]["presets"]["active"] = action.payload;
    },
    setCabinetControls: (state, action: PayloadAction<CabinetControlsI>) => {
      state["player"]["objectOptions"] = action["payload"];
    },
    setSavedConfiguration2D: (state, action: PayloadAction<IConfiguration>) => {
      state["room2D"]["savedConfiguration2D"] = action.payload;
    },
    setLoadFeatures: (state, action: PayloadAction<boolean>) => {
      state["room2D"]["loadFeatures"] = action.payload;
    },
  },
})

export const {
  setActiveSection,
  setAllSettingConfig,
  setReconfigurationSettings,
  updateSettings,
  removeOptionArrValues,
  setInitialWalls,
  setMeasurement,
  setActivePreset,
  setCabinetControls,
  setSavedConfiguration2D,
  setLoadFeatures,
} = settingsUISlice.actions;

export default settingsUISlice.reducer;