import { CONNECTORS_CABINET_WALL } from "../../../utils/constants/connectors";
import { ModelCabinetWallT, ModelsName_NodesT } from "../../../utils/constants/nodesNamesThreekit";
import { getKeys } from "../../../utils/other/getObjKeysFromType";
import { getInstanceIdAssetFromNullModel, getNameNodeThreekit, getTranslationThreekit } from "../../../utils/threekit/general/getFunctions";
import { setActiveTagsThreekit, setVisible } from "../../../utils/threekit/general/setFunctions";
import { isFeaturesModelNullName } from "../../features/general";
import { checkIntersectIntervalFromIntervals } from "../../intervals/intersectIntervals";
import { ArrWallRangesT, RangeT, WallRangeT, getIntervalsWallCabinetsForAllWalls } from "../../intervals/getIntervalsOnWallForCabinetsWall";
import { getIntervalsBaseCabinetsForAllWalls } from "../../intervals/getIntervalsInfoOnWallBase";
import { getWallNameFromPlaneName } from "../../wallsAndFloor/getGeneralWallInfo";
import { isConnectorsSideTrue } from "../cabinetsBase/checkCabinetsBaseConnectors";
import { isUpperPantryCabinetWall } from "../checkModels";
import { CABINETS_WALL_BOTTOM_POSITION, CABINETS_WALL_STANDART_HEIGHT } from "../constatns";
import { getSizeModelBoxFromAssetCabinetWall } from "./size";
import { checkIfCornerCabinetFromNullName } from "../cabinetsBase/checkCornersCabinetsBase";
import { checkDefaultPositionCabinetsWall } from "./position";
import { getConfiguratorModelFromIdModel } from "../configuration/decorativePanel";
import { checkLockTranslationY } from "./checkFreeMovementCabinetsWall";
import { ICoordinates } from "@threekit-tools/treble/dist/types";
import { getConnectorConnected } from "../connectors";

// export const CONNECTORS_TOLERANCE = 0.005;
export const CONNECTORS_TOLERANCE = 0.0254;

type SideConnectorsT = {
  leftConnectors?: boolean; // Outdated property
  rightConnectors?: boolean; // Outdated property
  leftInnerConnectors?: boolean;
  rightInnerConnectors?: boolean;

  leftTopConnector?: boolean;
  leftBottomConnector?: boolean;
  rightTopConnector?: boolean;
  rightBottomConnector?: boolean;
}

const updateConnectorsTagsCabinetsWall = (objSideConnectors: SideConnectorsT, instanceIdParentAsset: string, isLockTranslationY?: boolean) => {
  if (objSideConnectors["leftConnectors"] !== undefined) {
    setActiveTagsThreekit({
      from: instanceIdParentAsset,
      name: CONNECTORS_CABINET_WALL.TOP_LEFT,
      value: objSideConnectors["leftConnectors"],
    })

    if (isLockTranslationY !== undefined) {
      setActiveTagsThreekit({
        from: instanceIdParentAsset,
        name: CONNECTORS_CABINET_WALL.BOTTOM_LEFT,
        value: !isLockTranslationY,
      })
      setVisible({
        from: instanceIdParentAsset,
        name: CONNECTORS_CABINET_WALL.BOTTOM_LEFT,
        value: !isLockTranslationY,
      })
    } else {
      setActiveTagsThreekit({
        from: instanceIdParentAsset,
        name: CONNECTORS_CABINET_WALL.BOTTOM_LEFT,
        value: objSideConnectors["leftConnectors"],
      })
    }
    
  }
  if (objSideConnectors["rightConnectors"] !== undefined) {
    setActiveTagsThreekit({
      from: instanceIdParentAsset,
      name: CONNECTORS_CABINET_WALL.TOP_RIGHT,
      value: objSideConnectors["rightConnectors"],
    })
    if (isLockTranslationY !== undefined) {
      setActiveTagsThreekit({
        from: instanceIdParentAsset,
        name: CONNECTORS_CABINET_WALL.BOTTOM_RIGHT,
        value: !isLockTranslationY,
      })
      setVisible({
        from: instanceIdParentAsset,
        name: CONNECTORS_CABINET_WALL.BOTTOM_RIGHT,
        value: !isLockTranslationY,
      })
    } else {
      setActiveTagsThreekit({
        from: instanceIdParentAsset,
        name: CONNECTORS_CABINET_WALL.BOTTOM_RIGHT,
        value: objSideConnectors["rightConnectors"],
      })
    }
  }
  // if (objSideConnectors["rightInnerConnectors"] !== undefined) {
  //   setActiveTagsThreekit({
  //     from: instanceIdParentAsset,
  //     name: CONNECTORS_CABINET_WALL.TOP_RIGHT_INNER,
  //     value: objSideConnectors["rightInnerConnectors"],
  //   })
  // }
  // if (objSideConnectors["leftInnerConnectors"] !== undefined) {
  //   setActiveTagsThreekit({
  //     from: instanceIdParentAsset,
  //     name: CONNECTORS_CABINET_WALL.TOP_LEFT_INNER,
  //     value: objSideConnectors["leftInnerConnectors"],
  //   })
  // }
}

const updateConnectorsTagsCabinetsWallNew = (objSideConnectors: SideConnectorsT, instanceIdParentAsset: string, isLockTranslationY?: boolean) => {

  // Верхній лівий конектор
  if (objSideConnectors["leftTopConnector"] !== undefined) {
    setActiveTagsThreekit({
      from: instanceIdParentAsset,
      name: CONNECTORS_CABINET_WALL.TOP_LEFT,
      value: objSideConnectors["leftTopConnector"],
    })
  }

  // Нижній лівий конектор
  if (objSideConnectors["leftBottomConnector"] !== undefined) {
    // Додатково перевіряємо нижні конектори для виділеної моделі
    // Якщо для виділеної моделі ЗАБОРОНЕНЕ вертикальне переміщення - то ВИМИКАЄМО для неї нижні конектори
    // Якщо для виділеної моделі ДОЗВОЛЕНЕ вертикальне переміщення - то ВМИКАЄМО для неї нижні конектори
    // if (isLockTranslationY !== undefined) {
    //   setActiveTagsThreekit({
    //     from: instanceIdParentAsset,
    //     name: CONNECTORS_CABINET_WALL.BOTTOM_LEFT,
    //     value: !isLockTranslationY,
    //   })
    //   setVisible({
    //     from: instanceIdParentAsset,
    //     name: CONNECTORS_CABINET_WALL.BOTTOM_LEFT,
    //     value: !isLockTranslationY,
    //   })
    // } else {
    //   setActiveTagsThreekit({
    //     from: instanceIdParentAsset,
    //     name: CONNECTORS_CABINET_WALL.BOTTOM_LEFT,
    //     value: objSideConnectors["leftBottomConnector"],
    //   })
    // }
    setActiveTagsThreekit({
      from: instanceIdParentAsset,
      name: CONNECTORS_CABINET_WALL.BOTTOM_LEFT,
      value: objSideConnectors["leftBottomConnector"],
    })
  }

  // Верхній правий конектор
  if (objSideConnectors["rightTopConnector"] !== undefined) {
    setActiveTagsThreekit({
      from: instanceIdParentAsset,
      name: CONNECTORS_CABINET_WALL.TOP_RIGHT,
      value: objSideConnectors["rightTopConnector"],
    })
  }

  // Нижній правий конектор
  if (objSideConnectors["rightBottomConnector"] !== undefined) {
    // Додатково перевіряємо нижні конектори для виділеної моделі
    // Якщо для виділеної моделі ЗАБОРОНЕНЕ вертикальне переміщення - то ВИМИКАЄМО для неї нижні конектори
    // Якщо для виділеної моделі ДОЗВОЛЕНЕ вертикальне переміщення - то ВМИКАЄМО для неї нижні конектори
    // if (isLockTranslationY !== undefined) {
    //   setActiveTagsThreekit({
    //     from: instanceIdParentAsset,
    //     name: CONNECTORS_CABINET_WALL.BOTTOM_RIGHT,
    //     value: !isLockTranslationY,
    //   })
    //   setVisible({
    //     from: instanceIdParentAsset,
    //     name: CONNECTORS_CABINET_WALL.BOTTOM_RIGHT,
    //     value: !isLockTranslationY,
    //   })
    // } else {
    //   setActiveTagsThreekit({
    //     from: instanceIdParentAsset,
    //     name: CONNECTORS_CABINET_WALL.BOTTOM_RIGHT,
    //     value: objSideConnectors["rightBottomConnector"],
    //   })
    // }
    setActiveTagsThreekit({
      from: instanceIdParentAsset,
      name: CONNECTORS_CABINET_WALL.BOTTOM_RIGHT,
      value: objSideConnectors["rightBottomConnector"],
    })
  }
  
  // if (objSideConnectors["rightInnerConnectors"] !== undefined) {
  //   setActiveTagsThreekit({
  //     from: instanceIdParentAsset,
  //     name: CONNECTORS_CABINET_WALL.TOP_RIGHT_INNER,
  //     value: objSideConnectors["rightInnerConnectors"],
  //   })
  // }
  // if (objSideConnectors["leftInnerConnectors"] !== undefined) {
  //   setActiveTagsThreekit({
  //     from: instanceIdParentAsset,
  //     name: CONNECTORS_CABINET_WALL.TOP_LEFT_INNER,
  //     value: objSideConnectors["leftInnerConnectors"],
  //   })
  // }
}

const getFakeRightInterval = (
  range: RangeT,
  widthModel: number,
): RangeT => [ range[1] + 0.05, range[1] + widthModel + 0.05 ];

const getFakeLeftInterval = (
  range: RangeT,
  widthModel: number,
): RangeT => [ range[0] - widthModel - 0.05, range[0] - 0.05 ];

// (Upper Pantry)
// перевіряємо моделі на активність конекторів для виділеної моделі Upper Pantry
const checkConnectorsForUpperPantry = (
  isUpperPantrySelectedModel: boolean,
  rangeIterModel: RangeT,
  widthSelectedModel: number,
  arrBaseIntervals: ArrWallRangesT,
  side: "left" | "right"
): boolean => {
  if (!isUpperPantrySelectedModel) return true;

  const fakeInterval =
    side === "left"
      ? getFakeLeftInterval(rangeIterModel, widthSelectedModel)
      : getFakeRightInterval(rangeIterModel, widthSelectedModel);

  const isIntersectsIntervals = checkIntersectIntervalFromIntervals(
    fakeInterval,
    arrBaseIntervals
  );

  if (isIntersectsIntervals) return false;

  return true;
};

const checkDefaultHeightForModelInterval = (
  objInterval: WallRangeT | null | undefined,
) => {

  if (objInterval === undefined || objInterval === null) return true;

  const { empty, range, name } = objInterval;

  if (empty || name === undefined) return true;

  return checkDefaultPositionCabinetsWall(name);

}

const isIntervalEmpty = (
  interval: WallRangeT
): boolean => interval["empty"];

const isIntervalSize = (
  interval: WallRangeT,
  widthSelectedModel: number
): boolean => {
  const intervalLength = interval["range"][1] - interval["range"][0];
  return intervalLength - widthSelectedModel >= -CONNECTORS_TOLERANCE;
  // return interval["range"][1] - interval["range"][0] >= widthSelectedModel
  // return Math.abs(intervalLength - widthSelectedModel) < 0.005;
}

const isNamesMatch = (
  interval: WallRangeT,
  nullNameSelectedModel: ModelsName_NodesT
): boolean => interval["name"] === nullNameSelectedModel;

function isPointInIntervalWithTolerance({
  point,
  startInterval,
  endInterval,
  tolerance
}: {
  point: number;
  startInterval: number;
  endInterval: number;
  tolerance: number;
}) {
  return point >= (startInterval - tolerance) && point <= (endInterval + tolerance);
}

const checkFreeConnector = ({
  currentInterval,
  intervalNeabor,
  intervalNeaborNext,
  nullNameSelectedModel,
  sizeCurentModel,
  positionCurentModel,
  curentModelPointX,
  curentModelPointY,
}: {
  currentInterval: WallRangeT;
  intervalNeabor: WallRangeT | null | undefined;
  intervalNeaborNext: WallRangeT | null | undefined;
  nullNameSelectedModel: ModelsName_NodesT;
  sizeCurentModel: ICoordinates;
  positionCurentModel: ICoordinates;
  curentModelPointX: number;
  curentModelPointY: number;
}): boolean => {
  
  let result = true;

  if (
    intervalNeabor !== null &&
    intervalNeabor !== undefined &&
    !intervalNeabor["empty"] &&
    intervalNeabor["name"] !== undefined
  ) {

    if (
      isNamesMatch(intervalNeabor, nullNameSelectedModel) ||
      isFeaturesModelNullName(intervalNeabor["name"])
    ) return result;

    const isGorisontalBusy = isPointInIntervalWithTolerance({
      point: curentModelPointX,
      startInterval: intervalNeabor["range"][0],
      endInterval: intervalNeabor["range"][1],
      tolerance: CONNECTORS_TOLERANCE,
    });

    const positionNeaborModel = getTranslationThreekit({name: intervalNeabor["name"]});
    const sizeNeaborModel = getSizeModelBoxFromAssetCabinetWall(intervalNeabor["name"]);

    const isVerticalBusy = isPointInIntervalWithTolerance({
      point: curentModelPointY,
      startInterval: positionNeaborModel["y"],
      endInterval: positionNeaborModel["y"] + sizeNeaborModel["y"],
      tolerance: CONNECTORS_TOLERANCE,
    });

    result = (isGorisontalBusy && isVerticalBusy) ? false : true;

  };
  return result;
}

export const checkRangeSizeForSelectedModel = ({
  intervalNeabor,
  intervalNeaborNext,
  nullNameSelectedModel,
  widthSelectedModel,
}: {
  intervalNeabor: WallRangeT | null | undefined,
  intervalNeaborNext: WallRangeT | null | undefined,
  nullNameSelectedModel: ModelsName_NodesT,
  widthSelectedModel: number
}): boolean => {
  return (
    intervalNeabor !== null &&
    intervalNeabor !== undefined &&
    (
      (isIntervalEmpty(intervalNeabor) &&
        isIntervalSize(intervalNeabor, widthSelectedModel)) ||
      (isIntervalEmpty(intervalNeabor) &&
        !isIntervalSize(intervalNeabor, widthSelectedModel) &&
        intervalNeaborNext !== null &&
        intervalNeaborNext !== undefined &&
        isNamesMatch(intervalNeaborNext, nullNameSelectedModel))
    )
  );
};

const checkRangeSizeSmallForSelectedModel = ({
  intervalNeabor,
  widthSelectedModel,
}: {
  intervalNeabor: WallRangeT | null | undefined,
  widthSelectedModel: number
}): boolean => {
  return (
    intervalNeabor !== null &&
    intervalNeabor !== undefined &&
    isIntervalEmpty(intervalNeabor) &&
    !isIntervalSize(intervalNeabor, widthSelectedModel)
  );
};

export const checkCabinetsWallConnectors = (idSelectedModel: string) => {

  const nullNameSelectedModel = getNameNodeThreekit({id: idSelectedModel}) as ModelsName_NodesT;
  const sizeSelectedModel = getSizeModelBoxFromAssetCabinetWall(nullNameSelectedModel);
  const isUpperPantrySelectedModel = isUpperPantryCabinetWall(nullNameSelectedModel);

  const intervalsCabinetsWallForAllWalls = getIntervalsWallCabinetsForAllWalls();
  const intervalsCabinetsBaseForAllWalls = getIntervalsBaseCabinetsForAllWalls();

  const keysIntervalsCabinetsWallForAllWalls = getKeys(
    intervalsCabinetsWallForAllWalls
  );

  keysIntervalsCabinetsWallForAllWalls.forEach((planeName) => {

    const arrWallIntervals =
      intervalsCabinetsWallForAllWalls[planeName];
    const arrBaseIntervals =
      intervalsCabinetsBaseForAllWalls[getWallNameFromPlaneName(planeName)];

    let prevInterval: WallRangeT | null = null;
    let prevFilledInterval: WallRangeT | null = null;

    arrWallIntervals.forEach((interval, indx) => {

      let { empty, range, name } = interval;
      const nextInterval = arrWallIntervals[indx + 1];
      const nextFilledInterval = nextInterval !== undefined && nextInterval["empty"] ? arrWallIntervals[indx + 2] : arrWallIntervals[indx + 1];
      let objSideConnectors: SideConnectorsT = {}

      if (!empty && !!name && !isFeaturesModelNullName(name)) {

        const instanceIdAssetModel = getInstanceIdAssetFromNullModel({name: name});
        const positionModel = getTranslationThreekit({name});
        const sizeModel = getSizeModelBoxFromAssetCabinetWall(name);

        // якщо настінна модель знаходить виже або нижче дефолтної позиції,
        // то вмикаємо всі конектори
        // if (Math.abs((positionModel["y"] + sizeModel["y"]) - (CABINETS_WALL_BOTTOM_POSITION + CABINETS_WALL_STANDART_HEIGHT)) > 0.005) {
        //   objSideConnectors["leftConnectors"] = true;
        //   objSideConnectors["rightConnectors"] = true;
        //   prevInterval = interval;
        //   prevFilledInterval = interval;
        //   return updateConnectorsTagsCabinetsWall(objSideConnectors, instanceIdAssetModel)
        // }

        // якщо це перша модель на стіні
        // то перевіряємо тільки праву сторону
        // для лівої сторони вимикаємо конектори (якщо це не кутова модель),
        if (indx === 0) {

          const isFreeConnectorTopRight = checkFreeConnector({
            currentInterval: interval,
            intervalNeabor: nextInterval,
            intervalNeaborNext: nextFilledInterval,
            nullNameSelectedModel,
            sizeCurentModel: sizeModel,
            positionCurentModel: positionModel,
            curentModelPointX: interval["range"][1],
            curentModelPointY: positionModel["y"] + sizeModel["y"],
          })
  
          const isFreeConnectorBottomRight = checkFreeConnector({
            currentInterval: interval,
            intervalNeabor: nextInterval,
            intervalNeaborNext: nextFilledInterval,
            nullNameSelectedModel,
            sizeCurentModel: sizeModel,
            positionCurentModel: positionModel,
            curentModelPointX: interval["range"][1],
            curentModelPointY: positionModel["y"],
          })
  
          const isRangeSizeForSelectedModelRight = checkRangeSizeForSelectedModel({
            intervalNeabor: nextInterval,
            intervalNeaborNext: nextFilledInterval,
            nullNameSelectedModel: nullNameSelectedModel,
            widthSelectedModel: sizeSelectedModel["x"]
          });

          const isRangeSizeSmallForSelectedModelCornerRight = checkRangeSizeSmallForSelectedModel({
            intervalNeabor: nextInterval,
            widthSelectedModel: sizeSelectedModel["x"]
          })
  
          if (isRangeSizeForSelectedModelRight) {
            // Зправа пустий проміжок. Підходячщий по розміру. Нижній правий конектор - ON, Верхній правий конектор - ON
            objSideConnectors["rightTopConnector"] = true;
            objSideConnectors["rightBottomConnector"] = true;
          } else if (isRangeSizeSmallForSelectedModelCornerRight) {
            // Сусідній проміжок пустий, але замалий для вставлення виділеної моделі. Нижній правий конектор - OFF, Верхній правий конектор - OFF
            objSideConnectors["rightTopConnector"] = false;
            objSideConnectors["rightBottomConnector"] = false;
          } else {
            // Модель з'єднується з іншою моделлю, перевіряємо верхні та нижні конектори зправа
            if (!isFreeConnectorBottomRight && !isFreeConnectorTopRight) {
              // Нижній конектор - OFF, Верхній конектор - OFF
              objSideConnectors["rightTopConnector"] = false;
              objSideConnectors["rightBottomConnector"] = false;
            } else if (!isFreeConnectorBottomRight && isFreeConnectorTopRight) {
              // Нижній конектор - OFF, Верхній конектор - ON
              objSideConnectors["rightTopConnector"] = true;
              objSideConnectors["rightBottomConnector"] = false;
            } else if (isFreeConnectorBottomRight && !isFreeConnectorTopRight) {
              // Нижній конектор - ON, Верхній конектор - OFF
              objSideConnectors["rightTopConnector"] = false;
              objSideConnectors["rightBottomConnector"] = true;
            } else {
              // Нижній конектор - ON, Верхній конектор - ON
              objSideConnectors["rightTopConnector"] = true;
              objSideConnectors["rightBottomConnector"] = true;
            }
          }

          if (!checkIfCornerCabinetFromNullName(name)) {
            // Модель не кутова, тому вимикаємо конектори, які стикаються з стіною
            // Нижній лівий конектор - OFF, Верхній лівий конектор - OFF
            objSideConnectors["leftTopConnector"] = false;
            objSideConnectors["leftBottomConnector"] = false;
          }

          prevInterval = interval;
          prevFilledInterval = interval;
          
          return updateConnectorsTagsCabinetsWallNew(objSideConnectors, instanceIdAssetModel);


          // if (
          //   isConnectorsSideTrue(
          //     nextInterval,
          //     nextFilledInterval,
          //     nullNameSelectedModel,
          //     sizeSelectedModel["x"]
          //   )
          // ) {
          //   objSideConnectors["rightConnectors"] = true;
          // } else {
          //   objSideConnectors["rightConnectors"] = false;
          // }
          // return updateConnectorsTagsCabinetsWall(objSideConnectors, instanceIdAssetModel);


        }

        // якщо це остання модель на стіні
        // то перевіряємо тільки ліву сторону
        // для правої сторони вимикаємо конектори (якщо це не кутова модель),
        if (indx === arrWallIntervals.length - 1) {

          const isFreeConnectorTopLeft = checkFreeConnector({
            currentInterval: interval,
            intervalNeabor: prevInterval,
            intervalNeaborNext: prevFilledInterval,
            nullNameSelectedModel,
            sizeCurentModel: sizeModel,
            positionCurentModel: positionModel,
            curentModelPointX: interval["range"][0],
            curentModelPointY: positionModel["y"] + sizeModel["y"],
          })
  
          const isFreeConnectorBottomLeft = checkFreeConnector({
            currentInterval: interval,
            intervalNeabor: prevInterval,
            intervalNeaborNext: prevFilledInterval,
            nullNameSelectedModel,
            sizeCurentModel: sizeModel,
            positionCurentModel: positionModel,
            curentModelPointX: interval["range"][0],
            curentModelPointY: positionModel["y"],
          })
  
          const isRangeSizeForSelectedModelLeft = checkRangeSizeForSelectedModel({
            intervalNeabor: prevInterval,
            intervalNeaborNext: prevFilledInterval,
            nullNameSelectedModel: nullNameSelectedModel,
            widthSelectedModel: sizeSelectedModel["x"]
          });

          const isRangeSizeSmallForSelectedModelCornerLeft = checkRangeSizeSmallForSelectedModel({
            intervalNeabor: prevInterval,
            widthSelectedModel: sizeSelectedModel["x"]
          })
  
          if (isRangeSizeForSelectedModelLeft) {
            // Зліва пустий проміжок. Підходячщий по розміру. Нижній лівий конектор - ON, Верхній лівий конектор - ON
            objSideConnectors["leftTopConnector"] = true;
            objSideConnectors["leftBottomConnector"] = true;
          } else if (isRangeSizeSmallForSelectedModelCornerLeft) {
            // Сусідній проміжок пустий, але замалий для вставлення виділеної моделі. Нижній лівий конектор - OFF, Верхній лівий конектор - OFF
            objSideConnectors["leftTopConnector"] = false;
            objSideConnectors["leftBottomConnector"] = false;
          } else {

            // Модель з'єднується з іншою моделлю, перевіряємо верхні та внижні конектори зліва
            if (!isFreeConnectorBottomLeft && !isFreeConnectorTopLeft) {
              // Нижній конектор - OFF, Верхній конектор - OFF
              objSideConnectors["leftTopConnector"] = false;
              objSideConnectors["leftBottomConnector"] = false;
            } else if (!isFreeConnectorBottomLeft && isFreeConnectorTopLeft) {
              // Нижній конектор - OFF, Верхній конектор - ON
              objSideConnectors["leftTopConnector"] = true;
              objSideConnectors["leftBottomConnector"] = false;
            } else if (isFreeConnectorBottomLeft && !isFreeConnectorTopLeft) {
              // Нижній конектор - ON, Верхній конектор - OFF
              objSideConnectors["leftTopConnector"] = false;
              objSideConnectors["leftBottomConnector"] = true;
            } else {
              // Нижній конектор - ON, Верхній конектор - ON
              objSideConnectors["leftTopConnector"] = true;
              objSideConnectors["leftBottomConnector"] = true;
            }
          }

          if (!checkIfCornerCabinetFromNullName(name)) {
            // Модель не кутова, тому вимикаємо конектори, які стикаються з стіною
            // Нижній правий конектор - OFF, Верхній правий конектор - OFF
            objSideConnectors["rightTopConnector"] = false;
            objSideConnectors["rightBottomConnector"] = false;
          }

          prevInterval = interval;
          prevFilledInterval = interval;
          
          return updateConnectorsTagsCabinetsWallNew(objSideConnectors, instanceIdAssetModel);


          // if (
          //   isConnectorsSideTrue(
          //     prevInterval,
          //     prevFilledInterval,
          //     nullNameSelectedModel,
          //     sizeSelectedModel["x"]
          //   )
          // ) {
          //   objSideConnectors["leftConnectors"] = true
          // } else {
          //   objSideConnectors["leftConnectors"] = false;
          // }
          // return updateConnectorsTagsCabinetsWall(objSideConnectors, instanceIdAssetModel)


        }

        // якщо це є виділеною моделлю, то вмикаємо для неї всі конектори
        if (name === nullNameSelectedModel) {

          // Це виділена модель. Активовуємо всі конектори. Щоб модель можна було законектити будь де.
          objSideConnectors["leftTopConnector"] = true;
          objSideConnectors["leftBottomConnector"] = true;
          objSideConnectors["rightTopConnector"] = true;
          objSideConnectors["rightBottomConnector"] = true;
          prevInterval = interval;
          prevFilledInterval = interval;
          const isLockTranslationY = checkLockTranslationY(nullNameSelectedModel);
          return updateConnectorsTagsCabinetsWallNew(objSideConnectors, instanceIdAssetModel, isLockTranslationY);


          // objSideConnectors["leftConnectors"] = true;
          // objSideConnectors["rightConnectors"] = true;
          // return updateConnectorsTagsCabinetsWall(objSideConnectors, instanceIdAssetModel, isLockTranslationY)


        }

        // Якщо виділена модель є шафою Upper Pantry
        // Якщо модель, яка первіряється теж Upper Pantry
        // Якщо модель, яка первіряється вже має з'єднання з напольною моделлю
        // То для моделі Upper Pantry, яка перевіряється, вимикаємо нижні та верхні конектори (нижні конектори для таких моделей вимкнені в налаштуваннях threekit)
        // Так як шафа Upper Pantry може конектитись тільки до напольних моделей відповідного розміру
        if (
          isUpperPantrySelectedModel &&
          isUpperPantryCabinetWall(name) &&
          getConnectorConnected(name, CONNECTORS_CABINET_WALL.BOTTOM_UPPER_PANTRY_REGEXP) !== null
        ) {
          objSideConnectors["leftTopConnector"] = false;
          objSideConnectors["leftBottomConnector"] = false;
          objSideConnectors["rightTopConnector"] = false;
          objSideConnectors["rightBottomConnector"] = false;
          prevInterval = interval;
          prevFilledInterval = interval;
          return updateConnectorsTagsCabinetsWallNew(objSideConnectors, instanceIdAssetModel);
        }

        // перевіряємо ліву сторону

        const isFreeConnectorTopLeft = checkFreeConnector({
          currentInterval: interval,
          intervalNeabor: prevInterval,
          intervalNeaborNext: prevFilledInterval,
          nullNameSelectedModel,
          sizeCurentModel: sizeModel,
          positionCurentModel: positionModel,
          curentModelPointX: interval["range"][0],
          curentModelPointY: positionModel["y"] + sizeModel["y"],
        })

        const isFreeConnectorBottomLeft = checkFreeConnector({
          currentInterval: interval,
          intervalNeabor: prevInterval,
          intervalNeaborNext: prevFilledInterval,
          nullNameSelectedModel,
          sizeCurentModel: sizeModel,
          positionCurentModel: positionModel,
          curentModelPointX: interval["range"][0],
          curentModelPointY: positionModel["y"],
        })

        const isRangeSizeForSelectedModelLeft = checkRangeSizeForSelectedModel({
          intervalNeabor: prevInterval,
          intervalNeaborNext: prevFilledInterval,
          nullNameSelectedModel: nullNameSelectedModel,
          widthSelectedModel: sizeSelectedModel["x"]
        });
        
        const isRangeSizeSmallForSelectedModelLeft = checkRangeSizeSmallForSelectedModel({
          intervalNeabor: prevInterval,
          widthSelectedModel: sizeSelectedModel["x"]
        })

        if (isRangeSizeForSelectedModelLeft) {
          // Зліва пустий проміжок. Підходячщий по розміру. Нижній лівий конектор - ON, Верхній лівий конектор - ON
          objSideConnectors["leftTopConnector"] = true;
          objSideConnectors["leftBottomConnector"] = true;
        } else if (isRangeSizeSmallForSelectedModelLeft) {
          // Сусідній проміжок пустий, але замалий для вставлення виділеної моделі. Нижній лівий конектор - OFF, Верхній лівий конектор - OFF
          objSideConnectors["leftTopConnector"] = false;
          objSideConnectors["leftTopConnector"] = false;
        } else {
          // Модель з'єднується з іншою моделлю, перевіряємо верхні та внижні конектори зліва
          if (!isFreeConnectorBottomLeft && !isFreeConnectorTopLeft) {
            // Нижній конектор - OFF, Верхній конектор - OFF
            objSideConnectors["leftTopConnector"] = false;
            objSideConnectors["leftBottomConnector"] = false;
          } else if (!isFreeConnectorBottomLeft && isFreeConnectorTopLeft) {
            // Нижній конектор - ON, Верхній конектор - OFF
            objSideConnectors["leftTopConnector"] = true;
            objSideConnectors["leftBottomConnector"] = false;
          } else if (isFreeConnectorBottomLeft && !isFreeConnectorTopLeft) {
            // Нижній конектор - ON, Верхній конектор - OFF
            objSideConnectors["leftTopConnector"] = false;
            objSideConnectors["leftBottomConnector"] = true;
          } else {
            // Нижній конектор - ON, Верхній конектор - ON
            objSideConnectors["leftTopConnector"] = true;
            objSideConnectors["leftBottomConnector"] = true;
          }
        }


        // const isConnectorsLeft = isConnectorsSideTrue(
        //   prevInterval,
        //   prevFilledInterval,
        //   nullNameSelectedModel,
        //   sizeSelectedModel["x"]
        // )
        // const isConnectorsForUpperPantryLeft = checkConnectorsForUpperPantry(
        //   isUpperPantrySelectedModel,
        //   range,
        //   sizeSelectedModel["x"],
        //   arrBaseIntervals,
        //   "left",
        // );
        // const isPrevFilledIntervalOnDefaultHeight = checkDefaultHeightForModelInterval(prevFilledInterval);
        // if (
        //   isConnectorsLeft && isConnectorsForUpperPantryLeft || !isPrevFilledIntervalOnDefaultHeight
        // ) {
        //   objSideConnectors["leftConnectors"] = true
        // } else {
        //   objSideConnectors["leftConnectors"] = false;

        //   // Check inner connector Right
        //   if (sizeSelectedModel["x"] > sizeModel["x"]) {
        //     objSideConnectors["rightInnerConnectors"] = false;
        //   } else {
        //     objSideConnectors["rightInnerConnectors"] = true;
        //   }

        // }

        
        // перевіряємо праву сторону
        
        const isFreeConnectorTopRight = checkFreeConnector({
          currentInterval: interval,
          intervalNeabor: nextInterval,
          intervalNeaborNext: nextFilledInterval,
          nullNameSelectedModel,
          sizeCurentModel: sizeModel,
          positionCurentModel: positionModel,
          curentModelPointX: interval["range"][1],
          curentModelPointY: positionModel["y"] + sizeModel["y"],
        })

        const isFreeConnectorBottomRight = checkFreeConnector({
          currentInterval: interval,
          intervalNeabor: nextInterval,
          intervalNeaborNext: nextFilledInterval,
          nullNameSelectedModel,
          sizeCurentModel: sizeModel,
          positionCurentModel: positionModel,
          curentModelPointX: interval["range"][1],
          curentModelPointY: positionModel["y"],
        })

        const isRangeSizeForSelectedModelRight = checkRangeSizeForSelectedModel({
          intervalNeabor: nextInterval,
          intervalNeaborNext: nextFilledInterval,
          nullNameSelectedModel: nullNameSelectedModel,
          widthSelectedModel: sizeSelectedModel["x"]
        });

        const isRangeSizeSmallForSelectedModelRight = checkRangeSizeSmallForSelectedModel({
          intervalNeabor: nextInterval,
          widthSelectedModel: sizeSelectedModel["x"]
        })

        if (isRangeSizeForSelectedModelRight) {
          // Зправа пустий проміжок. Підходячщий по розміру. Нижній правий конектор - ON, Верхній правий конектор - ON
          objSideConnectors["rightTopConnector"] = true;
          objSideConnectors["rightBottomConnector"] = true;
        } else if (isRangeSizeSmallForSelectedModelRight) {
          // Сусідній проміжок пустий, але замалий для вставлення виділеної моделі. Нижній правий конектор - OFF, Верхній правий конектор - OFF
          objSideConnectors["rightTopConnector"] = false;
          objSideConnectors["rightBottomConnector"] = false;
        } else {
          // Модель з'єднується з іншою моделлю, перевіряємо верхні та нижні конектори справа
          if (!isFreeConnectorBottomRight && !isFreeConnectorTopRight) {
            // Нижній конектор - OFF, Верхній конектор - OFF
            objSideConnectors["rightTopConnector"] = false;
            objSideConnectors["rightBottomConnector"] = false;
          } else if (!isFreeConnectorBottomRight && isFreeConnectorTopRight) {
            // Нижній конектор - OFF, Верхній конектор - ON
            objSideConnectors["rightTopConnector"] = true;
            objSideConnectors["rightBottomConnector"] = false;
          } else if (isFreeConnectorBottomRight && !isFreeConnectorTopRight) {
            // Нижній конектор - ON, Верхній конектор - OFF
            objSideConnectors["rightTopConnector"] = false;
            objSideConnectors["rightBottomConnector"] = true;
          } else {
            // Нижній конектор - ON, Верхній конектор - ON
            objSideConnectors["rightTopConnector"] = true;
            objSideConnectors["rightBottomConnector"] = true;
          }
        }

        updateConnectorsTagsCabinetsWallNew(objSideConnectors, instanceIdAssetModel);

        prevFilledInterval = interval;

      }

      if (!empty && !!name && isFeaturesModelNullName(name)) {
        prevFilledInterval = interval;
      }

      prevInterval = interval;

    })
  })

}