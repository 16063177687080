import { CabinetsAndFeatures_NodesT, NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getNameNodeThreekit } from "../../../utils/threekit/general/getFunctions";
import { setActiveColliderThreekit } from "../../../utils/threekit/general/setFunctions";
import { getSizeModelBoxFromAssetCabinetWall } from "../cabinetsWall/size";
import { isUpperPantryCabinetWall } from "../checkModels";
import { isEqualAxisSize } from "../configuration/countertop";
import { getСompletedModelsNullNames } from "./../getNodesCabinets";
import { getSizeModelBoxFromAssetCabinetBase } from "./size";

export const checkPhisicsBaseCabinets = (idWallCabinet: string, activePhisicsValue: boolean) => {
  const nullNameWallCabinet = getNameNodeThreekit({id: idWallCabinet}) as CabinetsAndFeatures_NodesT;
  const isUpperPantry = isUpperPantryCabinetWall(nullNameWallCabinet);
  const sizeWallCabinet = getSizeModelBoxFromAssetCabinetWall(nullNameWallCabinet);

  if (!isUpperPantry) return;

  const allСompletedNullForCabinets = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_BASE);

  allСompletedNullForCabinets.forEach((nullNameCabinetBase) => {

    const sizeCabinetBase = getSizeModelBoxFromAssetCabinetBase(nullNameCabinetBase);
    const isEqualSizeXZ = isEqualAxisSize(sizeCabinetBase["x"], sizeWallCabinet["x"]) && isEqualAxisSize(sizeCabinetBase["z"], sizeWallCabinet["z"]);

    if (isEqualSizeXZ) {
      setActiveColliderThreekit({
        name: nullNameCabinetBase,
        value: activePhisicsValue,
      })
    };
    
  });
}