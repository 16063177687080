import {
  CabinetsAndFeatures_NodesT,
  ModelCabinetWallT,
  NODES_THREEKIT,
} from "../../../utils/constants/nodesNamesThreekit";
import { getKeys } from "../../../utils/other/getObjKeysFromType";
import { areNumbersAlmostEqual } from "../../../utils/other/numberData";
import { getBoxWidthThreekit } from "../../../utils/threekit/general/getFunctions";
import { isFeaturesModelNullName } from "../../features/general";
import {
  ArrWallRangesFilledT,
  OrientationIntervalT,
  WallRangeFilledT,
  getFilledInterval,
  getIntervalSizeFromInterval,
} from "../../intervals/generalIntervals";
import {
  ArrWallRangesT,
  RangeT,
  getIntervalsWallCabinetsForAllWalls,
} from "../../intervals/getIntervalsOnWallForCabinetsWall";
import {
  getFilledIntervalsIntersectWithInterval,
  getFilledIntervalsIntersectWithIntervalVertical,
  getIntersectionIntervalsLength,
  getNeaborIntervalsBottom,
  getNeaborIntervalsLeft,
  getNeaborIntervalsRight,
} from "../../intervals/intersectIntervals";
import { checkIfCornerCabinetFromNullName } from "../cabinetsBase/checkCornersCabinetsBase";
import { ATTRIBUTES_DECORATIVE_PANELS, getConfiguratorModelFromNullName } from "../configuration/decorativePanel";
import { ATTRIBUTES_MOULDING, MouldingEdgeScribeConfigurationT } from "../configuration/moulding";
import { getSizeModelBoxFromAssetCabinetWall } from "./size";
import { getСompletedModelsNullNames } from "./../getNodesCabinets";
import _ from "lodash";
import { check24DepthCabinetWall, isNullNameModelCabinetWallT } from "./checkCabinetsWall";
import { isOTRCabinetWall, isUpperPantryCabinetWall } from "../checkModels";
import { inchesToMeters } from "../../../utils/other/measurements";

const EDGE_SCRIBE_MOULDING_WIDTH = 0.0189992;

export interface LengthEdgeScribeMouldingI {
  left: number;
  right: number;
  bottom: number;
  leftFront: number;
  rightFront: number;
}

export interface InfoEdgeScribeMouldingI {
  mouldingLength: LengthEdgeScribeMouldingI;
  configuration: MouldingEdgeScribeConfigurationT;
}

export type CabinetsEdgeScribeMouldingLengthT = {
  [key in ModelCabinetWallT]: LengthEdgeScribeMouldingI;
};

export type AllCabinetsEdgeScribeMouldingInfoT = {
  [key in ModelCabinetWallT]: InfoEdgeScribeMouldingI;
};

export type AllCabinetsEdgeScribeMouldingConfigurationT = {
  [key in ModelCabinetWallT]: MouldingEdgeScribeConfigurationT;
};

const getInitialConfigurationEdgeScribeMoulding = (): MouldingEdgeScribeConfigurationT => {
  return {
    [ATTRIBUTES_MOULDING.EDGE_SCRIBE_MOULDING]: "no",
    [ATTRIBUTES_MOULDING.EDGE_SCRIBE_MOULDING_LOCATION]: "Center",
  };
};

const getInitialObjInfo = (): InfoEdgeScribeMouldingI => {
  return {
    mouldingLength: {
      left: 0,
      right: 0,
      bottom: 0,
      leftFront: 0,
      rightFront: 0,
    },
    configuration: {
      [ATTRIBUTES_MOULDING.EDGE_SCRIBE_MOULDING]: "no",
      [ATTRIBUTES_MOULDING.EDGE_SCRIBE_MOULDING_LOCATION]: "Center",
    },
  };
};

const calculateIntersectLength = (
  targetInterval: RangeT,
  neaborIntervals: ArrWallRangesFilledT,
  orientation: OrientationIntervalT = "vertical"
): number => {
  return neaborIntervals.reduce((totalLength, interval) => {
    const [targetStart, targetEnd] = targetInterval;
    const orientationInterval = orientation === "vertical" ? interval["rangeVertical"] : interval["range"];
    const [intervalStart, intervalEnd] = orientationInterval;

    const isAdjacent =
      areNumbersAlmostEqual(targetStart, intervalEnd, 0.001) || areNumbersAlmostEqual(targetEnd, intervalStart, 0.001);

    if (isAdjacent) {
      return totalLength;
    }

    return totalLength + getIntersectionIntervalsLength(targetInterval, orientationInterval);
  }, 0);
};

const calculateMouldingSideLength = (
  targetInterval: RangeT,
  neaborIntervals: ArrWallRangesFilledT,
  orientation?: OrientationIntervalT
): number => {
  const intersectLength = calculateIntersectLength(targetInterval, neaborIntervals, orientation);
  const targetIntervalSize = getIntervalSizeFromInterval(targetInterval);
  const mouldingLength = targetIntervalSize - intersectLength;

  return mouldingLength;
};

const calculateLeftSideFridgePanel = (
  modelNullName: CabinetsAndFeatures_NodesT,
  sideDecorative: "Left" | "Right"
): number => {
  let decorativeMoldingLength = 0;
  if (!isNullNameModelCabinetWallT(modelNullName)) return decorativeMoldingLength;
  if (isUpperPantryCabinetWall(modelNullName)) {
    const modelConfiguration = getConfiguratorModelFromNullName(modelNullName).getConfiguration();
    if (
      modelConfiguration.hasOwnProperty(ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE) &&
      modelConfiguration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE] === "Yes"
    ) {
      const decorativeLocation = modelConfiguration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION] as string;
      const decorativeSize = modelConfiguration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_FRIDGE_SIZE] as string;
      if (decorativeLocation.includes(sideDecorative)) {
        decorativeMoldingLength = inchesToMeters(parseInt(decorativeSize));
      }
    }
  }
  if (isOTRCabinetWall(modelNullName) && check24DepthCabinetWall(modelNullName)) {
    const modelConfiguration = getConfiguratorModelFromNullName(modelNullName).getConfiguration();
    if (
      modelConfiguration.hasOwnProperty(ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE) &&
      modelConfiguration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE] === "Yes"
    ) {
      const decorativeLocation = modelConfiguration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION] as string;
      const decorativeSize = modelConfiguration[ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_FRIDGE_SIZE] as string;
      if (decorativeLocation.includes(sideDecorative)) {
        decorativeMoldingLength = inchesToMeters(parseInt(decorativeSize));
      }
    }
  }
  return decorativeMoldingLength;
};

const getMouldingLeftSideLength = ({
  targetInterval,
  neaborIntervals,
  wallWidth,
  isCornerModel,
}: {
  targetInterval: WallRangeFilledT;
  neaborIntervals: ArrWallRangesFilledT;
  wallWidth: number;
  isCornerModel: boolean;
}): number => {
  if (targetInterval["range"][0] <= 0.0025) return 0;

  if (isCornerModel && targetInterval["range"][0] > 0.0025 && targetInterval["range"][1] < wallWidth - 0.0025) {
    const modelConfiguration = getConfiguratorModelFromNullName(targetInterval["name"]).getConfiguration();
    if (
      modelConfiguration.hasOwnProperty("rotateCabinetCorner") &&
      modelConfiguration["rotateCabinetCorner"] === "right"
    )
      return 0;
  }

  const checkLeftSideFridgePanel = calculateLeftSideFridgePanel(targetInterval["name"], "Left");
  if (checkLeftSideFridgePanel > 0) return checkLeftSideFridgePanel;

  return calculateMouldingSideLength(targetInterval["rangeVertical"], neaborIntervals);
};

const getMouldingRightSideLength = ({
  targetInterval,
  neaborIntervals,
  wallWidth,
  isCornerModel,
}: {
  targetInterval: WallRangeFilledT;
  neaborIntervals: ArrWallRangesFilledT;
  wallWidth: number;
  isCornerModel: boolean;
}): number => {
  if (targetInterval["range"][1] >= wallWidth - 0.0025) return 0;

  if (isCornerModel && targetInterval["range"][0] > 0.0025 && targetInterval["range"][1] < wallWidth - 0.0025) {
    const modelConfiguration = getConfiguratorModelFromNullName(targetInterval["name"]).getConfiguration();
    if (modelConfiguration["rotateCabinetCorner"] === "left") return 0;
  }

  const checkLeftSideFridgePanel = calculateLeftSideFridgePanel(targetInterval["name"], "Right");
  if (checkLeftSideFridgePanel > 0) return checkLeftSideFridgePanel;

  return calculateMouldingSideLength(targetInterval["rangeVertical"], neaborIntervals);
};

const getMouldingBottomSideLength = ({
  targetInterval,
  neaborIntervals,
  wallWidth,
  isCornerModel,
}: {
  targetInterval: WallRangeFilledT;
  neaborIntervals: ArrWallRangesFilledT;
  wallWidth: number;
  isCornerModel: boolean;
}): number => {
  if (isCornerModel && (targetInterval["range"][0] <= 0.0025 || targetInterval["range"][1] >= wallWidth - 0.0025)) {
    const targetIntervalSize = getIntervalSizeFromInterval(targetInterval["range"]);
    return targetIntervalSize * 2;
  }
  if (isUpperPantryCabinetWall(targetInterval["name"])) return 0;
  return calculateMouldingSideLength(targetInterval["range"], neaborIntervals, "gorisontal");
};

const calculateMouldingFrontLength = (
  targetInterval: WallRangeFilledT,
  wallWidth: number,
  isCornerModel: boolean
): { lengthLeftFront: number; lengthRightFront: number } => {
  let lengthLeftFront = 0;
  let lengthRightFront = 0;

  // Якщо маємо кутову модель, то в неї не може бути "Left Wall" та "Right Wall". Ставимо довжину для цих позицій молдингів рівну 0
  if (isCornerModel) {
    return {
      lengthLeftFront,
      lengthRightFront,
    };
  }

  // Перевіряємо чи розташована модель лівою стороною біля стіни
  if (targetInterval["range"][0] <= 0.0025) {
    const sizeModel = getSizeModelBoxFromAssetCabinetWall(targetInterval["name"]);
    lengthLeftFront = sizeModel["z"] + sizeModel["y"];
  }

  // Перевіряємо чи розташована модель правою стороною біля стіни
  if (targetInterval["range"][1] >= wallWidth - 0.0025) {
    const sizeModel = getSizeModelBoxFromAssetCabinetWall(targetInterval["name"]);
    lengthRightFront = sizeModel["z"] + sizeModel["y"];
  }

  return {
    lengthLeftFront,
    lengthRightFront,
  };
};

const getModelLengthEdgeScribeMoulding = (
  targetInterval: WallRangeFilledT,
  intervalsOnWall: ArrWallRangesT,
  wallWidth: number
): LengthEdgeScribeMouldingI => {
  const isCornerModel = checkIfCornerCabinetFromNullName(targetInterval["name"]);

  // Отримуємо інтервали з якими перетинається інтервал моделі по горизонталі
  const filledIntervalsIntersectWithInterval = getFilledIntervalsIntersectWithInterval(
    targetInterval,
    intervalsOnWall,
    EDGE_SCRIBE_MOULDING_WIDTH
  );

  // Отримуємо інтервали з якими перетинається інтервал моделі по вертикалі
  const filledIntervalsIntersectWithIntervalVertical = getFilledIntervalsIntersectWithIntervalVertical(
    targetInterval,
    filledIntervalsIntersectWithInterval,
    EDGE_SCRIBE_MOULDING_WIDTH
  );

  // В результаті маємо інтервали моделей, які дотичні до обраної моделі

  // Отримуємо сусідні інтервали зліва
  const neaborIntervalsLeft = getNeaborIntervalsLeft(
    targetInterval,
    filledIntervalsIntersectWithIntervalVertical,
    EDGE_SCRIBE_MOULDING_WIDTH
  );

  // Отримуємо сусідні інтервали зправа
  const neaborIntervalsRight = getNeaborIntervalsRight(
    targetInterval,
    filledIntervalsIntersectWithIntervalVertical,
    EDGE_SCRIBE_MOULDING_WIDTH
  );

  // Отримуємо сусідні інтервали знизу
  const neaborIntervalsBottom = getNeaborIntervalsBottom(
    targetInterval,
    filledIntervalsIntersectWithIntervalVertical,
    EDGE_SCRIBE_MOULDING_WIDTH
  );

  // Left
  // Отримуємо довжину молдінга з лівої сторони
  // const mouldingLeftSideLength = calculateMouldingSideLength(targetInterval["rangeVertical"], neaborIntervalsLeft);
  const mouldingLeftSideLength = getMouldingLeftSideLength({
    targetInterval,
    neaborIntervals: neaborIntervalsLeft,
    wallWidth,
    isCornerModel,
  });

  // Right
  // Отримуємо довжину молдінга з правої сторони
  // const mouldingRightSideLength = calculateMouldingSideLength(targetInterval["rangeVertical"], neaborIntervalsRight);
  const mouldingRightSideLength = getMouldingRightSideLength({
    targetInterval,
    neaborIntervals: neaborIntervalsRight,
    wallWidth,
    isCornerModel,
  });

  // Bottom
  // Отримуємо довжину молдінга з нижньої сторони
  // const mouldingBottomSideLength = calculateMouldingSideLength(
  //   targetInterval["range"],
  //   neaborIntervalsBottom,
  //   "gorisontal"
  // );
  const mouldingBottomSideLength = getMouldingBottomSideLength({
    targetInterval,
    neaborIntervals: neaborIntervalsBottom,
    wallWidth,
    isCornerModel,
  });

  // Front
  // Отримуємо довжину молдінга спереду та збоку для випадку, коли модель розташована біля стіни
  const mouldingFrontSideLength = calculateMouldingFrontLength(targetInterval, wallWidth, isCornerModel);

  return {
    left: mouldingFrontSideLength["lengthLeftFront"] === 0 ? mouldingLeftSideLength : 0,
    right: mouldingFrontSideLength["lengthRightFront"] === 0 ? mouldingRightSideLength : 0,
    bottom: mouldingBottomSideLength,
    leftFront: mouldingFrontSideLength["lengthLeftFront"],
    rightFront: mouldingFrontSideLength["lengthRightFront"],
  };

};

const getLengthMouldingModelsOnWall = (
  intervalsOnWall: ArrWallRangesT,
  wallWidth: number
): CabinetsEdgeScribeMouldingLengthT => {
  let objEdgeScribeMouldingInfo: CabinetsEdgeScribeMouldingLengthT = {};
  // Перебираємо всі інтервали на стіні
  intervalsOnWall.forEach((targetInterval) => {
    const filledInterval = getFilledInterval(targetInterval);

    // Якщо
    // 1. Інтервал пустий;
    // 2. Інтервал для Features (вікна, двері, проеми)
    // То пропускаємо ітерацію
    if (
      filledInterval === undefined ||
      (filledInterval !== undefined && isFeaturesModelNullName(filledInterval["name"]))
    )
      return;

    const modelName = filledInterval["name"] as ModelCabinetWallT;

    // Порівнюємо інтервали на стіні з активним інтервалом targetInterval та отримуємо конфігурацію для моделі
    const modelInfo = getModelLengthEdgeScribeMoulding(filledInterval, intervalsOnWall, wallWidth);
    objEdgeScribeMouldingInfo[modelName] = modelInfo;
  });

  return objEdgeScribeMouldingInfo;
};

export const getEdgeScribeMouldingLengthAllCabinets = (): CabinetsEdgeScribeMouldingLengthT => {
  const allIntervalsWallCabinets = getIntervalsWallCabinetsForAllWalls();

  let resultObj: CabinetsEdgeScribeMouldingLengthT = {};

  // Перебираємо інтервали для всіх стін
  Object.entries(allIntervalsWallCabinets).forEach(([namePlane, intervalsOnWall]) => {
    // Шукаємо повну довжину стіни для визначення в подальшому моделей, які позташовані боковою стінкою біля сусідньої стіни
    const wallWidth = getBoxWidthThreekit({ name: namePlane });

    // Перебираємо всі інтервали на стіні, та отримуємо конфігурації по молдингах для кожної настінної моделі в заповнених інтервалах
    const objEdgeScribeMouldingInfoModelsOnWall = getLengthMouldingModelsOnWall(intervalsOnWall, wallWidth);
    function customizer(objValue: any, srcValue: any) {
      if (_.isNumber(objValue) && _.isNumber(srcValue)) {
        return Math.max(objValue, srcValue);
      }
    }
    resultObj = _.mergeWith({}, resultObj, objEdgeScribeMouldingInfoModelsOnWall, customizer);
  });

  return resultObj;
};

const getConfigurationModelFromLengthEdgeScribeMoulding = (
  nullNameModel: ModelCabinetWallT,
  objMouldingLength: LengthEdgeScribeMouldingI
): MouldingEdgeScribeConfigurationT => {
  const configuration: MouldingEdgeScribeConfigurationT = {
    [ATTRIBUTES_MOULDING.EDGE_SCRIBE_MOULDING]: "no",
    [ATTRIBUTES_MOULDING.EDGE_SCRIBE_MOULDING_LOCATION]: "Center",
  };

  const TOLERANCE_MOLDING_LENGTH = 0.005;
  const {
    left: mouldingLeftSideLength,
    right: mouldingRightSideLength,
    bottom: mouldingBottomSideLength,
    leftFront: mouldingLeftFrontSideLength,
    rightFront: mouldingRightFrontSideLength,
  } = objMouldingLength;

  const isLocationLeft = mouldingRightSideLength < TOLERANCE_MOLDING_LENGTH;
  const isLocationRight = mouldingLeftSideLength < TOLERANCE_MOLDING_LENGTH;
  const isLocationAlone =
    mouldingLeftSideLength > TOLERANCE_MOLDING_LENGTH && mouldingRightSideLength > TOLERANCE_MOLDING_LENGTH;
  const isLocationCenter = mouldingLeftSideLength < 0.005 && mouldingRightSideLength < TOLERANCE_MOLDING_LENGTH;
  const isLocationLeftWall = mouldingLeftFrontSideLength > TOLERANCE_MOLDING_LENGTH;
  const isLocationRightWall = mouldingRightFrontSideLength > TOLERANCE_MOLDING_LENGTH;
  const isMouldingNone =
    isLocationLeft &&
    isLocationRight &&
    mouldingBottomSideLength < TOLERANCE_MOLDING_LENGTH &&
    !isLocationLeftWall &&
    !isLocationRightWall;

  if (isLocationLeft || isLocationRight || isLocationAlone || isLocationCenter)
    configuration[ATTRIBUTES_MOULDING.EDGE_SCRIBE_MOULDING] = "yes";

  if (isLocationLeft) configuration[ATTRIBUTES_MOULDING.EDGE_SCRIBE_MOULDING_LOCATION] = "Left";

  if (isLocationRight) configuration[ATTRIBUTES_MOULDING.EDGE_SCRIBE_MOULDING_LOCATION] = "Right";

  if (isLocationAlone) configuration[ATTRIBUTES_MOULDING.EDGE_SCRIBE_MOULDING_LOCATION] = "Alone";

  // Робимо перевірку для кутової моделі
  // Якщо довжина молдингу по низу перевищує половину ширини моделі, то вмикаємо "Alone"
  // Для того щоб відобразити нижній молдинг повністю, коли з однієї зі сторін є інша тумба
  // В загальному підрахунку довжини молдинга все одно отримаємо правильне значення
  if (checkIfCornerCabinetFromNullName(nullNameModel)) {
    const sizeModel = getSizeModelBoxFromAssetCabinetWall(nullNameModel);
    if (mouldingBottomSideLength > sizeModel["x"] + TOLERANCE_MOLDING_LENGTH)
      configuration[ATTRIBUTES_MOULDING.EDGE_SCRIBE_MOULDING_LOCATION] = "Alone";
  }

  if (isLocationCenter) configuration[ATTRIBUTES_MOULDING.EDGE_SCRIBE_MOULDING_LOCATION] = "Center";

  if (isLocationLeftWall) configuration[ATTRIBUTES_MOULDING.EDGE_SCRIBE_MOULDING_LOCATION] = "Left Wall";

  if (isLocationRightWall) configuration[ATTRIBUTES_MOULDING.EDGE_SCRIBE_MOULDING_LOCATION] = "Right Wall";

  if (isMouldingNone) configuration[ATTRIBUTES_MOULDING.EDGE_SCRIBE_MOULDING] = "no";

  return configuration;
};

export const getConfigurationModelsFromLengthEdgeScribeMoulding = (
  isEdgeScribeMoulding: boolean
): AllCabinetsEdgeScribeMouldingConfigurationT => {
  let resultObj: AllCabinetsEdgeScribeMouldingConfigurationT = {};

  // Якщо в UI вимкнуто відображення молдингу, то вимикаємо молдинги для усіх шаф
  if (!isEdgeScribeMoulding) {
    const completedWallCabinetsNullNames = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_WALL);
    completedWallCabinetsNullNames.forEach((wallCabinetNullName) => {
      if (isNullNameModelCabinetWallT(wallCabinetNullName))
        resultObj[wallCabinetNullName] = { ...getInitialConfigurationEdgeScribeMoulding() };
    });
    return resultObj;
  }

  const objLengthEdgeScribeMoulding = getEdgeScribeMouldingLengthAllCabinets();
  const namesModels = getKeys(objLengthEdgeScribeMoulding);

  namesModels.forEach((nameModel) => {
    const objLengthMouldingModel = objLengthEdgeScribeMoulding[nameModel];
    resultObj[nameModel] = {
      ...getConfigurationModelFromLengthEdgeScribeMoulding(nameModel, objLengthMouldingModel),
    };
  });

  return resultObj;
};